import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EMPTY } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../services/auth/auth.service';
import { AppMessageService } from '../../../services/common/app-message.service';
import { UtilsService } from '../../../services/common/utils.service';
import { AppSettingsService } from '../../../services/settings/app-settings.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  form: FormGroup;
  otpForm: FormGroup;
  submitted = false;
  generalErrorMessage = '';

  otpSubmitted = false;
  otpErrorMessage = '';

  showTokenDialog = false;
  tokenCountdown = 0;

  constructor(private fb: FormBuilder,
              private auth: AuthService,
              private utils: UtilsService,
              private appMsgService: AppMessageService,
              private router: Router,
              public appSettings: AppSettingsService) {
    this.form = this.fb.group({
      oldPassword: new FormControl('', [Validators.required]),
      newPassword: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(30)]),
      newPassword2: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(30)])
    });

    this.otpForm = this.fb.group({
      otpToken: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]),
    });
  }

  ngOnInit(): void {
    if(this.appSettings.loginStrategy === 'ldap'){
      this.router.navigateByUrl('accessdenied');
    }
  }

  onResetPassword() {
    this.submitted = true;
    this.generalErrorMessage = '';

    if (!this.form.valid) {
      return;
    }

    const { oldPassword, newPassword, newPassword2 } = this.form.value;

    // eski şifre ile yeni şifre aynı olamaz.
    if (oldPassword === newPassword) {
      this.generalErrorMessage = 'Eski şifreniz ile yeni şifreniz aynı olamaz';
      this.appMsgService.resetSubmitButton();
      return;
    }

    // girilen iki yeni şifre de aynı olmalı
    if (newPassword !== newPassword2) {
      this.generalErrorMessage = 'Girdiğiniz yeni şifreler farklı olamaz.';
      this.appMsgService.resetSubmitButton();
      return;
    }

    this.auth.resetPassword(oldPassword, '', '')
             .pipe(
               catchError((err) => {
                this.generalErrorMessage = this.utils.getErrorMessage(err);
                this.generalErrorMessage='Eski Şifrenizi Yanlış Girdiniz'
                return EMPTY;
               }),
               finalize(() => this.appMsgService.resetSubmitButton())
             )
             .subscribe((response) => {
                if (response.result === 'token') {
                  this.tokenCountdown = 180;
                  this.showTokenDialog = true;
                } else {
                  this.generalErrorMessage = 'PIN kodu gönderilemedi.';
                }
             });
  }

  onSendOTPToken() {
    this.otpSubmitted = true;

    if (!this.otpForm.valid) {
      return;
    }

    const { oldPassword, newPassword} = this.form.value;
    const { otpToken } = this.otpForm.value;

    this.auth.resetPassword(oldPassword, newPassword, otpToken)
             .pipe(
               catchError((err) => {
                this.otpErrorMessage = this.utils.getErrorMessage(err);

                return EMPTY;
               }),
               finalize(() => this.appMsgService.resetSubmitButton())
             )
             .subscribe((response) => {
                if (response.result === 'success') {
                  this.showTokenDialog = false;
                  this.appMsgService.successToast('Şifre', 'Şifreniz başarıyla değiştirildi.');

                  setTimeout(() => {
                    this.router.navigateByUrl('');                    
                  }, 1000);

                } else {
                  this.otpErrorMessage = 'Şifreniz değiştirilemedi.';
                }
             });
  }
}
