import { StatusService } from './../../../services/settings/status.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppMessageService } from '../../../services/common/app-message.service';
import { DashboardService } from '../../../services/dashboard/dashboard.service';
import { textColorPicker } from '../../../../common/common';

interface IStatusCardData {
  status: string;
  name: string;
  count: number;
  selected: boolean;
  color: string;
  group: string;
}

@Component({
  selector: 'app-durumlar-card',
  templateUrl: './durumlar-card.component.html',
  styleUrls: ['./durumlar-card.component.scss']
})
export class DurumlarCardComponent implements OnInit, OnDestroy {

  dashboard$: Subscription;
  data: any[] = [];
  statusGroups: any[] = [];

  statusGroupData: any[] = [];

  dashboardData: IStatusCardData[] = [];

  constructor(private dashboardService: DashboardService,
    private messageService: AppMessageService,
    private statusService: StatusService) {
  }

  ngOnInit() {
    this.dashboard$ = this.dashboardService.events$.subscribe(async (e) => {
      if (e.event === 'service-changed') {
            await this.getServiceStatus(e.state.selectedService);
            await this.getStatusGroups();
            const services = e.state.response.services ? e.state.response.services : [];
            this.dashboardData = [];
            this.statusGroupData = [];

                this.data.forEach((statusItem) => {
                  const count = services.filter(f => f.status === statusItem._id)
                                      .reduce((p, c) => p + c.count, 0);

                      this.dashboardData.push({
                        status: statusItem._id,
                        name: statusItem.name,
                        count,
                        selected: false,
                        color: statusItem.color,
                        group: statusItem.group
                      });
                 });

                 await this.getStatusGroupData();
      }
    });
  }

  textColor(color:string){
    return textColorPicker(color)
  }

  async getServiceStatus(serviceId: string) {
    this.data = await this.statusService.
        getAllStatusesForFilter('', [serviceId], '').toPromise();
  }

  async getStatusGroups() {
    this.statusGroups = await this.statusService
                              .getAllStatusGroups().toPromise();
  }

  async getStatusGroupData() {
   this.statusGroups.forEach(group => {
    const count = this.dashboardData.filter(data => data.group === group._id)
                      .reduce((p, c) =>  p + c.count,  0);

                      this.statusGroupData.push({
                        name: group.name,
                        color: group.color,
                        count: count,
                        group: group._id
                      });
   });
  }

  ngOnDestroy(): void {
      if (this.dashboard$) {
        this.dashboard$.unsubscribe();
      }
  }

  trackByFn(index: number, item: any) {
    return item.text;
  }

  goToList(item: IStatusCardData) {
    if(item.count === 0) {
      this.messageService.errorToast('Kayıt Yok', 'Seçilen filtrelerle eşleşen kayıt yok');
      return;
    }
    this.dashboardService.goToServiceStatusList(item.status);
  }

  goToListByGroup(item) {
    if(item.count === 0) {
      this.messageService.errorToast('Kayıt Yok', 'Seçilen filtrelerle eşleşen kayıt yok');
      return;
    }
    this.dashboardService.goToServiceStatusGroupList(item.group);
  }

}
