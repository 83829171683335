import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'zeplinLogTypePipe'
})
export class ZeplinLogTypePipe implements PipeTransform {

  transform(value: string): string {
    switch(value) {
      case 'create': return 'Rezervasyon Oluşumu';
      case 'manuel': return 'Manuel';
      case 'automatic': return 'Otomatik';

    }
  }

}
