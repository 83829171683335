import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { EmailDto, IEmail } from "../domain/email";
@Injectable()
export class EmailService {
    constructor(private http: HttpClient) {}

    getAll() {
        return this.http.get<IEmail[]>(`${environment.url}email`);
    }
    createEmailTemplate(emailDto: EmailDto) {
        return this.http.post<IEmail>(`${environment.url}email/create`,emailDto);
    }
    updateEmailTemplate(id: string, emailDto: EmailDto) {
        return this.http.put<IEmail>(`${environment.url}email/${id}`, emailDto);
    }
}
