import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IUserGuide, UserGuideDto } from 'src/app/pages/operator/user-guides/interface/userguide.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserGuidesService {

  constructor(
    private http: HttpClient
  ) { }

  getAllUserGuides() {
    return this.http.get<IUserGuide[]>(`${environment.url}userGuides/getAll`);
  };

  addUserGuide(data: UserGuideDto): Observable<any> {
    return this.http.post<IUserGuide>(`${environment.url}userGuides/create`, data);
  };

  editUserGuide(id: string, data: UserGuideDto): Observable<any> {
    return this.http.put<IUserGuide>(`${environment.url}userGuides/update/${id}`, data);
  };

}
