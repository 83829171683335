import {Component, OnInit} from '@angular/core';
import {PrimeNGConfig} from 'primeng/api';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{


    constructor(private config: PrimeNGConfig) {}

    ngOnInit() {
        this.config.ripple = true;
        this.config.setTranslation({
            dayNames: ["Pazar", "Pazartesi", "Salı", "Çarşamba", "Perşembe", "Cuma", "Cumartesi"],
            dayNamesShort: ["Pzr", "Pzrts", "Salı", "Çarş", "Perş", "Cuma", "Cmrts"],
            dayNamesMin: ["Pz","Pzt","Sa","Ça","Pe","Cu","Cmt"],
            monthNames: ["Ocak","Şubat","Mart","Nisan","Mayıs","Haziran","Temmuz","Ağustos","Eylül","Ekim","Kasım","Aralık"],
            monthNamesShort: ["Oca", "Şub", "Mar", "Nis", "May", "Haz","Tem", "Ağu", "Eyl", "Eki", "Kas", "Ara"],
            today: "Bugün",
            clear: "Temizle",
            choose: "Seç",
            dateFormat: "dd.mm.yy",
            firstDayOfWeek: 1,
            accept: "Evet",
            reject: "Hayır",
            apply: "Uygula",
            startsWith: "İle Başlayan",
            endsWith: "İle Biten",
            contains: "İçerir",
            notContains: "İçermez",
            equals: "Eşit",
            emptyMessage: 'Hiç sonuç bulunamadı',
            emptyFilterMessage: 'Hiç sonuç bulunamadı'
        });
    }
}
