import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { CampaignDto, CampaignSmsDto, ICampaigns } from '../dto/campaign';


@Injectable({
  providedIn: 'root'
})
export class CampaignsService {

  constructor(private http: HttpClient) {}

  getAll() {
    return this.http.get<ICampaigns[]>(`${environment.url}campaigns`);
  }

  create(data:CampaignDto) {
    return this.http.post<ICampaigns>(`${environment.url}campaigns/create`,data );
  }

  update(id:string, data: CampaignDto) {
    return this.http.put<ICampaigns>(`${environment.url}campaigns/update/${id}`, data);
  }

  getAllSelectItems() {
    return this.http.get<ICampaigns[]>(`${environment.url}campaigns/items`);
  }

  sendSms(data:CampaignSmsDto) {
    return this.http.post<ICampaigns>(`${environment.url}campaigns/sendSms`,data );
  }

}
