import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { StatusGroupData } from '../../pages/settings/status-group/utils/status-group.interface';
import { StatusCardInfo } from '../../pages/settings/status/utils/status-card.interface';
import { StatusData, StatusFilterData } from '../../pages/settings/status/utils/status.interface';
import { ILevel } from '../../pages/settings/levels/levels.interface';

@Injectable({
  providedIn: 'root'
})
export class StatusService {
  constructor(private http: HttpClient) { }

  getAllStatusGroups() {
    return this.http.get<StatusGroupData[]>(`${environment.url}status-group/getAll`);
  }

  createStatusGroup(statusGroup: StatusGroupData) {
    return this.http.post<StatusGroupData>(`${environment.url}status-group/create`, statusGroup);
  }

  updateStatusGroup(statusGroup: StatusGroupData) {
    return this.http.patch<StatusGroupData>(`${environment.url}status-group/update`, statusGroup);
  }

  getAllStatus(customer?: string, services?: string[]) {
    return this.http.post<StatusData[]>(`${environment.url}status/getAll`, {customer, services});
  }

  getAllStatusesForFilter(customer?: string, services?: string[],actionParty?:string) {
    return this.http.post<StatusFilterData[]>(`${environment.url}status/getAllForFilter`, {customer, services,actionParty});
  }

  getAllAsGrouped() {
    return this.http.post<StatusCardInfo[]>(`${environment.url}status/getAllAsGrouped`, {});
  }

  getNextStatuses( serviceId: string, getAll?: boolean) {
    return this.http.post<StatusData[]>(`${environment.url}status/getNextStatuses`, {serviceId, getAll});
  }

  createStatus(status: StatusData) {
    return this.http.post<StatusData>(`${environment.url}status/create`, status);
  }

  updateStatus(status: StatusData) {
    return this.http.patch<StatusData>(`${environment.url}status/update`, status);
  }

  updateServiceStatus(serviceId: string, nextStatus: string) {
    return this.http.post<{serviceId: string, status:StatusData,levelData?:{level:ILevel,isLocked:boolean}}>(`${environment.url}status/updateServiceStatus`, {serviceId, nextStatus});
  }

  updateServiceStatusForCancel(serviceId: string, nextStatus: string, cancelReason?: string, rejectReason?: string, rejectDescription?: string) {
    return this.http.post<{serviceId: string, status:StatusData}>(`${environment.url}status/updateServiceStatusForCancel`, {serviceId, nextStatus, cancelReason, rejectReason, rejectDescription});
  }
}
