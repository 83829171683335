import { Injectable } from '@angular/core';

// 30 dakika
const DEFAULT_EXPIRATION = 1000 * 60 * 30;

export interface ICacheItem {
    expires: number;
    value: any;
}

@Injectable({
    providedIn: 'root'
})
export class AppCacheService {
    cache: { [id: string]: ICacheItem };

    constructor() {
        this.cache = {};
    }

    set(key: string, obj: any, expires: number = DEFAULT_EXPIRATION) {
        this.cache[key] = {
            expires: Date.now() + expires,
            value: obj
        };
    }

    remove(key: string) {
        delete this.cache[key];
    }

    get(key: string) {
        const item = this.cache[key];

        if (!item) {
            return null;
        }

        if (item.expires < Date.now()) {
            this.remove(key);
            return null;
        }

        return item.value;
    }
}
