import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../services/auth/auth.service';
import { AppMessageService } from '../../../services/common/app-message.service';
import { RecaptchaComponent } from '../../../components/recaptcha/recaptcha.component';
import { AppSettingsService } from '../../../services/settings/app-settings.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit,AfterViewInit {
  @Input() email = '';
  @Input() otpToken = '';
  @ViewChild("recapthca",{ static: false }) recapthca: RecaptchaComponent;

  formEmail: FormGroup;
  otpForm: FormGroup;

  show = false;
  emailSubmitted = false;
  emailErrorMessage = '';

  lastCaptchaToken = '';

  showEmailSubmitBtn = false;

  otpSubmitted = false;
  tokenErrorMessage = '';
  showTokenDialog = false;
  tokenCountdown = 0;
  recaptchaSiteKey = environment.recaptchaKey;

  countdownActive = false;

  constructor(private fb: FormBuilder,
              private authService: AuthService,
              private appMsgService: AppMessageService,
              private router: Router,
              private appSettings:AppSettingsService,
              private confirmation: ConfirmationService, private cdr:ChangeDetectorRef) {
    this.formEmail = this.fb.group({
      email: new FormControl('', [Validators.required]),
    });

    this.otpForm = this.fb.group({
      otpToken: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]),
    });
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(){
    // if(this.recapthca){
    //  this.recapthca.start();
   // }
}
  onSendEmail() {
    this.emailSubmitted = true;

    if (!this.formEmail.valid) {
      return;
    }

    const email = this.formEmail.controls.email.value;

    this.authService.forgotPassword(email, this.lastCaptchaToken, '')
                    .pipe(
                      catchError((err) => {
                        this.appMsgService.reportError(err);

                        return EMPTY;
                      })
                    )
                    .subscribe((response) => {
                      this.appMsgService.resetSubmitButton();
                      if (response.result === 'token') {
                        this.tokenCountdown = 180;
                        this.showTokenDialog = true;
                      } else {
                        this.emailErrorMessage = 'PIN kodu gönderilemedi.';
                      }
                    });
  }

  onSendOTPToken() {
    this.otpSubmitted = true;

    if (!this.otpForm.valid) {
      return;
    }

    const email = this.formEmail.controls.email.value;
    const otpToken = this.otpForm.controls.otpToken.value;

    this.authService.forgotPassword(email, this.lastCaptchaToken, otpToken)
                    .pipe(
                      catchError((err) => {
                        this.appMsgService.reportError(err);

                        return EMPTY;
                      })
                    )
                    .subscribe((response) => {
                      if (response.result === 'token') {
                        this.tokenCountdown = 180;
                        this.countdownActive = true;
                        this.showTokenDialog = true;
                      } else if (response.result === 'success') {
                        this.showTokenDialog = false;
                        this.tokenCountdown = 0;
                        this.countdownActive = false;

                        this.confirmation.confirm({
                          header: 'Şifre yenileme',
                          message: 'Yeni şifreniz başarıyla e-posta adresinize gönderildi.',
                          acceptLabel: 'Tamam',
                          acceptButtonStyleClass: 'p-button-success',
                          acceptIcon: 'pi pi-check',
                          rejectVisible: false,
                          accept: () => {
                            this.router.navigate(['/login']);
                          }
                        });
                      }
                    });
  }

  showCaptchaResponse(e) {
    if(e){
    this.lastCaptchaToken = e;
    this.showEmailSubmitBtn = true;
    this.cdr.detectChanges();
    }
  }

  expireCaptcha() {
    this.showEmailSubmitBtn = false;
  }

  onCountdownCompleted() {
    this.countdownActive = false;
  }
}
