import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
}

const scriptSrc = `https://maps.googleapis.com/maps/api/js?key=${environment.googleMapsApiKey}&libraries=places&language=tr&region=TR`;
const script = document.createElement('script');
script.src = scriptSrc.toString();
script.async = true;
script.defer = true;
document.head.appendChild(script);

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
