<div class="p-grid table-demo">
    <div class="p-col-12">
        <div class="card">
            <p-table #dt [value]="supplierStaffList" dataKey="_id" styleClass="oc-border" [rowHover]="true" [rows]="10"
                [paginator]="true"
                [globalFilterFields]="['name', 'tradeInfo.taxNo', 'adress.formattedAdress', 'phone']">
                <ng-template pTemplate="caption">
                    <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
                        Tedarikçi Çalışanlarım
                        <div class="p-fluid p-formgrid p-grid">
                            <div *ngIf="!isSupplier && !special" class="p-field p-col">
                                <div style="margin: auto; margin-right: 0px; padding-right: 25px;">
                                    <p-dropdown inputId="supplier" [options]="suppliersLists"
                                        [(ngModel)]="selectedSupplier" placeholder="Seçiniz" appendTo="body"
                                        filter="true" appFilterDropdown></p-dropdown>
                                </div>
                            </div>
                            <div class="p-field p-col">
                                <div style="    margin-top: 0.5rem !important;">
                                    <p-triStateCheckbox [(ngModel)]="supplierType"
                                        [label]="supplierType === true ? 'Aktif Çalışanlar' : (supplierType === false) ? 'Pasif Çalışanlar' : 'Tüm Çalışanlar'">
                                    </p-triStateCheckbox>
                                </div>
                            </div>
                            <div class="p-field p-col">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search"></i>
                                    <input pInputText (input)="searchChange($event)" type="text" placeholder="Ara" />
                                </span>
                            </div>

                            <div class="p-field p-col">
                                <div style="padding-left: 15px;">
                                    <button pButton type="button" (click)="getSupplierStaffList()" label="Ara"
                                        icon="pi pi-search">
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th>Çalışan Adı</th>
                        <th *ngIf="!isSupplier">Tedarikçi Adı</th>
                        <th *ngIf="!isSupplier">Son Konum Paylaşım Tarihi</th>
                        <th style="width: 8rem">Durum</th>
                        <th style="width: 14rem"> <button pButton type="button" label="Yeni Çalışan Ekle"
                                (click)="openStaffDialog()" icon="pi pi-plus"
                                class="p-button p-button-info p-button-text" style="float: right;"></button></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-staff>
                    <tr>
                        <td><a style="cursor: pointer;" (click)="openStaffDialog(staff)" icon="pi pi-info-circle"
                                label="Show">{{ staff.name + ' ' + staff.surname }}</a>
                        </td>
                        <td *ngIf="!isSupplier">{{ staff?.supplier?.name }}</td>
                        <td *ngIf="!isSupplier">{{ staff?.lastLocationUpdateDate | date: 'dd/MM/yyyy HH:mm:ss' }}</td>

                        <td>
                            <p-tag [severity]="staff.isActive ? 'success' : 'danger'"
                                [value]="staff.isActive ? 'Aktif' : 'Pasif'"></p-tag>
                        </td>
                        <td></td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="4">Henüz çalışan girişi yapılmadı.</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>
<app-supplier-staff-dialog #dialog (onNewStaffAdded)="onNewStaffAdded($event)" (onStaffEdit)="onStaffEdit($event)" [isSupplier]="isSupplier" [special]="special" [supplierId]="isSupplier"  [selectedSupplier]="selectedSupplier" ></app-supplier-staff-dialog>
