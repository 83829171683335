<div class="layout-wrapper" (click)="onLayoutClick()"
                            [ngClass]="{'layout-horizontal': isHorizontal(),
                                        'layout-overlay': isOverlay(),
                                        'layout-static': isStatic(),
                                        'layout-slim':isSlim(),
                                        'layout-static-inactive': staticMenuDesktopInactive,
                                        'layout-mobile-active': staticMenuMobileActive,
                                        'layout-overlay-active':overlayMenuActive}">
    <app-topbar></app-topbar>
    <app-user-guide></app-user-guide>

    <div class="super-layout-container" [class.openSideMenu]="staticMenuMobileActive">
      <div class="layout-menu-container" [class.menu-size-organizer]="!isMobile()" (click)="onMenuClick($event)">
        <div class="layout-menu-content">
            <app-menu [reset]="resetMenu"></app-menu>
        </div>
      </div>
    </div>

    <div class="layout-content">
        <!-- <app-breadcrumb></app-breadcrumb> -->
        <div class="layout-content-container container-min-height">
          <!-- error reporting dialog -->
          <p-dialog header="İşlem sırasında hata oluştu" [(visible)]="showErrorDialog" [modal]="true" [contentStyle]="{'max-height':'200px', 'width': '400px'}">
                <div class="error-text">
                    {{ errorText }}
                </div>
              <p-footer>
                  <button pButton type="button" label="Kapat" class="ui-button-secondary" (click)="closeErrorDialog()" style="width:100px"></button>
              </p-footer>
            </p-dialog>

            <!-- working panel -->
            <p-blockUI [blocked]="showWorkingPanel === true" #blockUI>
            </p-blockUI>

            <div class="loading-panel" [class.hidden]="showWorkingPanel === false">
              <p-progressSpinner [style]="{width: '50px', height: '50px'}" animationDuration="3s"></p-progressSpinner> <br>
              <p>{{ workingMsg }}</p>
            </div>

            <!-- success dialog -->
            <p-confirmDialog key="success"
                             header="Başarılı" acceptLabel='Tamam' [rejectVisible]="false" icon="pi pi-info-circle"></p-confirmDialog>

            <!-- warning dialog -->
            <p-confirmDialog key="warning" header="Uyarı" acceptLabel='Tamam' [rejectVisible]="false" icon="pi pi-exclamation-triangle"></p-confirmDialog>

            <!-- confirm -->
            <p-confirmDialog key="confirm"
                             acceptLabel='Evet'
                             rejectLabel="Hayır"
                             icon="pi pi-question-circle-o"
                             acceptIcon="pi pi-check"
                             rejectIcon="pi pi-times"
                             acceptButtonStyleClass="p-button-primary"
                             rejectButtonStyleClass="p-button-secondary p-button-text"></p-confirmDialog>

            <!-- toast -->
            <p-toast key="systemToast"></p-toast>
            <p-toast key="stickyNote" position="bottom-right"></p-toast>
            <!-- toast -->
          <router-outlet></router-outlet>
          <div class="push"></div>
        </div>
        <div class="layout-mask" *ngIf="staticMenuMobileActive"></div>
    </div>
</div>
