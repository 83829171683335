import { SelectItem } from "primeng/api";

enum OccurrenceFileStatusEnum {
  none = '',
  open = 'open',
  closed = 'closed',
  canceled = 'canceled',
  notAssigned = 'notAssigned',
  assigned = 'assigned',
}

const getOccurrenceFileStatusText = (value: OccurrenceFileStatusEnum) => {
  switch (value) {
    case OccurrenceFileStatusEnum.open: return 'Devam Ediyor';
    case OccurrenceFileStatusEnum.closed: return 'Tamamlandı';
    case OccurrenceFileStatusEnum.canceled: return 'İptal edildi';
    case OccurrenceFileStatusEnum.notAssigned: return 'Atama Bekliyor';
    case OccurrenceFileStatusEnum.assigned: return 'Atanmış';
    default: return '';
  }
}

const getOccurrenceFileStatusColor = (value: OccurrenceFileStatusEnum) => {
  switch (value) {
    case OccurrenceFileStatusEnum.open: return 'success';
    case OccurrenceFileStatusEnum.closed: return 'info';
    case OccurrenceFileStatusEnum.canceled: return 'danger';
    case OccurrenceFileStatusEnum.assigned: return 'success';
    case OccurrenceFileStatusEnum.notAssigned: return 'warning';
    default: return 'success'
  }
}

const getOccurrenceFileStatusItems = () => {
  return [
    { value: OccurrenceFileStatusEnum.open, label: 'Devam Ediyor' },
    { value: OccurrenceFileStatusEnum.closed, label: 'Tamamlandı' },
    { value: OccurrenceFileStatusEnum.canceled, label: 'İptal Edildi' },
    { value: OccurrenceFileStatusEnum.notAssigned, label: 'Atama Bekliyor' },
    { value: OccurrenceFileStatusEnum.assigned, label: 'Atanmış' },
    
  ] as SelectItem[];
}

export { OccurrenceFileStatusEnum, getOccurrenceFileStatusText, getOccurrenceFileStatusColor, getOccurrenceFileStatusItems }