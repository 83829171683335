
    <p-toolbar>
        <div class="p-toolbar-group-left">
                <h3>Sistem Parametreleri</h3>
        </div>
        
        <div class="p-toolbar-group-right">
            <p-button label="Ekle" icon="pi pi-plus" (click)="showDialog()"></p-button>
        </div>
    </p-toolbar>


<p-dialog [header]="parameterId ? 'Parametre Değerini Güncelle' : 'Parametre Ekleme Formu'"
          [(visible)]="addDialog" modal="true" appendTo="body">
    <form [formGroup]="form">
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-6">
                <label for="exCode">Referans Kodu</label>
                <input id="exCode" formControlName="exCode" type="text" pInputText />
                <div *ngIf="submitted && form.controls.exCode.errors">
                    <div *ngIf="form.controls.exCode.errors.required" class="validation-error">
                      Referans Kodu zorunludur!
                    </div>
                </div>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="name">Parametre Adı</label>
                <input id="name" formControlName="name" type="text" pInputText />
                <div *ngIf="submitted && form.controls.name.errors">
                    <div *ngIf="form.controls.name.errors.required" class="validation-error">
                      Parametre adı zorunludur!
                    </div>
                </div>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="group">Parametre Grubu</label>
                <input id="group" formControlName="group" type="text" pInputText />
                <div *ngIf="submitted && form.controls.group.errors">
                    <div *ngIf="form.controls.group.errors.required" class="validation-error">
                      Grup alanı zorunludur!
                    </div>
                </div>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="type">Tipi</label>
                <p-dropdown [options]="parameterTypes" formControlName="type" appendTo="body" id="type"></p-dropdown>
                <div *ngIf="submitted && form.controls.type.errors">
                    <div *ngIf="form.controls.type.errors.required" class="validation-error">
                      Parametre tipi zorunludur!
                    </div>
                </div>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="value">Değer</label>
            <ng-container [ngSwitch]="form.controls.type.value">
                <input id="value" formControlName="value" type="text"  *ngSwitchCase="'string'" pInputText />
                <p-calendar formControlName="value" *ngSwitchCase="'date'" appendTo="body" dateFormat="dd.mm.yy" inputId="value"></p-calendar>
                <p-chips formControlName="value" *ngSwitchCase="'array'"></p-chips>
            </ng-container>
                <div *ngIf="submitted && form.controls.value.errors">
                    <div *ngIf="form.controls.value.errors.required" class="validation-error">
                        Değer alanı zorunludur!
                    </div>
                </div>
            </div>
            <div class="p-field p-col-12 p-md-12">
                <label>Açıklama</label>
                <input id="description" formControlName="description" type="text" pInputText />
                 <div *ngIf="submitted && form.controls.description.errors">
                    <div *ngIf="form.controls.description.errors.required" class="validation-error">
                      Açıklama alanı zorunludur!
                    </div>
                </div>
            </div>
        </div>
    </form>
        <p-footer>
            <button type="button" pButton [icon]="parameterId ? 'pi pi-refresh' : 'pi pi-check'"
                (click)="parameterId  ? editParameter() : addParameter()"
                 [label]="parameterId ? 'Güncelle' : 'Kaydet'"
                class="p-button-text"></button>
            <button type="button" pButton icon="pi pi-times" (click)="addDialog = false;"
                label="İptal" class="p-button-text"></button>
        </p-footer>    
</p-dialog>