import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IUserGuide } from 'src/app/pages/operator/user-guides/interface/userguide.interface';

@Injectable({
  providedIn: 'root'
})
export class UserGuidesSharedService {
  private userGuidesSource = new BehaviorSubject<IUserGuide[]>([]);
  userGuides$ = this.userGuidesSource.asObservable();

  updateUserGuides(userGuides: IUserGuide[]) {
    this.userGuidesSource.next(userGuides);
  }
}
