import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { saveAs } from "file-saver";
import { IFile } from "../../domain/file";

@Injectable({
    providedIn: "root",
})
export class CMService {
    constructor(private http: HttpClient) {}

    creatFile(modalId: string, file: any, args: {}, storagePlace: string,_case:string) {
        return this.http.post<any>(`${environment.url}cm/save-document`, {
            file,
            cmData: args,
            storagePlace,
            modalId,
            case:_case
        });
    }

    getFile(piid: string) {
        return this.http.get<any>(`${environment.url}cm/get-document/${piid}`);
    }

    searchFile(filteredData:any,type?:'policy'|'insuranceFileNo') {
        return this.http.post<any>(`${environment.url}cm/search-document`,{filteredData,type});
    }
    download(file: IFile) {
        saveAs(file.url, file.filename);
    }

    removeFile(id: string, fileId: string) {
        return this.http.delete<IFile[]>(
            `${environment.url}cm/deleteFile/${id}/${fileId}`
        );
    }
}

export interface CM_DTO{

    AsistansDosyaNo?:string;
    AsistansHizmetNo?:string;
    PoliceNo?:string;
    YenilemeNo?:string,
    EkNo?:string,
    HasarDosyaNo?:string,
    EklenmeTarihi?:Date,
    OrganizasyonID?:number,
    GonderenID?:string,
    TedarikciKodu?:string,
    Aciklama?:string,
    Status?:string,
    FaturaNo?:string
}

export enum storagePlaceEnum{
    bill="bill",
    photo="photo",
    files="files",
    announcements="announcements",
    poFile="poFile"
}
