import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { colunmData, fromOptions, Service, tableOptions } from './utilities/service.interface';
import { serviceMap } from './utilities/serviceMap';
/*  Kaldırılabilir, başka bir format veya özgür formata dönüştürmek için kaldırmak yeterli */
import { SelectItem } from 'primeng/api';
/* --------------------------------------------------------------------------------------- */
import { Observable } from 'rxjs';
import  * as Translator  from './utilities/translateFuctions';

@Injectable({
  providedIn: 'root'
})
export class OctoTableMasterService implements Service {

  constructor(private http: HttpClient) { }

  /**
   * Tablonun üzerinde gözükecek olan sayfa başlığının serviceMap üzerinden alan metod.
   * @param page İlgili sayfanın serviceMap üzerinde eşleşen keyi
   * @returns Tabloda gösterilmek üzere başlık
   */
  getTitle(page: string): string {
    page = page.toLowerCase();
    return serviceMap[page]['title'];
  }

  /**
   * Tablonun kolon bilgilerinin filtrelenerek alındığı metod.
   * @param page İlgili sayfanın serviceMap üzerinde eşleşen keyi
   * @returns Tablodaki kolon bilgilerini array olarak return eder
   */
  colunms(page: string): colunmData[] {
    page = page.toLowerCase();
    return serviceMap[page]['colunms'].filter( (colunm: colunmData) => !colunm.ignore );
  }

  /**
   * [LazyLoad ile ÇALIŞMAZ!!] Filtreleneceği belirlenmiş kolonları map üzerinden ayıklamaya yarayan metod.
   * @param page İlgili sayfanın serviceMap üzerinde eşleşen keyi
   * @returns fitrelenecek sütunları dizi formatında return eder
   */
  filters(page: string): string[] {
    page = page.toLowerCase();
    let filter: string[] = [];
    serviceMap[page]['colunms'].forEach( (col: colunmData) => {
      if(col.filter) {
        filter.push(col.field);
      }
    });
    return filter;
  }

  /**
   * Tablonun oluşturma ayarlarını değiştirmeye olanak tanıyan seçenekleri serviceMap üzerinden alan metod.
   * @param page İlgili sayfanın serviceMap üzerinde eşleşen keyi
   * @returns Seçenekleri return eder
   */
  options(page: string): tableOptions {
    page = page.toLowerCase();
    const options = serviceMap[page]['options'];
    return options ? options : {};
  }

  /**
   * Tabloda gösterilecek satırların alındığı metod. Çeşitli filtrelere göre datayı LazyLoad yapmak için Apiden alır.
   * @param page İlgili sayfanın serviceMap üzerinde eşleşen keyi
   * @param first Tabloda gösterilecek ilk kaydın indexi
   * @param row Tabloda gösterilecek satır sayısı
   * @param text Tablo üzerinde arama çubuğundan gelen text alan
   * @param filter.field Sıralaması yapılacak alanın adı
   * @param filter.order Sıralamanın tipini belirler (1 veya -1 olur) [Büyükten küçüğe veya küçükten büyüğe şeklinde]
   * @returns .subscribe(res) yapıldığında | res.rows = tabloda listelenecek satırlar | res.totalRecords = toplam kayıt 
   */
  getListData(page: string, first: number, row: number,
    search: {text: string, fields: string[]}, 
    sort?: { field: string, order: number}
    ): Observable<{rows: any[], totalRecords: number}> {

    page = page.toLowerCase();
    const query = `?first=${first}&row=${row}`;
    const prefix = getPrefix(page);
    const populate = this.getPopulateFields(page);

    return this.http.post<{rows: any[], totalRecords: number}>(`${environment.url}${prefix}${page}/getList${query}`, {search, sort, populate});
  }


  getPopulateFields(page: string): string[] {
    let populate: string[] = [];
    this.colunms(page).filter( c => c.type === 'multiSelect').forEach( c => {
      if(!c.options.multiSelect.list || (c.options.multiSelect.list && c.options.multiSelect.list.length === 0)) {
        populate.push(c.options.multiSelect.from.entity);
      }
    });
    return populate;
  }


  getTableFilters(page: string): any[] {
    page = page.toLowerCase();
    return serviceMap[page]['filters'] ? serviceMap[page]['filters'] : [];
  }


  /**
   * Tabloda hücrelerin verilerini doldurmak için işlendiği metod.
   * @param page İlgili sayfanın serviceMap üzerinde eşleşen keyi
   * @param colData ilgili hücrenin kolon bilgisi
   * @param data Hücrede gösterilmek üzere işlenecek veri, object de olabilir(multiSelect durumunda - populate edilmiş olmalı) 
   * @returns string formatında işlenmiş ve dönüştürülmüş veriyi html elementi şeklinde gömülmek üzere return eder 
   */
  fillCell(page: string, colData: colunmData, data: any, row?: any): string {
    const excludedTypes = ['color', 'date', 'translate']
    if( (!colData.options && !excludedTypes.includes(colData.type))) {
      switch(typeof data) {
        case 'number': return data.toString();
        case 'string': return data;
        //case 'boolean': return row ? '<i class="pi pi-check"></i>' : '<i class="pi pi-times"></i>';
        default: return '';
      }
    }
    
    else if(colData.options && colData.options.action === 'link') {
      const after = colData.options.link.fieldToAdd ? '/' + row[colData.options.link.fieldToAdd] : '';
      return data ? `
        <a href="${environment.clientUrl}${colData.options.link.url}${after}">${data}</a>
      ` : '';
    }

    // Geliştirilebilir
    else if(colData.type === 'translate') {
      return Translator[colData.options.translate](data);
    }

    else if (colData.type === 'date' && data) {
      let dateOptions: any = { day: '2-digit', month: '2-digit', year: 'numeric' };
      if (colData.options && colData.options.date && colData.options.date.hours) {
        dateOptions = { ...dateOptions, hour: '2-digit', minute: '2-digit' };
      }
      return new Date(data).toLocaleDateString('tr-TR', dateOptions);
    }

    else if(colData.type === 'money') {
      return data.toString() + ' ₺';
    }

    else if(colData.type === 'object' && data) {
      let value: any = data;
      colData.options.object.forEach( f => {
        if(colData.field==='createdBy'){
          value = value[f] ? value[f] : 'Kullanıcı Kayıtlı Değil';
        }
        else{
          value = value[f] ? value[f] : data;

        }
      });
      return value;
    }
    

    return '';
  }

  /**
   * Kayıt oluşturmak için kullanılan metod.
   * @param page İlgili sayfanın serviceMap üzerinde eşleşen keyi
   * @param data İlgili sayfanın object formatında bilgileri
   * @returns Eklenen verinin kendisini return eder
   */
  create(page: string, data: any) {
    page = page.toLowerCase();
    const prefix = getPrefix(page);
    const populate = this.getPopulateFields(page);

    return this.http.post<any>(`${environment.url}${prefix}${page}/create`, {data, populate});
  }

  /**
   * Kayıt güncellemek için kullanılan metod.
   * @param page İlgili sayfanın serviceMap üzerinde eşleşen keyi
   * @param data İlgili sayfanın object formatında bilgileri
   * @returns Değiştirilen verinin güncellenen haini return eder
   */
  update(page: string, data: any) {
    page = page.toLowerCase();
    const prefix = getPrefix(page);
    const populate = this.getPopulateFields(page);

    return this.http.patch<any>(`${environment.url}${prefix}${page}/update`, {data, populate});
  }

  controlFields(data: any, cols: colunmData[]): {isOk: boolean, emptyFields: string[]} {
    let result = {isOk: true, emptyFields: []}
    cols.filter(col => col.required).forEach(col => {
      if(!data[col.field] && col.type !== 'boolean') {
        result.emptyFields.push(col.header);
        result.isOk = false;
      }
    });
    return result;
  }

  getMultiSelectData(from: {entity: string, options: fromOptions}): Observable<SelectItem<any>[]> {
    const page = from.entity.toLowerCase();
    const prefix = getPrefix(page);
    return this.http.post<SelectItem[]>(`${environment.url}${prefix}${page}/getMultiSelect`, from.options);
  }
  getAllMultiSelectData(from: {entity: string, options: { filters?: {field: string, value: any}[]}}) {
    const page = from.entity.toLowerCase();
    const prefix = getPrefix(page);
    return this.http.post<any[]>(`${environment.url}${prefix}${page}/getAllMultiSelect`, from.options);
  }

  getListForAssurance(tableName:string){
    return this.http.post<SelectItem[]>(`${environment.url}assurance-overrun/${tableName}/getList`, {});

  } 
}


export function getPrefix(page: string) {
  if(!serviceMap[page]) {
    console.error('serviceMap üzerinde ilgili sayfanın kaydına rastlanmadı!');
    return '';
  }
  return serviceMap[page].prefix ? serviceMap[page].prefix + '/' : '';
}