<div class="container">
    <div class="left-section">
        <p-listbox
            [options]="versions"
            [(ngModel)]="selectedVersion"
            optionLabel="label"
            [filter]="true"
            [filterBy]="['label', 'date']"
            [listStyle]="{ 'max-height': '500px' }"
        >
            <ng-template let-versions pTemplate="item">
                <div class="list-box-line">
                    <div>{{ versions.label }}</div>
                    <div style="font-size: 12px; font-weight: 200">
                        {{ versions.date | date : "dd.MM.yyyy" }}
                    </div>
                </div>
            </ng-template>
        </p-listbox>
    </div>
    <div *ngIf="selectedVersion" class="right-section">
        <div class="card">
            <div class="hakkinda-logos">
                <div class="selected-version-date">
                    <span style="font-size: 25px"
                        >v{{ selectedVersion.version }}</span
                    >
                    <span
                        style="
                            font-size: 13px;
                            padding-left: 20px;
                            font-weight: 200;
                        "
                        >{{ selectedVersion.date | date : "dd.MM.yyyy" }}</span
                    >
                </div>

                <div>
                    <img
                        alt="octopus"
                        src="assets/images/octopus/octopus_logo_white.png"
                        height="30"
                    />
                </div>
            </div>
            <div class="content">
                <ul class="version-changes">
                    <li *ngFor="let change of selectedVersion?.changes">
                        <i class="pi pi-check-square"> {{ change }} </i>
                        <p-divider></p-divider>
                    </li>
                </ul>
            </div>
            <div class="footer">
                <a
                    href="mailto:destek@ideabilisim.com"
                    style="text-align: right; color: white; font-size: 0.8rem"
                    >destek&#64;ideabilisim.com</a
                >
            </div>
        </div>
    </div>
</div>
