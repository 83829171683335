<div class="p-grid">
    <div class="p-xs-12 p-sm-12 p-md-offset-4 p-md-4">
        <div class="card p-p-0">
            <p-panel header="Raporunuz hazır" class="p-m-0">
                <div class="p-grid">
                    <div class="p-col-12 p-text-center">
                        Talep ettiğiniz rapor indirilmek için hazır.
                    </div>
                    <div class="p-col-12 p-text-center">
                        <p-button label="Tıklayınız" (click)="download()"></p-button>
                    </div>
                </div>
            </p-panel>
        </div>
    </div>
</div>