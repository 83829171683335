import { Component, Input, OnInit, SecurityContext } from "@angular/core";
import { CreateScriptDto } from "../../dtos/create-script.dto";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { ScriptService } from "../../services/script.service";
import { DynamicDialogConfig } from "primeng/dynamicdialog";
import { IScript } from "../../models/script.interface";

@Component({
    selector: "app-script-show",
    templateUrl: "./script-show.component.html",
    styleUrls: ["./script-show.component.scss"],
})
export class ScriptShowComponent implements OnInit {
    @Input() scriptData: CreateScriptDto;
    @Input() scriptId: string;

    showScript: boolean = false;

    constructor(
        private sanitizer: DomSanitizer,
        private scriptService: ScriptService,
        private config: DynamicDialogConfig,
    ) {}

    ngOnInit():void {
        if(this.config.data) {
            this.scriptData = this.config.data.scriptData;
            this.scriptData.safeContent = this.sanitizer.bypassSecurityTrustHtml(
                this.scriptData.content
            );
        }
    }

    getContent(scriptData: IScript){
        this.scriptId = scriptData._id;
    }

    showScriptDialog(data: CreateScriptDto) {
        this.showScript = true;
        this.scriptData = data;
        this.scriptData.safeContent = this.sanitizer.bypassSecurityTrustHtml(
            this.scriptData.content
        );
    }

    sanitizeContent(html: string): SafeHtml {
        const sanitizedHtml = this.sanitizer.sanitize(
            SecurityContext.HTML,
            html,
        );
        return this.sanitizer.bypassSecurityTrustHtml(sanitizedHtml);
    }
}