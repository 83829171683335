<p-dialog
[header]="staff._id ? 'Tedarikçi Çalışanı Güncelleme Formu' : 'Yeni Tedarikçi Çalışanı Kayıt Formu'"
    [(visible)]="staffDialog"
    [modal]="true"
    showEffect="fade"
    [style]="{ width: '800px' }"
    #supplierStaffListCom
    >

    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-6">
            <label for="name">Çalışan Adı</label>
            <input id="name" [(ngModel)]="staff.name" type="text" pInputText />
        </div>
        <div class="p-field p-col-12 p-md-6">
            <label for="surname">Çalışan Soyadı</label>
            <input id="surname" [(ngModel)]="staff.surname" type="text" pInputText />
        </div>
        <div class="p-field p-col-12 p-md-6">
            <label for="phone">Çalışan Telefon</label>
            <input id="phone" maxlength="10"  placeholder="Numarayı 0 olmadan giriniz." [(ngModel)]="staff.phoneNumber" type="text" pInputText />
        </div>
        <div class="p-field p-col-12 p-md-6">
            <label for="nickName">Çalışan Takma Adı</label>
            <input id="nickName" [(ngModel)]="staff.nickName" type="text" pInputText />
        </div>
        <div class="p-field p-col-12 p-md-6">
            <label for="vehicleType">Araç Tipi</label>
            <p-dropdown inputId="vehicleType" [options]="vehicleTypeOptions" [(ngModel)]="staff.vehicleType"
                placeholder="Seçiniz" appendTo="body" filter="true"></p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-6">
            <label for="plaka">Çalışan Araç Plaka</label>
            <input id="plaka" [(ngModel)]="staff.licensePlate" type="text" pInputText />
        </div>
        <div
        class="p-field p-col-12 p-md-6">
            <label for="supplier">Tedarikçi</label>
            <p-dropdown inputId="supplier" [disabled]="isSupplier || special" [options]="suppliersLists" [(ngModel)]="selectedSupplier"
            optionLabel="label" optionValue="value"   placeholder="Seçiniz"  appendTo="body" filter="true"></p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-6">
            <label for="plaka">Çalışan Durumu</label>
            <div><p-inputSwitch   [(ngModel)]="staff.isActive"></p-inputSwitch>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button type="button" pButton
            [icon]="staff._id ? 'pi pi-refresh' : 'pi pi-check'"
            (click)="updateOrSave()" label="Kaydet"
            [label]="staff._id ? 'Güncelle' : 'Kaydet'"
            class="p-button-text">
        </button>
        <button type="button" pButton icon="pi pi-times"
            (click)="staffDialog = false; supplierStaffId = ''"
            label="İptal"
            class="p-button-text">
        </button>
    </ng-template>
</p-dialog>
