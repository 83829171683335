import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
  selector: 'octo-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss']
})
export class CountDownComponent implements OnInit ,OnDestroy{

  constructor() { }


  @Input() counter:number;
  @Output() onCounterFinish=new EventEmitter();
  spin = true;
  timer:any;

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.startCountDown(this.counter);
  }

  startCountDown(counter:number=59){
    this.spin = true;
    this.clearTimer();
    this.counter=counter;
    this.timer= setInterval(() => {
        this.counter=this.counter-1;
        if(this.counter===0){
            this.stopCounting();
        }
    },1000);
    }

  private stopCounting(){
    this.clearTimer();
    this.spin = false;
    this.onCounterFinish.emit();
  }
  private clearTimer(){
    if(this.timer){
        clearInterval(this.timer);
    }
  }

  ngOnDestroy(): void {
    this.clearTimer()
  }



}
