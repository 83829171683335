import { SelectItem } from "primeng/api";

enum OccurrenceTypeEnum {
  none = '',
  breakdown = 'breakdown',
  damage = 'damage',
  outOfFuel = 'outOfFuel',
  singleTyre = 'singleTyre',
  autoLocksmith = 'autoLocksmith',
  naturalDisaster = 'naturalDisaster',
  stolenVehicle = 'stolenVehicle',
  burnedVehicle = 'burnedVehicle',

  water = 'water',
  glass = 'glass',
  electricity = 'electricity',
  locksmith = 'locksmith',
  buildingDamage = 'buildingDamage',
  homeSmallServices = 'homeSmallServices',
  airCombi = 'airCombi',
  heaterCleaning = 'heaterCleaning',
  cleaning = 'cleaning',
  health='health',
  travel='travel',

  funeral = 'funeral',
  otoglass = 'otoglass',
  otomultimedia = 'otomultimedia'

}

const getOccurrenceTypeText = (value: OccurrenceTypeEnum) => {
  switch (value) {
    case OccurrenceTypeEnum.breakdown: return 'Arıza';
    case OccurrenceTypeEnum.damage: return 'Hasar';
    case OccurrenceTypeEnum.outOfFuel: return 'Benzin Bitmesi';
    case OccurrenceTypeEnum.autoLocksmith: return 'Oto Çilingir';
    case OccurrenceTypeEnum.naturalDisaster: return 'Doğal Afet';
    case OccurrenceTypeEnum.singleTyre: return 'Lastik Patlaması';
    case OccurrenceTypeEnum.stolenVehicle: return 'Araç Çalınması';
    case OccurrenceTypeEnum.burnedVehicle: return 'Araç Yanması';

    case OccurrenceTypeEnum.water: return 'Dahili Su';
    case OccurrenceTypeEnum.glass: return 'Cam Kırılması';
    case OccurrenceTypeEnum.electricity: return 'Elektrik';
    case OccurrenceTypeEnum.locksmith: return 'Çilingir';
    case OccurrenceTypeEnum.buildingDamage: return 'Konut Hasarı';
    case OccurrenceTypeEnum.homeSmallServices: return 'Evde Küçük İşler Hizmeti';
    case OccurrenceTypeEnum.airCombi: return 'Kombi ve Klima Bakım';
    case OccurrenceTypeEnum.heaterCleaning: return 'Petek Temizliği';
    case OccurrenceTypeEnum.cleaning: return 'Koltuk ve Halı Yıkama';
    case OccurrenceTypeEnum.funeral: return 'Cenaze Nakli';
    case OccurrenceTypeEnum.otoglass: return 'Oto Cam';
    case OccurrenceTypeEnum.otomultimedia: return 'Oto Teyp/Multimedia';
    default: return '';
  }
}

const getOccurrenceTypeItems = () => {
  return [
    { value: OccurrenceTypeEnum.breakdown, label: 'Arıza' },
    { value: OccurrenceTypeEnum.damage, label: 'Hasar' },
    { value: OccurrenceTypeEnum.singleTyre, label: 'Lastik Patlaması' },
    { value: OccurrenceTypeEnum.outOfFuel, label: 'Benzin Bitmesi' },
    { value: OccurrenceTypeEnum.autoLocksmith, label: 'Oto Çilingir' },
    { value: OccurrenceTypeEnum.naturalDisaster, label: 'Doğal Afet' },
    { value: OccurrenceTypeEnum.stolenVehicle, label: 'Araç Çalınması' },
    { value: OccurrenceTypeEnum.burnedVehicle, label: 'Araç Yanması' },

    { value: OccurrenceTypeEnum.water, label: 'Dahili Su' },
    { value: OccurrenceTypeEnum.glass, label: 'Cam Kırılması' },
    { value: OccurrenceTypeEnum.electricity, label: 'Elektrik' },
    { value: OccurrenceTypeEnum.locksmith, label: 'Çilingir' },
    { value: OccurrenceTypeEnum.buildingDamage, label: 'Konut Hasarı' },
    { value: OccurrenceTypeEnum.homeSmallServices, label: 'Evde Küçük İşler Hizmeti' },
    { value: OccurrenceTypeEnum.airCombi, label: 'Kombi ve Klima Bakım' },
    { value: OccurrenceTypeEnum.heaterCleaning, label: 'Petek Temizliği' },
    { value: OccurrenceTypeEnum.cleaning, label: 'Koltuk ve Halı Yıkama' },

    { value: OccurrenceTypeEnum.funeral, label: 'Cenaze Nakli' },

    { value: OccurrenceTypeEnum.otoglass, label: 'Oto Cam' },
    { value: OccurrenceTypeEnum.otomultimedia, label: 'Oto Teyp/Multimedia' },

  ] as SelectItem[];
}

export { OccurrenceTypeEnum, getOccurrenceTypeText, getOccurrenceTypeItems }