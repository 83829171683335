import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class AppSettingsService {
    version: string;
    versionName: string;
    season: string;
    loginStrategy: string;
    twoFactorAuthentication: boolean;
    captchaGuard: boolean;
}
