import { Component, ElementRef, EventEmitter, NgZone, OnInit, Output, Renderer2 } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AppMessageService } from '../../services/common/app-message.service';

declare const grecaptcha: any;
@Component({
  selector: 'app-recaptcha',
  templateUrl: './recaptcha.component.html',
  styleUrls: ['./recaptcha.component.scss']
})
export class RecaptchaComponent implements OnInit {

@Output() onResponse = new EventEmitter<string>();

  private siteKey = environment.recaptchaKey;
  private widgetId: number;


  constructor(private msgService:AppMessageService,private elementRef: ElementRef, private renderer: Renderer2, private ngZone: NgZone) { }

  ngOnInit(): void {
  }

  start(){
    // Load the reCAPTCHA script dynamically
    const script = this.renderer.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=explicit`;
    script.async = true;
    script.defer = true;

    script.onload = () => {
      // Initialize reCAPTCHA when the script is loaded
      this.ngZone.runOutsideAngular(() => {
        this.widgetId=grecaptcha?.render('recaptcha', {
          sitekey: this.siteKey,
          callback: (response: string) => {
            this.onResponse.emit(response);
          }
        });
      });
    };
    script.onerror = (error: Event | string) => {
        this.msgService.errorToast("Hata",'reCaptcha hatası alındı.')
        // console.error('Error loading reCAPTCHA script:', error);
        return;
      };

    this.renderer.appendChild(this.elementRef.nativeElement, script);
  }
}
