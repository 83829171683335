import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { IListEnvelope } from '../domain/list.interface';
import { IOccurrenceParameter,OccurrenceParameterDto } from '../pages/occurrence-parameter/occurrence-parameter';

@Injectable({
  providedIn: 'root'
})
export class OccurenceParameterService {

  constructor(private http: HttpClient) {}

  getParamaters(page?: number, limit?: number, type?: string, searchText?: string, group?:string): Observable<IListEnvelope<IOccurrenceParameter>> {
    let query = `?&page=${page ?? ''}&limit=${limit ?? ''}&text=${searchText ?? ''}`;
    if (type) {
    query = query+ `&type=${type ?? ''}`;
    }
    if (group) {
      query = query+ `&group=${group ?? ''}`;
      }
    return this.http.get<IListEnvelope<IOccurrenceParameter>>(`${environment.url}occurrenceParameters${query}`);
  }

  addParameter(data: OccurrenceParameterDto) {
    return this.http.post<IOccurrenceParameter>(`${environment.url}occurrenceParameters`, data);
  }

  editParameterValue(id: string, data: OccurrenceParameterDto) {
    return this.http.put<IOccurrenceParameter>(`${environment.url}occurrenceParameters/${id}`, data);
  }

  getValues(name: string) {
    return this.http.get<any>(`${environment.url}occurrenceParameters/${name}`);
  }
  
  getParameterByName(name: string) {
    return this.http.get<any>(`${environment.url}occurrenceParameters/getParameter/${name}`);
  }

  getAllGroups() {
    return this.http.get<any>(`${environment.url}occurrenceParameters/allGroups`);
  }
}
