import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ISupplierCar, SupplierCarDto } from '../../domain/supplier-car';
import { Observable } from 'rxjs-compat/Observable';

@Injectable({
    providedIn: 'root'
})
export class SupplierCarService {
    getSupplierItems() {
      throw new Error('Method not implemented.');
    }
    constructor(
        private http: HttpClient
    ) { }

    addSupplierCar(supplierCar: SupplierCarDto) {
        return this.http.post<ISupplierCar>(`${environment.url}supplier-cars/create`, supplierCar);
    }

    editSupplierCar(id: string, supplierCar: SupplierCarDto) {
        return this.http.put<ISupplierCar>(`${environment.url}supplier-cars/${id}`, supplierCar);
    }
    
    
}