import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { CreateScriptDto } from "../dtos/create-script.dto";
import { IScript } from "../models/script.interface";
import { environment } from "src/environments/environment";
import { IScriptFilter } from "../models/script-filter.model";



@Injectable({
    providedIn: 'root'
})
export class ScriptService {
    constructor(private http: HttpClient) {}

    private _scriptPage = new BehaviorSubject<string>("home");
    readonly scriptPage$ = this._scriptPage.asObservable();

    private updatedScriptListSubject = new Subject<IScript>();  
    readonly updatedScriptList$ = this.updatedScriptListSubject.asObservable();

    notifyScriptListUpdated(script: IScript ) {
        this.updatedScriptListSubject.next(script);
    }
  

    changePage(currentPage: string) {
        this._scriptPage.next(currentPage);
    }

    create(script: CreateScriptDto): Observable<IScript>{
        return this.http.post<IScript>(
            `${environment.url}scripts/create`,
            script
        );
    }

    update(
        scriptId: string,
        script: CreateScriptDto
    ): Observable<IScript> {
        return this.http.patch<IScript>(
            `${environment.url}scripts/update/${scriptId}`,
            script
        );
    }

    getList(filters: IScriptFilter) {
        return this.http.post<IScriptFilter[]>(
            `${environment.url}scripts/list`,
            filters
        );
    }
  
    getListWithoutContent(filters: IScriptFilter) {
        return this.http.post<IScriptFilter[]>(
            `${environment.url}scripts/listWithoutContent`,
            filters
        )
    }

    getScript(scriptId: string){
        return this.http.get<IScript>(
            `${environment.url}scripts/${scriptId}`,
           
        )
    }

    getAllActiveTags(): Observable<string[]> {
        return this.http.post<string[]>(`${environment.url}scripts/tagsList`, {});
      }

}