import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ISupplierRegion } from 'src/app/domain/decorators/ISupplierRegion';
import { IListEnvelope } from 'src/app/domain/list.interface';
import { ISupplierPrice } from 'src/app/pages/settings/supplier/supplier-management/supplier-price/interfaces/ISupplierPrice';
import { environment } from 'src/environments/environment';
import { IService } from '../../domain/service';
import { ISupplier } from '../../domain/supplier';
import { WorkingTypeData } from '../../pages/settings/working-hours/utils/working-hours.interface';
import { AppCacheService } from './app-cache.service';
import { GoogleLogParameters } from '../../components/address/intefaces/address-autocomplete.interface';

@Injectable({
    providedIn: 'root'
})
export class SupplierService {
    constructor(private http: HttpClient,
                private cache: AppCacheService) {}

    getAllSuppliers(): Observable<IListEnvelope<ISupplier>> {

        const cachedItem = this.cache.get('SUPPLIERS_ALL');
        if (cachedItem) {
            return of(cachedItem);
        }

        return this.http.get<IListEnvelope<ISupplier>>(`${environment.url}suppliers?all=true`)
                            .pipe(
                                tap(t => this.cache.set('SUPPLIERS_ALL', t, 1000 * 60 * 10))
                            );
    }

    getAllSuppliersWithDeactive(filters: {isActive?: boolean, cities?: string[], towns?: string[], services?: string[]}): Observable<IListEnvelope<ISupplier>> {
        return this.http.post<IListEnvelope<ISupplier>>(`${environment.url}suppliers/getAllWithDeactive?all=true`, filters);
    }

    getSuppliers(page?: number, limit?: number) {
        return this.http.get<IListEnvelope<ISupplier>>(`${environment.url}suppliers?limit=${limit ?? ''}&page=${page ?? ''}`);
    }

    async getSupplierItems(): Promise<SelectItem[]> {
        return new Promise((resolve, reject) => {
            this.getAllSuppliers()
                    .subscribe((response) => {
                        const suppliers = response.rows.map<SelectItem>(m => ({ value: m._id, label: m.name }));

                        resolve(suppliers);
                    },
                    (error) => reject(error)
                    );
        });
    }

    getSuppliersStaff(supplierId: string) {
        return this.http.get(`${environment.url}supplierStaffs/bySupplierId/${supplierId}`);
    }

    getSuppliersByServiceId(serviceId: string) {
        return this.http.get<ISupplier[]>(`${environment.url}suppliers/byServiceId/${serviceId}`);
    }

    getServicesById(supplierId: string) {
        return this.http.get<IService[]>(`${environment.url}suppliers/getServices/${supplierId}`);
    }

    addSupplier(supplier: ISupplier) {
        return this.http.post<ISupplier>(`${environment.url}suppliers/create`, supplier);
    }

    editSupplier(id: string, supplier: ISupplier, progressType: string) {
        const data = {supplier: supplier, progressType: progressType}
        return this.http.put<ISupplier>(`${environment.url}suppliers/${id}`, data);
    }

    detailSupplier(id: string) {
        return this.http.get<ISupplier>(`${environment.url}suppliers/${id}`);
    }

    updatePoCounters(id: string, poDate: string) {
        return this.http.post(`${environment.url}suppliers/updatePoCounters`, {id: id, poCode: poDate});
    }

    deleteSupplier(id: string): Observable<any> {
        return this.http.delete(`${environment.url}suppliers/${id}`);
    }

    getSupplierRegions(supplierId: string){
        return this.http.get<ISupplier[]>(`${environment.url}suppliers/supplierRegions/${supplierId}`);
    }

    addRegion(region: ISupplierRegion[], supplierId: string){
        return this.http.post<ISupplierRegion>(`${environment.url}suppliers/supplierRegions/${supplierId}/create`, region);
    }

    deleteSupplierRegion(region: ISupplierRegion, supplierId: string){
        return this.http.post(`${environment.url}suppliers/supllierRegions/${supplierId}/delete`, region);
    }

    getSupplierPriceInfo(supplierId: string) {
        return this.http.get<ISupplierPrice[]>(`${environment.url}price/bySupplierId/${supplierId}`);
        // Aşağıda eski fiyat sistemi için kullanılan api yer almakta.
        //return this.http.get<ISupplierPrice[]>(`${environment.url}pricings/bySupplierId/${supplierId}`);
    }

    addSupplierPriceInfo(newPrices: ISupplierPrice, oldPrices: ISupplierPrice) {
        return this.http.post(`${environment.url}pricings/create`, {new: newPrices, old: oldPrices});
    }

    /**
     * hizmet ve bölgeye göre tedarikçi listesini getirir
     *
     * @param service hizmet id
     * @param city city id
     * @param town town id
     * @returns
     */
    getSuppliersByCityAndTown(service: string, city: string, town: string){
        return this.http.get<ISupplier[]>(`${environment.url}suppliers/for-service/${service}/${city}/${town}`);
    }

    getSupplierReport(page: number, limit: number, filters: {towns?: string[], services?: string[], adressTowns?: string[], status?: string}): Observable<IListEnvelope<ISupplier>> {
        return this.http.post<IListEnvelope<ISupplier>>(`${environment.url}suppliers/getSupplierReport?limit=${limit}&page=${page}`, filters);
    }

    addWorkHours(workHours:WorkingTypeData,supplierId:string){
        return this.http.post<ISupplier>(`${environment.url}suppliers/addWorkHours`,{workHours,supplierId});
    }

    updateWorkHours(workHours:WorkingTypeData,supplierId:string){
        return this.http.post<ISupplier>(`${environment.url}suppliers/updateWorkHours`,{workHours,supplierId});
    }

    getCitiesAndServices(supplierId:string){
        return this.http.get<any>(`${environment.url}suppliers/getCitiesAndServices/${supplierId}`);
    }


    getByIdForRegionFilter(supplierId:string){
        return this.http.get<any>(`${environment.url}suppliers/getByIdForRegionFilter/${supplierId}`);
    }

    getCustomizedVehicle(supplierId:string,){
        return this.http.get<any>(`${environment.url}suppliers/getCustomizedSupplierVehicle/${supplierId}`);

    }


    addCustomizeVehicle(supplierId:string,data:any){
        return this.http.post<any>(`${environment.url}suppliers/customizeSupplierVehicle/${supplierId}`,data);

    }

    updateCustomizedVehicle(supplierId:string,data:any){
        return this.http.put<any>(`${environment.url}suppliers/updateCustomizedSupplierVehicle/${supplierId}`,data);

    }

    removeCustomizedVehicle(supplierId:string,elementId:string){
        return this.http.delete<any>(`${environment.url}suppliers/removeCustomizedSupplierVehicle/${supplierId}/${elementId}`);

    }

    getSupplierBranches(supplierId:string,filter?:{cityId:string,townId:string,isActive?:boolean}){
        return this.http.post<any>(`${environment.url}suppliers/getSupplierBranches/${supplierId}`,filter);

    }

    addSupplierBranch(supplierId:string,data:any){
        return this.http.post<any>(`${environment.url}suppliers/addSupplierBranch/${supplierId}`,data);

    }

    updateSupplierBranch(supplierId:string, data:any){
        return this.http.put<any>(`${environment.url}suppliers/updateSupplierBranch/${supplierId}`,data);
    }

    calculateBranchKM(supplierIds:string[],serviceLatLng:{latitude:number,longitude:number},googleLogParameters:GoogleLogParameters){
        return this.http.post<any>(`${environment.url}suppliers/calculateBranchKM`,{supplierIds,serviceLatLng,googleLogParameters});
    }

    getSuppliersPriceSerialCodes(serialCode: number) {
        return this.http.post<any>(`${environment.url}suppliers/suppliersPriceSerialCodes`, { serialCode });
    }

    getSupplierRegionAndBranches(filter:{cityId?:string}){
        return this.http.post<any>(`${environment.url}suppliers/getSupplierRegionAndBranches`,{filter});

    }
}
