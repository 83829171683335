export class EnumMappper<T extends { [name: string]: any }> {

    constructor(public enumObject: T) { }

    getEnumAsString(key: string | number): string {
        if (!key) {
            return undefined;
        }

        if (typeof key === 'string') {
            return this.enumObject[key];
        } else if (typeof key === 'number') {
            return this.enumObject[key];
        } else {
            throw new Error(`Unable to parse enum from ${typeof (key)}`);
        }
    }

    getEnumKeyValue(value: string | number): string {
        const retVal = Object.keys(this.enumObject).find((item) => this.enumObject[item] === value);
        return retVal ?? value.toString();
    }
}

export function calculatePagination(limit?: number, page?: number, all?: boolean): [number, number, number] {
    if (all) {
        return [Number.MAX_SAFE_INTEGER, 1, 0];
    }
    limit = Math.max(10, limit ?? 10);
    page = Math.max(1, page ?? 0);
    const offset = limit * (page - 1);
    return [limit, page, offset];
}



export function textColorPicker(backgroundColor: string){
    if(!backgroundColor) {
        return '#fff';
      }
      var result = parseInt(backgroundColor.replace('#',''), 16);
      const color:{R: number, G: number, B: number} = result ? {
      R: result ? (result >> 16) & 255 : 1,
      G: result ? (result >> 8) & 255 : 1,
      B: result ? result & 255 : 1
    } : null;

      let d = '';
      const luminance = (0.299 * color.R + 0.587 * color.G + 0.114 * color.B)/255;
      if (luminance > 0.5) { d = '#000000'; }
      else { d = '#ffffff'; }

      return d;

}

export function getBase64(file) {
    return new Promise((resolve, reject) => {
        var reader = new FileReader();
        let base64String = "";
        reader.readAsDataURL(file);
        reader.onload = function () {
            base64String = reader.result
                .toString()
                .replace("data:", "")
                .replace(/^.+,/, "");
            resolve(base64String);
        };
        reader.onerror = function (error) {
            reject;
        };
    });
}
