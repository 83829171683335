import { SelectItem } from "primeng/api";

enum ServiceTypeEnum {
    none = '',
    technical = 'technical',
    medical = 'medical',
    housing = 'housing',
}

const getServiceTypeText = (value: ServiceTypeEnum) => {
  switch (value) {
    case ServiceTypeEnum.housing: return 'Konut';
    case ServiceTypeEnum.medical: return 'Medikal';
    case ServiceTypeEnum.technical: return 'Teknik';
  }

  return '';
}

const getServiceTypeItem = () => {
  return [
            { label: 'Teknik', value:'technical' },
            { label: 'Medikal', value:'medical' },
            { label: 'Konut', value:'housing' }
  
          ] as SelectItem[];
}

export { ServiceTypeEnum, getServiceTypeText, getServiceTypeItem }