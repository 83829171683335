import { environment } from './../../../environments/environment.prod';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
  })
  export class AuthPermissionService {
  
    constructor(private http: HttpClient) {}
  
    setAuthPermissions(permissions: any[]) {
        localStorage.setItem('user_roles', JSON.stringify(permissions));
    }

    checkCurrentPermission(userId: string){
        const permissions = localStorage.getItem('user_roles');
        this.http.post<any[]>(`${environment.url}/auth/checkCurrentPermissions`, {permissions: permissions, userId: userId});
    }

    permissionCheck(name: string, key: string = 'isList') {
        const permissions = localStorage.getItem('user_roles');
        const item = JSON.parse(permissions);
        let result: boolean = false;
        
        item.forEach(i => {
          if(i.permissionName === name && i[key]) {
            result = true;
          }
        });
        return result;
      }
  }