<p-card header="Log Listesi">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col">
            <label for="customerId">Kayıt Türü</label>
            <p-dropdown id="customerId" [options]="logTypes" placeholder="Seçiniz"
                [(ngModel)]="selectedTable"></p-dropdown>
        </div>
        <p-divider layout="vertical"></p-divider>
        <div class="p-field p-col" *ngIf="selectedTable === 'SMS'">
            <label for="startDate">Başlangıç Tarihi</label>
            <p-calendar placeholder="Başlangıç Tarihi" [maxDate]="endDate" showButtonBar="true" firstDayOfWeek="1"
                dateFormat="dd.mm.yy" [(ngModel)]="startDate" [showIcon]="true" appendTo="body">
            </p-calendar>
        </div>
        <div class="p-field p-col" *ngIf="selectedTable === 'SMS'">
            <label for="endDate">Bitiş Tarihi</label>
            <p-calendar placeholder="Bitiş Tarihi" showButtonBar="true" firstDayOfWeek="1" dateFormat="dd.mm.yy"
                [minDate]="startDate" [(ngModel)]="endDate" [showIcon]="true" appendTo="body">
            </p-calendar>
        </div>
        <div class="p-field p-col" *ngIf="selectedTable === 'SMS' || selectedTable === 'E-Mail'">
            <label>{{selectedTable === 'SMS' ? 'Telefon Numarası': (selectedTable === 'E-Mail' ? 'E-posta' : ' '
                )}}</label>
            <span class="p-input-icon-left">
                <i class="pi pi-search"></i>
                <input pInputText [(ngModel)]="inputText" type="text" placeholder="Ara" />
            </span>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <div class="p-grid" style="justify-content: space-between;">
            <div *ngIf="selectedTable === 'SMS'" class="p-col">
                <p-button label="Sonuçları Göster" (click)="getAllLogListService()"></p-button>
            </div>
            <div *ngIf="selectedTable ==='E-Mail'" class="p-col">
                <p-button label="Sonuçları Göster" (click)="getEmailLogListService()"></p-button>
            </div>
        </div>
    </ng-template>
</p-card>
<div class="p-grid">
    <div class="p-col-12"></div>
</div>
<app-sms-log [smsLogList]='smsLogList'></app-sms-log>
<app-email-log [eMaillogList]='eMaillogList'></app-email-log>