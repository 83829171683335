import { Component, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { SupplierCarDialogComponent } from './supplier-car-dialog/supplier-car-dialog.component';
import { ISupplierCar, SupplierCarDto } from '../../domain/supplier-car';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../services/auth/auth.service';
import { SupplierService } from '../../services/settings/supplier.service';
import { SelectItem } from 'primeng/api';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-supplier-car-list',
  templateUrl: './supplier-car-list.component.html',
  styleUrls: ['./supplier-car-list.component.scss']
})
export class SupplierCarListComponent implements OnInit, OnDestroy {
  @ViewChild('carDialog') carDialog: SupplierCarDialogComponent;
  @Input() matchedModel: any;
  supplierCarList: SupplierCarDto[] = [];
  carType: any = null;
  inputText: string = '';
  suppliersLists: SelectItem[];
  private searchSub$ = new Subject<string>();
  searchElSub$: Subscription;
  isSupplier: string;
  @Input() selectedSupplier: any;
  @Input() special: boolean = false;
  selectedBranch: any;
  branches: any[] = [];
  filteredData: SupplierCarDto[] = [];

  constructor(
    private auth: AuthService,
    private http: HttpClient,
    private supplierService: SupplierService
  ) {
    this.isSupplier = this.auth.getUserSupplierId();
  }

  async ngOnInit() {
    this.filteredData = this.supplierCarList;

    this.suppliersLists = [{ value: '', label: 'HEPSİ' }, ...(await this.supplierService.getSupplierItems())];

    if (this.selectedSupplier) {
      this.supplierService.getSupplierBranches(this.selectedSupplier).subscribe((branches) => {
        this.branches = branches.map(branch => ({ label: branch.branchName, value: branch._id }));
      });
    }

    this.getSupplierCarList();

    this.searchElSub$ = this.searchSub$.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe((value) => {
      this.inputText = value;
      this.filterData();
    });
  }

  ngOnDestroy(): void {
    if (this.searchElSub$) {
      this.searchElSub$.unsubscribe();
    }
  }

  searchChange(event: any) {
    const searchTerm = event.target.value.toLowerCase();
    this.searchSub$.next(searchTerm);
  }

  openCarDialog(car?: SupplierCarDto) {
    this.carDialog.openCarDialog(car, this.selectedSupplier);
  }

  onSupplierChange(input: any) {
    const selectedSupplier = input.value ? input.value : input;
    this.selectedSupplier = selectedSupplier;

    if (selectedSupplier) {
      this.supplierService.getSupplierBranches(selectedSupplier).subscribe((branches) => {
        this.branches = branches.map(branch => ({ label: branch.branchName, value: branch._id }));
      });
    }
  }

  getSupplierCarList() {
    const param = { 'text': this.inputText, 'supplier': this.selectedSupplier, 'supplierBranch': this.selectedBranch };
    this.http.post<SupplierCarDto[]>(`${environment.url}supplier-cars/getAll`, param)
      .subscribe(response => {
        this.supplierCarList = response.map(car => {
          const vehicleModelId = car?.vehicleModel;
          const matchedModel = car?.brand?.autoModels?.find(model => model._id === vehicleModelId);
          if (matchedModel) {
            car.matchedModel = matchedModel;
          } else {
            car.matchedModel = null;
          }
          return car;
        });
        this.filteredData = [...this.supplierCarList];
        this.filterData();
      });
  }

  getBranchName(car: any) {
    // console.log(car)
    if(car && car.supplier && car?.supplier?.supplierBranches){
      const branch = (car?.supplier?.supplierBranches as any[]).find(br => br._id == car?.supplierBranch);
      return branch ? branch.branchName : '';
    }
    
  }

  onNewCarAdded(supplierCar: ISupplierCar) {
    this.supplierCarList.push(supplierCar);
    const index = this.supplierCarList.length - 1;
    const vehicleModelId = supplierCar?.vehicleModel;
    const matchedModel = (supplierCar?.brand as any)?.autoModels?.find(model => model._id === vehicleModelId);
    if (matchedModel) {
      this.supplierCarList[index].matchedModel = matchedModel;
    } else {
      this.supplierCarList[index].matchedModel = null;
    }
    this.filteredData = [...this.supplierCarList]; 
    this.filterData(); 
  }

  onNewCarEdit(supplierCar: ISupplierCar) {
    const index = this.supplierCarList.findIndex(s => s._id === supplierCar._id);
    if (index !== -1) {
      this.supplierCarList[index] = supplierCar;
      const vehicleModelId = supplierCar?.vehicleModel;
      const matchedModel = (supplierCar?.brand as any)?.autoModels?.find(model => model._id === vehicleModelId);
      if (matchedModel) {
        this.supplierCarList[index].matchedModel = matchedModel;
      } else {
        this.supplierCarList[index].matchedModel = null;
      }
      this.filteredData = [...this.supplierCarList]; 
      this.filterData();
    }
  }

  filterData() {
    const searchTerm = this.inputText.toLowerCase();
    if (!searchTerm) {
      this.filteredData = [...this.supplierCarList];
      return;
    }
    this.filteredData = this.supplierCarList.filter(item => {
      return this.checkObjectForSearchTerm(item, searchTerm);
    });
  }

  checkObjectForSearchTerm(obj: any, searchTerm: string): boolean {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        if (typeof value === 'object' && value !== null) {
          if (this.checkObjectForSearchTerm(value, searchTerm)) {
            return true;
          }
        } else if (value !== null && value.toString().toLowerCase().includes(searchTerm)) {
          return true;
        }
      }
    }
    return false;
  }

}
