import {
    Component,
    OnInit,
    ViewChild,
} from "@angular/core";
import { IScriptFilter } from "../../models/script-filter.model";
import { ScriptService } from "../../services/script.service";
import { AppMessageService } from "src/app/services/common/app-message.service";
import {
    catchError,
    finalize,
} from "rxjs/operators";
import { throwError } from "rxjs";
import { IScript } from "../../models/script.interface";
import { CreateScriptDto } from "../../dtos/create-script.dto";
import { DialogService } from "primeng/dynamicdialog";
import { ScriptShowComponent } from "../script-show/script-show.component";
import { Table } from "primeng/table";


@Component({
    selector: "app-script-search",
    templateUrl: "./script-search.component.html",
    styleUrls: ["./script-search.component.scss"],
})
export class ScriptSearchComponent implements OnInit {
    @ViewChild("scriptShow") scriptShowComponent: ScriptShowComponent;
    @ViewChild("dt2") table: Table;
    @ViewChild("tagButton") tagButton: any;

    scriptSearchDialog = false;
    scripts: IScript[];
    loading: boolean = true;
    totalRecords: number;
    searchText = "";
    filter: IScriptFilter = {
        searchText: "",
        isActive: [true],
        limit: null,
        page: 0,
        tags: null,
    };

    listMode: boolean = false;

    scriptId: string;
    tags: string[];
    activeTag: string | null = null;

    selectedTags: string[] = [];
    unselectedTags: string[] = [];

    constructor(
        private scriptService: ScriptService,
        private appMessageService: AppMessageService,
        private dialogService: DialogService
    ) {
        this.loadLocalSettings();
    }

    ngOnInit() {
        this.getAllActiveTags();
        this.scriptService.updatedScriptList$.subscribe((event) => {
            this.loadScripts(event);
            this.getAllActiveTags();
      });
    }

    loadScripts(event) {
        this.filter.page = event.first;
        this.filter.limit = event.rows;
        this.getList();
    }

    async getList() {
        this.loading = true;
        this.scriptService
            .getListWithoutContent(this.filter)
            .pipe(
                catchError((err) => {
                    this.appMessageService.reportError("Bilinmeyen hata!");
                    return throwError(err);
                }),
                finalize(() => {
                    this.loading = false;
                })
            )
            .subscribe((res: any) => {
                this.scripts = res.rows ? res.rows : [];
                this.totalRecords = res.totalRecords;
            });
    }

    getAllActiveTags(): void {
        this.scriptService.getAllActiveTags().subscribe(
            (tags: string[]) => {
                this.tags = tags.sort((a, b) => a.localeCompare(b));
                this.unselectedTags =[ ...this.tags];
            },
            (error) => {
                console.error("Error fetching active tags:", error);
            }
        );
    }

    toggleTag(tag: string): void {
        const index = this.selectedTags.indexOf(tag);
        if (index !== -1) {
            this.selectedTags.splice(index, 1);
            this.unselectedTags.push(tag);
            this.unselectedTags.sort((a, b) => a.localeCompare(b));
        } else {
            this.selectedTags.push(tag);
            const unselectedIndex = this.unselectedTags.indexOf(tag);
            if (unselectedIndex !== -1) {
                this.unselectedTags.splice(unselectedIndex, 1);
            }
        }

        this.filter.tags = this.selectedTags;

        if (this.selectedTags.length === 0) {
            this.filter.tags = null;
        }
        this.getList();
    }

    isSelected(tag: string) {
        return this.selectedTags.includes(tag);
    }

    showScript(scriptData: CreateScriptDto) {
        this.scriptId = scriptData._id;
        this.scriptService.getScript(this.scriptId).subscribe((res) => {
            this.dialogService.open(ScriptShowComponent, {
                width: "35vw",
                style: { 'min-width': '25vw' },
                data: { scriptData: res },
                modal: false,
                maximizable: true,
                dismissableMask: false,
                resizable: true,
                draggable: true,
            });
        });
    }

    changeListMode() {
        this.listMode = !this.listMode;
        localStorage.setItem(
            "scripts-list-mode",
            this.listMode ? "true" : "false"
        );
    }

    private loadLocalSettings() {
        const storedListMode = localStorage.getItem("scripts-list-mode");
        if (storedListMode) {
            this.listMode = storedListMode === "true" ? true : false;
        } else {
            localStorage.setItem(
                "scripts-list-mode",
                this.listMode ? "true" : "false"
            );
        }
    }

    searchTag(tag: string) {
 
        this.filter.searchText = tag;

        this.table.filterGlobal(this.searchText, "contains");
        return;
    }

    isFilterSelected(): boolean {
        return this.filter.searchText !== '' || this.selectedTags.length > 0;
     
      }

    clearFilters(): void {
        this.filter.searchText = "";
        this.selectedTags = [];
        this.filter.tags = null;
        this.unselectedTags =[ ...this.tags];
        this.table.clear();    
      }

    closeSearchDialog() {
        this.scriptSearchDialog = false;
        this.clearFilters()
    }
}
