import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'MoneyPipe'
})
export class MoneyFormatPipe implements PipeTransform {
    transform(value: number): string {

        return new Intl.NumberFormat('tr-TR', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        }).format(value);

    }
}
