import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { environment } from '../../environments/environment';
import { AuthService } from '../services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class OctoNotificationsService extends Socket {
  constructor() {
    super({ url: `${environment.socketUrl}`, options: { autoConnect: true, transports: ['websocket'] }});
    this.connect();
    this.emit('subscribe-notifications');
  }

  getNotifications() {
     return this.fromEvent<any>('notification');
  }

  sendNotificationsToEveryone(message: string) {
    this.emit('notify-everyone', {message});
  }
}
