import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit } from "@angular/core";

@Directive({
    selector: "[appCountdown]",
})
export class CountdownDirective implements OnInit, OnDestroy {
    countdownValue = 0;
    @Input() get appCountdown() {
        return this.countdownValue;
    }

    set appCountdown(value: number) {
        this.countdownValue = value;

        this.start();
    }

    countdownCompleted = new EventEmitter();

    interval: any;

    constructor(public el: ElementRef<any>) {}

    ngOnInit(): void {
    }

    ngOnDestroy() {
        if(this.interval) {
            clearInterval(this.interval);
        }
    }

    start() {
        if (this.countdownValue > 0) {
            this.el.nativeElement.innerText = this.countdownValue.toString();
            this.interval = setInterval(() => this.countdown(), 1000);
        } else {
            this.el.nativeElement.innerText = '';
        }
    }

    countdown() {
        if (this.countdownValue === 0) {
            clearInterval(this.interval);
            this.interval = undefined;
            return;
        }

        this.countdownValue--;
        this.el.nativeElement.innerText = this.countdownValue.toString();
    }
}
