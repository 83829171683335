<ng-template pTemplate="paginatorright"> </ng-template>
<p-card>
    <app-occurrence-parameter-add (addedParameter)="addParameter($event)" (editedParameter)="editParameter($event)"
        #parameterOperations></app-occurrence-parameter-add>
    <p-table [value]="rows" dataKey="_id" [rowHover]="true" [rows]="10" [rowsPerPageOptions]="[10, 20, 50]"
        pageLinks="7" [loading]="loading" [paginator]="true" [filterDelay]="500" [lazy]="true"
        (onLazyLoad)="loadData($event)" [totalRecords]="totalRecords" styleClass="oc-border" #dt>
        <!--  (onLazyLoad)="loadData($event)"
           [rowsPerPageOptions]="[10, 20, 50]"  -->
        <ng-template pTemplate="caption">
            <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
                <span style="display: hidden;"> </span>
                <div>
                    <label>Parametre Grubu&nbsp;</label>
                    <p-dropdown [options]="dropdownOptions" [filter]="true" [(ngModel)]="selectedGroup" optionLabel="label" optionValue="value"
                    (onChange)="resetFilter()" [resetFilterOnHide]="true" placeholder="Bir grup seçin" emptyFilterMessage="Sonuç bulunamadı.">
                    </p-dropdown>
                    &nbsp;&nbsp;&nbsp;
                    <label for="typeFilter">Parametre Tipleri&nbsp;</label>
                    <p-dropdown [options]="typeFilter" [filterValue]="okey" [(ngModel)]="selectedType" appFilterDropdown
                        (onChange)="resetFilter()" [resetFilterOnHide]="true" id="typeFilter"
                        emptyFilterMessage="sonuç bulunamadı."></p-dropdown>
                    &nbsp;&nbsp;&nbsp;
                    <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="searchChange($event)" placeholder="Ara" />
                    </span>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th>Referans Kodu</th>
                <th>Parametre Adı</th>
                <th>Parametre Grubu</th>
                <th>Tipi</th>
                <th>Değer</th>
                <th>Açıklama</th>
                <th>Oluşturan</th>
                <th>Oluşturma Tarihi</th>
                <th></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-data>
            <tr>
                <td>
                    {{ data?.exCode  }}
                </td>
                <td>{{ data?.name }}</td>
                <td>{{ data?.group }}</td>
                <td>{{ parameterTypeName(data.type) }}</td>
                <td *ngIf="data.type !== 'date'">{{data.value}}</td>
                <td *ngIf="data.type === 'date'">{{data.value | date:'dd.MM.yyyy'}}</td>
                <td>{{ data.description }}</td>
                <td>{{ data?.createdBy?.userName ?? 'Kullanıcı Kayıtlı Değil' }}</td>
                <td>{{ data?.creationDate | date: "dd.MM.yyyy HH:mm" }}</td>
                <td>
                    <p-button label="Güncelle" icon="pi pi-pencil" (click)="editParameterForm(data)"></p-button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</p-card>