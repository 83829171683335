import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.css']
})
export class AccessDeniedComponent implements OnInit, OnDestroy {

  constructor(public router: Router, private renderer: Renderer2) {
    this.renderer.addClass(document.body, 'access-body');
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'access-body');
  }

  gotoDashBoard() {
    this.router.navigate(['']);
  }
}
