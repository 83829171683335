import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CreateAnnouncementDto } from "../dtos/create-announcement.dto";
import { IAnnouncement } from "../models/announcement.interface";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "../../../../../environments/environment";
import { IAnnouncementFilter } from "../models/announcement-filter.model";
// import { IAssignedAnnouncementFilter } from "../models/assigned-announcements-filter.model";

@Injectable({ providedIn: "root" })
export class AnnouncementService {
    constructor(private http: HttpClient) {}

    private _announcementPage = new BehaviorSubject<string>("home");
    readonly announcementPage$ = this._announcementPage.asObservable();

    changePage(currentPage: string) {
        this._announcementPage.next(currentPage);
    }

    create(announcement: CreateAnnouncementDto): Observable<IAnnouncement> {
        return this.http.post<IAnnouncement>(
            `${environment.url}announcements/create`,
            announcement
        );
    }

    edit(
        announcementId: string,
        announcement: CreateAnnouncementDto
    ): Observable<IAnnouncement> {
        return this.http.patch<IAnnouncement>(
            `${environment.url}announcements/update/${announcementId}`,
            announcement
        );
    }

    getList(filters: IAnnouncementFilter) {
        return this.http.post<IAnnouncementFilter[]>(
            `${environment.url}announcements/list`,
            filters
        );
    }

    getUsersAnnouncementsList(announcementId: string) {
        return this.http.get<any[]>(
            `${environment.url}announcements/posting-users/${announcementId}`
        );
    }

    getAssignedMeList(filters: IAnnouncementFilter) {
        return this.http.post<IAnnouncementFilter[]>(
            `${environment.url}announcements/assigned-me-list`,
            filters
        );
    }

    updateReadReceipt(announcementId: string) {
        return this.http.post<any>(
            `${environment.url}announcements/update-read-receipt/${announcementId}`, {}
        )
    }

    
    getAnnouncementsAssignedMe() {
        return this.http.get<any[]>(
            `${environment.url}announcements/assigned-me`
        );
    }

    getUnreadReceiptCount() {
        return this.http.get<any>(
            `${environment.url}announcements/unread-receipt-count`
        );
    }

}
