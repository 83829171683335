import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { BlockUI } from 'primeng/blockui';
import { ScrollPanel } from 'primeng/scrollpanel';
import { AppMessageType } from 'src/app/services/common/app-message';
import { AppMessageService } from 'src/app/services/common/app-message.service';

@Component({
  selector: 'app-default',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.css']
})
export class DefaultLayoutComponent implements OnInit, AfterViewInit {
  @ViewChild('blockUI') blockUI: BlockUI;
  darkTheme = false;

  menuMode = 'horizontal';

  topbarMenuActive: boolean;

  topbarOnaylarimMenuActive: boolean;

  overlayMenuActive: boolean;

  staticMenuDesktopInactive: boolean;

  staticMenuMobileActive: boolean;

  layoutMenuScroller: HTMLDivElement;

  menuClick: boolean;

  topbarItemClick: boolean;

  activeTopbarItem: any;

  resetMenu: boolean;

  menuHoverActive: boolean;

  showErrorDialog = false;
  errorText = '';
  showWorkingPanel = false;
  workingMsg = '';

  constructor(private appMessageService: AppMessageService,
              private confirmationService: ConfirmationService,
              private messageService: MessageService,
              private changeDetectorRef: ChangeDetectorRef) {
  }

  @ViewChild('layoutMenuScroller') layoutMenuScrollerViewChild: ScrollPanel;

  ngOnInit() {

    this.appMessageService.eventSource.subscribe(event => {
        if (event.messageType === AppMessageType.empty) {
            return;
        }

        if (this.showWorkingPanel &&
            event.messageType !== AppMessageType.workingPanel) {
            this.blockUI.unblock();
            this.blockUI.destroyModal();
        }

        if (event.messageType === AppMessageType.workingPanel) {
            this.workingMsg = event.message;
            this.showWorkingPanel = true;
            this.changeDetectorRef.detectChanges();
        } else if (event.messageType === AppMessageType.hideWorkingPanel) {
            this.showWorkingPanel = false;
            this.changeDetectorRef.detectChanges();
        } else if (event.messageType === AppMessageType.success) {
            this.confirmationService.confirm({
                key: 'success',
                message: event.message
              });
        } else if (event.messageType === AppMessageType.warning) {
            this.confirmationService.confirm({
                key: 'warning',
                message: event.message
              });
        } else if (event.messageType === AppMessageType.confirm) {
            this.confirmationService.confirm({
                header: event.header,
                key: 'confirm',
                message: event.message,
                accept: event.accept,
                reject: event.reject
              });
        } else if (event.messageType === AppMessageType.error) {
            setTimeout(() => {
                this.errorText = event.message;
                this.showErrorDialog = true;
            }, 60);
        } else if (event.messageType === AppMessageType.successToast) {
            this.showWorkingPanel = false;
            this.messageService.clear('systemToast');
            this.messageService.add({key: 'systemToast',
                                     severity: 'success', summary: event.header,
                                     detail: event.message });

        }
        else if (event.messageType === AppMessageType.infoToast) {
            this.showWorkingPanel = false;
            this.messageService.clear('systemToast');
            this.messageService.add({key: 'systemToast', sticky: event?.sticky, icon: event?.icon,
                                     severity: 'info', summary: event.header,
                                     detail: event.message });

        } else if (event.messageType === AppMessageType.errorToast) {
            this.showWorkingPanel = false;
            this.messageService.clear('systemToast');
            this.messageService.add({key: 'systemToast',
                                     severity: 'error', summary: event.header,
                                     detail: event.message });
        } else if (event.messageType === AppMessageType.noteToast) {
            this.showWorkingPanel = false;
            this.messageService.clear('stickyNote');
            this.messageService.add({key: 'stickyNote', sticky: event?.sticky
                , summary: event.header, detail: event.message, severity: 'info' });
        }
    });
  }

  closeErrorDialog() {
    this.showErrorDialog = false;
    this.changeDetectorRef.detectChanges();
  }

  ngAfterViewInit() {
  }

  onLayoutClick() {
    if (!this.topbarItemClick) {
        this.activeTopbarItem = null;
        this.topbarMenuActive = false;
        this.topbarOnaylarimMenuActive = false;
    }

    if (!this.menuClick) {
        if (this.isHorizontal() || this.isSlim()) {
            this.resetMenu = true;
        }

        if (this.overlayMenuActive || this.staticMenuMobileActive) {
            this.hideOverlayMenu();
        }

        this.menuHoverActive = false;
    }

    this.topbarItemClick = false;
    this.menuClick = false;
}

onMenuButtonClick(event) {
    this.menuClick = true;
    this.topbarMenuActive = false;
    this.topbarOnaylarimMenuActive = false;

    if (this.isOverlay()) {
        this.overlayMenuActive = !this.overlayMenuActive;
    }
    if (this.isDesktop()) {
        this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
    } else {
        this.staticMenuMobileActive = !this.staticMenuMobileActive;
    }

    event.preventDefault();
}

onMenuClick($event) {
    this.menuClick = true;
    this.resetMenu = false;

    if (!this.isHorizontal()) {
        setTimeout(() => {
            if (this.layoutMenuScrollerViewChild) {
                this.layoutMenuScrollerViewChild.moveBar();
            }
        }, 500);
    }
}

onTopbarMenuButtonClick(event) {
    this.topbarItemClick = true;
    this.topbarMenuActive = !this.topbarMenuActive;
    this.topbarOnaylarimMenuActive = false;

    this.hideOverlayMenu();

    event.preventDefault();
}

onTopbarOnaylarimMenuButtonClick(event) {
    this.topbarItemClick = true;
    this.topbarOnaylarimMenuActive = !this.topbarOnaylarimMenuActive;
    this.topbarMenuActive = false;

    this.hideOverlayMenu();

    event.preventDefault();
}

onTopbarItemClick(event, item) {
    this.topbarItemClick = true;

    if (this.activeTopbarItem === item) {
        this.activeTopbarItem = null;
    } else {
        this.activeTopbarItem = item;
    }

    event.preventDefault();
}

onTopbarSubItemClick(event) {
  event.preventDefault();
}

isHorizontal() {
    return this.menuMode === 'horizontal';
}

isSlim() {
    return this.menuMode === 'slim';
}

isOverlay() {
    return this.menuMode === 'overlay';
}

isStatic() {
    return this.menuMode === 'static';
}

isMobile() {
    return window.innerWidth < 1025;
}

isDesktop() {
    return window.innerWidth > 1024;
}

isTablet() {
  const width = window.innerWidth;
  return width <= 1024 && width > 640;
}

hideOverlayMenu() {
    this.overlayMenuActive = false;
    this.staticMenuMobileActive = false;
}

changeTheme(theme) {
    const themeLink: HTMLLinkElement = document.getElementById('theme-css') as HTMLLinkElement;
    themeLink.href = 'assets/theme/theme-' + theme + '.css';
    const layoutLink: HTMLLinkElement = document.getElementById('layout-css') as HTMLLinkElement;
    layoutLink.href = 'assets/layout/css/layout-' + theme + '.css';

    if (theme.indexOf('dark') !== -1) {
      this.darkTheme = true;
    } else {
      this.darkTheme = false;
    }
  }
}
