<div *ngIf="eMaillogList && eMaillogList.length> 0" class="card">
    <p-table [value]="eMaillogList" dataKey="_id" [rowsPerPageOptions]="[10, 25, 50]" [paginator]="true" [rows]="10" pageLinks="7"
    [filterDelay]="500"  responsiveLayout="stack"  styleClass="oc-border p-datatable-sm"
    [customSort]="true" #dt>

        <ng-template pTemplate="header">
            <tr>
                <th>Gönderen Kullanıcı</th>
                <th>E-mail</th>
                <th>Konu</th>
                <th>Template Id</th>
                <th>Parametre</th>
                <th>İstek</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
            <tr>
                <td>{{(item?.user?.name ? item?.user?.name : '')+' '+(item?.user?.surname ? item?.user?.surname:'')}}</td>
                <td>{{item?.to}}</td>
                <td>{{item?.subject}}</td>
                <td>{{item?.templateId}}</td>
                <td>
                    <p-button type="button" (click)="paramsShowDialog(item?.params)">
                        {{item?.params.length}}
                    </p-button>
                </td>
                <td>
                    <p-button type="button" (click)="showDialog(item?.request)" icon="pi pi-info-circle">
                    </p-button>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="8">Belirlenen filtrelere uygun operasyon bulunamadı...</td>
            </tr>
        </ng-template>
    </p-table>
</div>

<p-dialog header="Request" [(visible)]="display" [modal]="true" [style]="{width: '50vw'}">
    {{request}}
</p-dialog>

<p-dialog header="Parametreler" [(visible)]="paramsDialog" [modal]="true" [style]="{width: '50vw'}">
    <p-table [value]="paramsData" dataKey="_id" [customSort]="true" #dt>

    <ng-template pTemplate="header">
        <tr>
            <th>Parametre</th>
            <th>Değer</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
        <tr>
            <td>{{item?.param}}</td>
            <td>{{item?.value}}</td>


        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="8">Belirlenen uygun parametreler bulunamadı...</td>
        </tr>
    </ng-template>
</p-table>
</p-dialog>