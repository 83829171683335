import { getOccurrenceFileStatusText } from "src/app/domain/decorators/occurrence-file-status.decorator";
import { getOccurrenceSubTypeText } from "src/app/domain/decorators/occurrence-sub-type.decorator";
import { getOccurrenceTypeText } from "src/app/domain/decorators/occurrence-type.decorator";
import { getServiceTypeText } from "src/app/domain/decorators/service-type.decorator";

export function occurrenceFileStatus(value: any) {
    return getOccurrenceFileStatusText(value);
}

export function serviceType(value: any) {
    return getServiceTypeText(value);
}

export function occurrenceType(value: any) {
    return getOccurrenceTypeText(value);
}

export function occurrenceSubType(value: any) {
    return getOccurrenceSubTypeText(value);
}