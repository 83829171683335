import { Injectable, OnInit } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OctoSupplierNotificationsService extends Socket {

  constructor(supplierId?:string) {
    if(!supplierId){
        super({
            url: `${environment.socketUrl ?? '/'}`
            , options: {
                autoConnect: true,
                transports: ['websocket'],
            },

           });
        return;

    }

    super({
        url: `${environment.socketUrl ?? '/'}`
        , options: {
            autoConnect: true,
             transports: ['websocket'],
           query:{
               supplierId:supplierId
           }
        },

       });

    this.connect();

  }

  getNotifications() {
    return this.fromEvent<any>('supplier-notification');
  }

  sendNotificationsToEveryone(message: string) {
     this.emit('notify-supplier', {message});
  }
  getServiceInfo() {
    return this.fromEvent<any>('serviceInfo');
  }


  //option-exchange olayını dinleyerek gelen veriyi bir Observable üzerinden iletirken, sendOptionInfo fonksiyonu bu veriyi sunucuya gönderir
  optionInfoExchange(): Observable<any> {
    return new Observable(observer => {
      this.ioSocket.on('option-exchange', (data) => {
        observer.next(data);
      });

      return () => {
        this.ioSocket.off('option-exchange');
      };

    });
  }

  sendOptionInfo(serviceInformation: any) {
    this.emit('option-exchange', serviceInformation);
  }

  //remove-option-info olayını tetikler ve başarılı ya da hatalı yanıtları Observable ile geri döner.
  removeOptionInfo(serviceInformation: any): Observable<any> {
    // console.log("aaa")
    return new Observable(observer => {
      this.emit('remove-option-info', serviceInformation);
      this.fromEvent<any>('remove-option-info').subscribe(response => {
        observer.next(response);
      });
    });
  }
}
