import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberCommaPipe'
})
export class NumberCommaFormatPipe implements PipeTransform {
  transform(value: number): string {
    // Format the number using German number format
    return new Intl.NumberFormat('de-DE').format(value);
  }
}
