import { SelectItem } from "primeng/api";
import { OccurrenceTypeEnum } from "./occurrence-type.decorator";

enum OccurrenceSubTypeEnum {
  none = '',

  engine = 'engine',
  battery = 'battery',
  brake = 'brake',
  gear = 'gear',

  multiTyre = 'multiTyre',
  keyMissing = 'KeyMissing',
  singleAccident = 'singleAccident',
  doubleAccident = 'doubleAccident',

  keyInside = 'keyInside',
  singleTyre = 'singleTyre',

  flood = 'flood',
  storm = 'storm',
  earthquake = 'earthquake',
  terror = 'terror',

  towTruck = 'towTruck',
  roadsideAid = 'roadsideAid',
  loadAid = 'loadAid',

  outOfFuel = 'outOfFuel',
  stolenVehicle = 'stolenVehicle',
  burnedVehicle = 'burnedVehicle',

  cleanWaterInstallation = 'cleanWaterInstallation',
  dirtyWaterInstallation = 'dirtyWaterInstallation',
  heaterInstallation = 'heaterInstallation',
  sewage = 'sewage',

  glassInside = 'glassInside',
  glassOutside = 'glassOutside',
  electricity = 'electricity',

  lockedInside = 'lockedInside',
  lockedOutside = 'lockedOutside',
  homeRepair = 'homeRepair',
  otelRepair = 'otelRepair',
  ceramic = 'ceramic',

  smallRepair = 'smallRepair',
  smallWorks = 'smallWorks',
  homeMaster = 'homeMaster',
  sampoRepair = 'sampoRepair',
  miniFix = 'miniFix',
  combiFix = 'combiFix',
  airFix = 'airFix',
  heaterClean = 'heaterClean',
  rugClean = 'rugClean',
  seatClean = 'seatClean',
  health = 'health',
  travel = 'travel',

  // Evde küşük işler hizmeti alanına gelen ekler
  eki01 = 'eki01',
  eki02 = 'eki02',
  eki03 = 'eki03',
  eki04 = 'eki04',
  eki05 = 'eki05',
  eki06 = 'eki06',
  eki07 = 'eki07',
  eki08 = 'eki08',
  eki09 = 'eki09',
  eki10 = 'eki10',
  eki11 = 'eki11',
  eki12 = 'eki12',
  eki13 = 'eki13',
  eki14 = 'eki14',
  eki15 = 'eki15',
  eki16 = 'eki16',
  eki17 = 'eki17',
  eki18 = 'eki18',
  eki19 = 'eki19',

  funeral = 'funeral',

}

const getOccurrenceSubTypeText = (value: OccurrenceSubTypeEnum) => {
  switch (value) {
    case OccurrenceSubTypeEnum.towTruck: return 'Çekici';

    case OccurrenceSubTypeEnum.engine: return 'Motor';
    case OccurrenceSubTypeEnum.battery: return 'Akü-Şarj';
    case OccurrenceSubTypeEnum.brake: return 'Fren-Debriyaj';
    case OccurrenceSubTypeEnum.gear: return 'Vites';
    case OccurrenceSubTypeEnum.multiTyre: return 'Birden Çok Teker patlaması';

    case OccurrenceSubTypeEnum.keyMissing: return 'Anahtar Kaybolması';
    case OccurrenceSubTypeEnum.singleAccident: return 'Tek Taraflı Kaza';
    case OccurrenceSubTypeEnum.doubleAccident: return 'Çift Taraflı Kaza';
    case OccurrenceSubTypeEnum.keyInside: return 'Anahtarın içinde kalması';

    case OccurrenceSubTypeEnum.singleTyre: return 'Tek Lastik Patlaması';
    case OccurrenceSubTypeEnum.flood: return 'Sel-Dolu';
    case OccurrenceSubTypeEnum.storm: return 'Fırtına';
    case OccurrenceSubTypeEnum.earthquake: return 'Deprem';
    case OccurrenceSubTypeEnum.terror: return 'Terör';

    case OccurrenceSubTypeEnum.roadsideAid: return 'Yol Yardım';
    case OccurrenceSubTypeEnum.loadAid: return 'Yük Yardım';

    case OccurrenceSubTypeEnum.outOfFuel: return 'Benzin Bitmesi';
    case OccurrenceSubTypeEnum.stolenVehicle: return 'Araç Çalınması';
    case OccurrenceSubTypeEnum.burnedVehicle: return 'Araç Yanması';

    case OccurrenceSubTypeEnum.cleanWaterInstallation: return 'Temiz Su Tesisatı';
    case OccurrenceSubTypeEnum.dirtyWaterInstallation: return 'Pis Su Tesisatı';
    case OccurrenceSubTypeEnum.heaterInstallation: return 'Kalorifer Tesisatı';
    case OccurrenceSubTypeEnum.sewage: return 'Kanalizasyon';

    case OccurrenceSubTypeEnum.glassInside: return 'İç Çephe';
    case OccurrenceSubTypeEnum.glassOutside: return 'Dış Cephe';
    case OccurrenceSubTypeEnum.electricity: return 'Elektrik Tesisatı';

    case OccurrenceSubTypeEnum.lockedInside: return 'Dış Kapıda Kalma';
    case OccurrenceSubTypeEnum.lockedOutside: return 'Evde/İşyerinde Mahsur Kalma';
    case OccurrenceSubTypeEnum.homeRepair: return 'Hasar Onarım/Restorasyon';
    case OccurrenceSubTypeEnum.otelRepair: return 'Otel Hizmeti';
    case OccurrenceSubTypeEnum.ceramic: return 'Seramik Kapama';

    case OccurrenceSubTypeEnum.smallRepair: return 'Konut Mini Onarım';
    case OccurrenceSubTypeEnum.smallWorks: return 'Groupama Evde Küçük İşler';
    case OccurrenceSubTypeEnum.homeMaster: return 'Generali Evimin Ustası';
    case OccurrenceSubTypeEnum.sampoRepair: return 'Sompo Mini Onarım';
    case OccurrenceSubTypeEnum.miniFix: return 'Anadolu Minifix';
    case OccurrenceSubTypeEnum.combiFix: return 'Kombi Bakım';
    case OccurrenceSubTypeEnum.airFix: return 'Klima Bakım';
    case OccurrenceSubTypeEnum.heaterClean: return 'Petek Temizliği';
    case OccurrenceSubTypeEnum.rugClean: return 'Koltuk Yıkama';
    case OccurrenceSubTypeEnum.seatClean: return 'Halı Yıkama';
    case OccurrenceSubTypeEnum.health: return 'Sağlık';


    // Sonradan eklenen Evde Küçük İşler Alanları
    case OccurrenceSubTypeEnum.eki01: return 'Musluk, sifon, armatur, klozet değişimi';
    case OccurrenceSubTypeEnum.eki02: return 'Duvar Rutüş ve boyacılık, duvar kağıdı';
    case OccurrenceSubTypeEnum.eki03: return 'Giderlerin açılması (banyo&mutfak)';
    case OccurrenceSubTypeEnum.eki04: return 'Islak zemin derzlerin doldurulması';
    case OccurrenceSubTypeEnum.eki05: return 'Avize montaj';
    case OccurrenceSubTypeEnum.eki06: return 'Korniş takılması/sökülmesi (10 metreye kadar)';
    case OccurrenceSubTypeEnum.eki07: return 'İlaçlama (sadece banyo veya sadece mutfak)';
    case OccurrenceSubTypeEnum.eki08: return 'Elektrik tesisat ayarlamaları ve priz değişimi';
    case OccurrenceSubTypeEnum.eki09: return 'Süs aksesuar, ayna, tablo, sabunluk';
    case OccurrenceSubTypeEnum.eki10: return 'Alçıpan/kartonpiyer tamiri ve yenilenmesi';
    case OccurrenceSubTypeEnum.eki11: return 'Dolapların sabitlenmesi (1 metrekareye kadar)';
    case OccurrenceSubTypeEnum.eki12: return 'Aksesuar montaj (Şifonyer, karyola,dolap)';
    case OccurrenceSubTypeEnum.eki13: return 'Beyaz eşya montaj/demontaj';
    case OccurrenceSubTypeEnum.eki14: return 'Kapı kilit değişimi';
    case OccurrenceSubTypeEnum.eki15: return 'Elektrik tesisatının gözden geçirilmesi';
    case OccurrenceSubTypeEnum.eki16: return 'Elektrik, telefon, bilgisayar hatları';
    case OccurrenceSubTypeEnum.eki17: return 'Evye değişimi';
    case OccurrenceSubTypeEnum.eki18: return 'Kapılara/pencerelere sineklik takılması';
    case OccurrenceSubTypeEnum.eki19: return 'Duşakabin montaj';

    case OccurrenceSubTypeEnum.funeral: return 'Cenaze Nakli';

    default: return '';
  }
}

const getOccurrenceSubTypeItems = (mainType: OccurrenceTypeEnum) => {
  switch (mainType) {
    case OccurrenceTypeEnum.breakdown: return [
      { value: OccurrenceSubTypeEnum.engine, label: 'Motor' },
      { value: OccurrenceSubTypeEnum.battery, label: 'Akü-Şarj' },
      { value: OccurrenceSubTypeEnum.brake, label: 'Fren-Debriyaj' },
      { value: OccurrenceSubTypeEnum.gear, label: 'Vites' },
    ] as SelectItem[];
    case OccurrenceTypeEnum.damage: return [
      { value: OccurrenceSubTypeEnum.multiTyre, label: 'Birden Çok Lastik Patlaması' },
      { value: OccurrenceSubTypeEnum.keyMissing, label: 'Anahtar Kaybolması' },
      { value: OccurrenceSubTypeEnum.singleAccident, label: 'Tek Taraflı Kaza' },
      { value: OccurrenceSubTypeEnum.doubleAccident, label: 'Çift Taraflı Kaza' },
    ] as SelectItem[];
    case OccurrenceTypeEnum.outOfFuel: return [
      { value: OccurrenceSubTypeEnum.outOfFuel, label: 'Benzin Bitmesi' },
    ] as SelectItem[];
    case OccurrenceTypeEnum.autoLocksmith: return [
      { value: OccurrenceSubTypeEnum.keyInside, label: 'Anahtarın içinde kalması' },
    ] as SelectItem[];
    case OccurrenceTypeEnum.singleTyre: return [
      { value: OccurrenceSubTypeEnum.singleTyre, label: 'Tek Lastik Patlaması' },
    ] as SelectItem[];
    case OccurrenceTypeEnum.naturalDisaster: return [
      { value: OccurrenceSubTypeEnum.flood, label: 'Sel-Dolu' },
      { value: OccurrenceSubTypeEnum.storm, label: 'Fırtına' },
      { value: OccurrenceSubTypeEnum.earthquake, label: 'Deprem' },
      { value: OccurrenceSubTypeEnum.terror, label: 'Terör' },
    ] as SelectItem[];
    case OccurrenceTypeEnum.stolenVehicle: return [
      { value: OccurrenceSubTypeEnum.stolenVehicle, label: 'Araç Çalınması' },
    ] as SelectItem[];
    case OccurrenceTypeEnum.burnedVehicle: return [
      { value: OccurrenceSubTypeEnum.burnedVehicle, label: 'Araç Yanması' },
    ] as SelectItem[];
    case OccurrenceTypeEnum.water: return [
      { value: OccurrenceSubTypeEnum.cleanWaterInstallation, label: 'Temiz Su Tesisatı' },
      { value: OccurrenceSubTypeEnum.dirtyWaterInstallation, label: 'Pis Su Tesisatı' },
      { value: OccurrenceSubTypeEnum.heaterInstallation, label: 'Kalorifer Tesisatı' },
      { value: OccurrenceSubTypeEnum.sewage, label: 'Kanalizasyon' },
    ] as SelectItem[];
    case OccurrenceTypeEnum.glass: return [
      { value: OccurrenceSubTypeEnum.glassInside, label: 'İç Çephe' },
      { value: OccurrenceSubTypeEnum.glassOutside, label: 'Dış Cephe' },
    ] as SelectItem[];
    case OccurrenceTypeEnum.electricity: return [
      { value: OccurrenceSubTypeEnum.electricity, label: 'Elektrik Tesisatı' },
    ] as SelectItem[];
    case OccurrenceTypeEnum.locksmith: return [
      { value: OccurrenceSubTypeEnum.lockedOutside, label: 'Dış Kapıda Kalma' },
      { value: OccurrenceSubTypeEnum.lockedInside, label: 'Evde/İşyerinde Mahsur Kalma' },
    ] as SelectItem[];
    case OccurrenceTypeEnum.buildingDamage: return [
      { value: OccurrenceSubTypeEnum.homeRepair, label: 'Hasar Onarım/Restorasyon' },
      { value: OccurrenceSubTypeEnum.otelRepair, label: 'Otel Hizmeti' },
      { value: OccurrenceSubTypeEnum.ceramic, label: 'Seramik Kapama' },
    ] as SelectItem[];
    case OccurrenceTypeEnum.homeSmallServices: return [
      { value: OccurrenceSubTypeEnum.smallRepair, label: 'Konut Mini Onarım' },
      { value: OccurrenceSubTypeEnum.smallWorks, label: 'Groupama Evde Küçük İşler' },
      { value: OccurrenceSubTypeEnum.homeMaster, label: 'Generali Evimin Ustası' },
      { value: OccurrenceSubTypeEnum.sampoRepair, label: 'Sompo Mini Onarım' },
      { value: OccurrenceSubTypeEnum.miniFix, label: 'Anadolu Minifix' },
      { value: OccurrenceSubTypeEnum.eki01, label: 'Musluk, sifon, armatur, klozet değişimi' },
      { value: OccurrenceSubTypeEnum.eki02, label: 'Duvar Rutüş ve boyacılık, duvar kağıdı' },
      { value: OccurrenceSubTypeEnum.eki03, label: 'Giderlerin açılması (banyo&mutfak)' },
      { value: OccurrenceSubTypeEnum.eki04, label: 'Islak zemin derzlerin doldurulması' },
      { value: OccurrenceSubTypeEnum.eki05, label: 'Avize montaj' },
      { value: OccurrenceSubTypeEnum.eki06, label: 'Korniş takılması/sökülmesi (10 metreye kadar)' },
      { value: OccurrenceSubTypeEnum.eki07, label: 'İlaçlama (sadece banyo veya sadece mutfak)' },
      { value: OccurrenceSubTypeEnum.eki08, label: 'Elektrik tesisat ayarlamaları ve priz değişimi' },
      { value: OccurrenceSubTypeEnum.eki09, label: 'Süs aksesuar, ayna, tablo, sabunluk' },
      { value: OccurrenceSubTypeEnum.eki10, label: 'Alçıpan/kartonpiyer tamiri ve yenilenmesi' },
      { value: OccurrenceSubTypeEnum.eki11, label: 'Dolapların sabitlenmesi (1 metrekareye kadar)' },
      { value: OccurrenceSubTypeEnum.eki12, label: 'Aksesuar montaj (Şifonyer, karyola,dolap)' },
      { value: OccurrenceSubTypeEnum.eki13, label: 'Beyaz eşya montaj/demontaj' },
      { value: OccurrenceSubTypeEnum.eki14, label: 'Kapı kilit değişimi' },
      { value: OccurrenceSubTypeEnum.eki15, label: 'Elektrik tesisatının gözden geçirilmesi' },
      { value: OccurrenceSubTypeEnum.eki16, label: 'Elektrik, telefon, bilgisayar hatları' },
      { value: OccurrenceSubTypeEnum.eki17, label: 'Evye değişimi' },
      { value: OccurrenceSubTypeEnum.eki18, label: 'Kapılara/pencerelere sineklik takılması' },
      { value: OccurrenceSubTypeEnum.eki19, label: 'Duşakabin montaj' },
    ] as SelectItem[];
    case OccurrenceTypeEnum.airCombi: return [
      { value: OccurrenceSubTypeEnum.combiFix, label: 'Kombi Bakım' },
      { value: OccurrenceSubTypeEnum.airFix, label: 'Klima Bakım' },
    ] as SelectItem[];
    case OccurrenceTypeEnum.heaterCleaning: return [
      { value: OccurrenceSubTypeEnum.heaterClean, label: 'Petek Temizliği' },
    ] as SelectItem[];
    case OccurrenceTypeEnum.cleaning: return [
      { value: OccurrenceSubTypeEnum.seatClean, label: 'Koltuk Yıkama' },
      { value: OccurrenceSubTypeEnum.rugClean, label: 'Halı Yıkama' },
    ] as SelectItem[];
    case OccurrenceTypeEnum.health: return [
      { value: OccurrenceTypeEnum.health, label: 'Sağlık' }
    ] as SelectItem[];
    case OccurrenceTypeEnum.travel: return [
      { value: OccurrenceTypeEnum.travel, label: 'Seyahat' }
    ] as SelectItem[];
    case OccurrenceTypeEnum.funeral: return [
      { value: OccurrenceTypeEnum.funeral, label: 'Cenaze Nakli' }
    ] as SelectItem[];
    case OccurrenceTypeEnum.otoglass: return [
      { value: OccurrenceTypeEnum.otoglass, label: 'Oto Cam' }
    ] as SelectItem[];
    case OccurrenceTypeEnum.otomultimedia: return [
      { value: OccurrenceTypeEnum.otomultimedia, label: 'Oto Teyp/Multimedia' }
    ] as SelectItem[];
  }



  return [
    { value: OccurrenceSubTypeEnum.towTruck, label: 'Çekici' },
    { value: OccurrenceSubTypeEnum.roadsideAid, label: 'Yol Yardım' },
    { value: OccurrenceSubTypeEnum.loadAid, label: 'Yük Yardım' },
  ] as SelectItem[];
}

export { OccurrenceSubTypeEnum, getOccurrenceSubTypeText, getOccurrenceSubTypeItems }