import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AppMessageService } from '../../services/common/app-message.service';

@Component({
  selector: 'app-log-list',
  templateUrl: './log-list.component.html',
  styleUrls: ['./log-list.component.scss']
})
export class LogListComponent implements OnInit {

  constructor(
    private http: HttpClient,
    private message: AppMessageService
  ) { }


  eMaillogList: any[] = [];
  smsLogList: any[];
  logTypes?: string[] = [];
  startDate: Date;
  endDate: Date;
  inputText: string;
  selectedTable: string;

  getEmailLogListService() {
    this.smsLogList=[];
    this.eMaillogList=[];
    let param = { 'startDate': this.startDate, 'endDate': this.endDate, 'userEmail': this.inputText, 'type': this.selectedTable };
    this.http.post<any[]>(`${environment.url}log-list/email`, param).subscribe(eMailData => {
      if (eMailData && eMailData.length > 0) {
        this.eMaillogList = eMailData.reverse();
      } else {
        this.message.errorToast('Veri Yok','Belirtilen Kriterlere Uygun Veri Bulunamadı');
      }
    });
  }

  getAllLogListService() {
    this.smsLogList=[];
    this.eMaillogList=[];
    let param = { 'startDate': this.startDate, 'endDate': this.endDate, 'phoneNumber': this.inputText, 'type': this.selectedTable };
    this.http.post<any[]>(`${environment.url}log-list/get-by-filter`, param)?.subscribe(smsData => {
      if (smsData && smsData.length > 0) {
        this.smsLogList = smsData.reverse();
      } else {
        this.message.errorToast('Veri Yok','Belirtilen Kriterlere Uygun Veri Bulunamadı');
      }
    });
  }

  
  ngOnInit(): void {
    this.logTypes = ["SMS"];
  }

  // ngOnInit(): void {
  //   this.logTypes = ["SMS", "E-Mail"];
  // }

}
