<div class="p-grid table-demo">
    <div class="p-col-12">
        <div class="card">
            <p-table [value]="filteredData" styleClass="oc-border" [rowHover]="true" [rows]="10" [paginator]="true">
                <ng-template pTemplate="caption">
                    <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
                        Tedarikçi Araçları
                        <div class="p-fluid p-formgrid p-grid">
                            <div *ngIf="!isSupplier" class="p-field p-col">
                                <div style="margin: auto; margin-right: 0px; padding-right: 25px;">
                                    <p-dropdown [disabled]="isSupplier || special" inputId="supplier" [options]="suppliersLists"
                                        [(ngModel)]="selectedSupplier" placeholder="Tedarikçi Seçiniz" appendTo="body"
                                        (onChange)="onSupplierChange($event)" filter="true"></p-dropdown>
                                </div>
                            </div>
                            <div *ngIf="!isSupplier" class="p-field p-col">
                                <div style="margin: auto; margin-right: 0px; padding-right: 25px;">
                                    <p-dropdown [disabled]="!selectedSupplier" inputId="supplierBranch" [options]="branches"
                                        [(ngModel)]="selectedBranch" placeholder="Tedarikçi Şube Seçiniz" appendTo="body" 
                                        (onChange)="onBranchChange($event)" filter="true"></p-dropdown>
                                </div>
                            </div>                                                      
                            <div class="p-field p-col">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search"></i>
                                    <input pInputText (input)="searchChange($event)" type="text" placeholder="Ara" />
                                </span>
                            </div>
                            <div class="p-field p-col">
                                <div style="padding-left: 15px;">
                                    <button pButton type="button" (click)="getSupplierCarList()" label="Ara"
                                        icon="pi pi-search">
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th>Plaka</th>
                        <th>Marka</th>
                        <th>Model</th>
                        <th>Yıl</th>
                        <th>Segment</th>
                        <th *ngIf="!isSupplier">Tedarikçi Adı</th>
                        <th>Tedarikçi Şube</th>
                        <th style="width: 14rem">
                            <button pButton type="button" label="Yeni Araç Ekle"
                                (click)="openCarDialog()" icon="pi pi-plus" class="p-button p-button-info p-button-text"
                                style="float: right;"></button>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-car>
                    <tr>
                        <td>
                            <a style="cursor: pointer;" (click)="openCarDialog(car)" icon="pi pi-info-circle"
                                label="Show">{{ car.plateCityCode + ' ' + car.plateNumber }}</a>
                        </td>
                        <td>{{ car?.brand?.name }}</td>
                        <td>{{ car?.matchedModel?.name }}</td>
                        <td>{{ car?.year }}</td>
                        <td>{{ car?.segmentName }}</td>
                        <td *ngIf="!isSupplier">{{ car?.supplier?.name }}</td>
                        <td>{{ getBranchName(car) }}</td>
                        <td></td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="8">Henüz araç girişi yapılmadı.</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>
<app-supplier-car-dialog #carDialog (onNewCarAdded)="onNewCarAdded($event)" [isSupplier]="isSupplier" [special]="special" (onNewCarEdit)="onNewCarEdit($event)" [selectedSupplier]="selectedSupplier"></app-supplier-car-dialog>
