<!-- Daha sonradan aktive edilecek "Bildirim Sistemi"
<div class="notifications-bell">
    <i pBadge class="pi pi-bell" style="font-size: 1.8rem; color: white" [value]="value" severity="danger"></i>
</div>

-->

<p-toast key="customToast" styleClass="customToast-container" [preventOpenDuplicates]="true"
    [showTransformOptions]="'inCustomToast'" [hideTransformOptions]="'outCustomToast'"
    [hideTransitionOptions]="'300ms linear'">
    <ng-template let-message pTemplate="message">
        <div class="customToast-message">
            <div><b>{{message.detail}}</b></div>
            <i [class]="'pi '+message.icon"></i>
        </div>
    </ng-template>
</p-toast>

<p-toast key="noteWatcherToast" styleClass="noteWatcherToast-container" [showTransformOptions]="'inCustomToast'" 
    [hideTransformOptions]="'outCustomToast'" [hideTransitionOptions]="'600ms linear'">
    <ng-template let-message pTemplate="message">
        <div class="noteWatcherToast-message">
            <i [class]="'pi '+message.icon" style="background-color: {{message.data.color}};"></i>
            <div class="noteWatcherToast-message-main">
                <div><b>{{message.summary}}</b></div>
                <div>{{message.detail}}</div>
            </div>
        </div>
    </ng-template>
</p-toast>
