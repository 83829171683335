import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ICity } from '../../domain/location';
import { IOccurrenceFileFilterDto } from '../../domain/occurrence-file';
import { PolicyRequestDto } from '../../domain/policy-request';
import { ISubscriberServiceFilterDto } from '../../domain/subscriber-service';
import { IDashboardInfoV2 } from './dashboard.interface';
import { NotifyAsistansClaimMatchDto } from '../../pages/operator/policy/policy-list/utilities/policy-details';

interface DashboardState {
  filter: ISubscriberServiceFilterDto;
  response: IDashboardInfoV2;

  cities: ICity[];
  services: SelectItem[];
  suppliers:SelectItem[];
  selectedService: string;
  selectedCityCode: string;
  selectedSupplier:string;
  assigned:boolean;
}

interface DashboardEvent {
  event: 'init' | 'response' | 'service-changed' | 'city-changed' | 'supplier-changed' | 'supplierType-changed';
  state: DashboardState;
}

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  state: DashboardState = {
    filter: {
      limit: -1,
      offset: 0,
      dateType: 'daily',
      startDate: new Date(),
      endDate: new Date(),
      orderBy: 'asc',
      customer: '',
      cities: [],
      serviceTypes: [],
      supplierList: [],
      assigned: undefined
    },
    response: {
      files: [],
      services: []
    },

    cities: [],
    services: [],
    suppliers: [],
    selectedService: '',
    selectedCityCode: '',
    selectedSupplier: '',
    assigned: undefined
  };

  private eventSource = new BehaviorSubject<DashboardEvent>({ event: 'init', state: { ...this.state } })
  public events$ = this.eventSource.asObservable();

  constructor(private http: HttpClient,
              private router: Router) { }


  getSearchFilePanelData(filter: PolicyRequestDto){
    return this.http.post<any[]>(`${environment.url}dashboard/filePanel`, filter);
  }

  getInfoV2(filter: ISubscriberServiceFilterDto) {
    return this.http.post<IDashboardInfoV2>(`${environment.url}dashboard/infov2`, filter);
  }

  setFilter(filter: ISubscriberServiceFilterDto) {
    this.state.filter = filter;

    this.saveFilter(filter);

    this.getInfoV2(filter)
        .subscribe((response) => {
          this.state.response = response;

          this.eventSource.next({ event: 'response', state: { ...this.state } });
        })
  }

  setCities(cities: ICity[]) {
    this.state = { ...this.state, cities };
  }

  setServices(services: SelectItem[]) {
    this.state = { ...this.state, services };
  }

  setSelectedService(serviceId: string) {
    this.state = { ...this.state, selectedService: serviceId }
    this.eventSource.next({ event: 'service-changed', state: this.state });
  }


  setSuppliers(suppliers: SelectItem[]) {
    this.state = { ...this.state, suppliers: [...suppliers] };
    this.eventSource.next({ event: 'supplier-changed', state: { ...this.state } });
  }
  setSupplierType(assigned: any) {
    this.state = { ...this.state, assigned: assigned };
    this.eventSource.next({ event: 'supplierType-changed', state: { ...this.state } });
  }

  setSelectedCity(cityCode: string) {

    // eğer aynı şehir tekrar tıklanırsa, şehir seçimi kaldırılıyor
    if (this.state.selectedCityCode === cityCode) {
      const filter = { ...this.state.filter, cities: [] };  
      this.state.selectedCityCode = '';

      this.setFilter(filter);
    } else {
      const city = this.state.cities.find(f => f.code === cityCode);
      const filter = { ...this.state.filter, cities: [city._id] };
  
      this.setFilter(filter);
    }
  }

  goToServiceList(serviceId: string) {
    const filter = { ...this.state.filter, serviceTypes: [serviceId] };

    this.router.navigate(['/operator/service-list'], {
      state: {
        filter
      }
    });
  }

  goToServiceStatusList(status: string) {
    const filter = { ...this.state.filter, serviceTypes: [this.state.selectedService], statuses: [status] };

    this.router.navigate(['/operator/service-list'], {
      state: {
        filter
      }
    });
  }

  goToServiceStatusGroupList(group: string) {
    const filter = { ...this.state.filter, serviceTypes: [this.state.selectedService], statusGroups: [group] };

    this.router.navigate(['/operator/service-list'], {
      state: {
        filter
      }
    });
  }

  goToFileStatusList(status: string) {
    const filter: IOccurrenceFileFilterDto = {
      limit: this.state.filter.limit,
      offset: this.state.filter.offset,
      dateType: this.state.filter.dateType,
      startDate: this.state.filter.startDate,
      endDate: this.state.filter.endDate,
      orderBy: this.state.filter.orderBy,
      status: status,
      customer: this.state.filter.customer,
      text: ''
    };

    this.router.navigate(['/operator/file-list'], {
      state: {
        filter
      }
    });
  }

  saveFilter(filter: ISubscriberServiceFilterDto) {
    localStorage.setItem('dashboardFilter', JSON.stringify(filter));
  }

  loadFilter() {
    const dashboardFilter = localStorage.getItem('dashboardFilter');

    if (dashboardFilter) {
      const filter = JSON.parse(dashboardFilter);

      if (filter) {
        return filter;
      }
    }
  }


  getPolicyExtDetails(policyNumber: string) { /**Asos Servise ek olarak gelen bilgiler için */
  // console.log("policy", policyNumber)
    // const encodedPolicyNumber = encodeURIComponent(policyNumber);
    return this.http.post<any[]>(`${environment.url}dashboard/getPolicyExtDetails`, {policyNumber});
  }

  notifyAsistansClaimMatch(data: NotifyAsistansClaimMatchDto) {
    console.log("notifyAsistansClaimMatch", data);
    return this.http.post<any[]>(`${environment.url}dashboard/notifyAsistansClaimMatch`, data);
  }

  supplierServiceDashboard(filter:ISubscriberServiceFilterDto){
    return this.http.post<any>(`${environment.url}dashboard/supplierServiceDashboard`, filter);
 }
 

}
