import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { AuthService } from '../services/auth/auth.service';
import { AppMessageType } from '../services/common/app-message';
import { AppMessageService } from '../services/common/app-message.service';
import { OctoSupplierNotificationsService } from './octo-supplier-notifications.service';

@Component({
  selector: 'app-octo-supplier-notifications',
  templateUrl: './octo-supplier-notifications.component.html',
  styleUrls: ['./octo-supplier-notifications.component.scss']
})
export class OctoSupplierNotificationsComponent implements OnInit {

  boop: HTMLAudioElement;
  alarm: HTMLAudioElement;

  value: number = 0;
  supplierId:any;
  service: OctoSupplierNotificationsService;
  constructor(
     private messageService: AppMessageService,
     private ms: MessageService,
     private authService:AuthService) {
     }

  ngOnInit() {
    this.supplierId=this.authService.getUserSupplierId();


    if(this.supplierId){
        this.initilize();
    }
  }

  initilize() {

   this.service=new OctoSupplierNotificationsService(this.supplierId)

    // this.boop = new Audio('assets/sounds/Boop.mp3');
    // this.boop.preload = 'auto';
    this.boop = new Audio('assets/sounds/alarm.mp3');
    this.boop.preload = 'auto';
    this.service.getNotifications().subscribe(msg => {
      this.messageService.notification(msg);
      this.value++;
    });

    this.messageService.eventSource.subscribe(event => {
      if(event.messageType === AppMessageType.custom) {

        if(this.boop) {
          this.boop.pause();
          this.boop.currentTime = 0;
          this.boop.play();
        } else {
            console.error('Ses yüklenemedi!');
        }

         this.ms.clear();
         this.ms.add({key: 'customToastSupplier', icon: event.icon, severity: 'custom', summary: event.header, detail: event.message });
      }
    });

  }


}
