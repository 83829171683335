import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-celebration',
  templateUrl: './celebration.component.html',
  styleUrls: ['./celebration.component.scss']
})
export class CelebrationComponent implements OnInit {

  isFestiveDay: boolean;
  isBirthday: boolean;

  celebrationData: any;


  constructor(
    private config: DynamicDialogConfig
  ) { }

  ngOnInit(): void {

    if (this.config.data.celebrationData.isBirthday) {
      this.celebrationData = this.config.data.celebrationData;
      this.isBirthday = this.celebrationData.isBirthday;
    }

    if (this.config.data.celebrationData.isFestiveDay) {
      this.celebrationData = this.config.data.celebrationData;
      this.isFestiveDay = this.celebrationData.isFestiveDay;
    }
  }
}
