import { Component, OnInit } from "@angular/core";
import { AboutService } from "./services/about.service";
interface City {
    name: string;
    code: string;
}

@Component({
    selector: "app-about",
    templateUrl: "./about.component.html",
    styleUrls: ["./about.component.scss"],
})
export class AboutComponent implements OnInit {
    versions: any[] = [];
    selectedVersion: any = {
        version: [],
        changes: [],
        date: [],
    };
    selectedChanges: string[] = [];
    errorMessage: string;

    constructor(private aboutService: AboutService) {}

    ngOnInit() {
        this.getVersions();
    }

    private async getVersions() {
        this.aboutService.getVersion().subscribe(
            (res) => {
                this.versions = res.map((e) => {
                    return {
                        ...e,
                        label: "v" + e.version,
                    };
                });
                this.selectedVersion = this.versions[0];
            },
            (err) => {
                console.error("Hata oluştu:", err);
                this.errorMessage = "Bir hata oluştu. Lütfen tekrar deneyin.";
            }
        );
    }
}
