<div class="card">
    <p-table [value]="campaigns" styleClass="oc-border" [rowHover]="true" [rows]="10" [paginator]="true">
        <ng-template pTemplate="caption">
            <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
                Kampanya Listesi
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th>Kampanya Adı</th>
                <th>NOC Template ID</th>
                <th>Kampanya Oluşturan</th>

                <th style="width: 14rem">
                    <button pButton type="button" label="Yeni Ekle" (click)="openDialog()" icon="pi pi-plus"
                        class="p-button p-button-info p-button-text" style="float: right;"></button>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-data>
            <tr>
                <td>
                    <a style="cursor: pointer;" (click)="openDialog(data)" icon="pi pi-info-circle"
                        label="Show">{{data.campaignName}}</a>
                </td>
                <td>{{data.nocTemplateId}}</td>
                <td>{{data.createdBy?.name}} {{data.createdBy?.surname}}</td>

                <td></td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="8">Kampanya mevcut değildir.</td>
            </tr>
        </ng-template>
    </p-table>
</div>

<p-dialog header="Kampanya Tanımla" [(visible)]="dialog" [style]="{ width: '60vw' }" [resizable]="false">

    <div class="noc-top"></div>
    <div class="noc-bottom"></div>

    <div class="p-fluid p-formgrid p-grid">

        <div class="p-field p-col-12 p-md-8">
            <label for="supplier">Kampanya Adı</label>
            <input type="text" pInputText [(ngModel)]="data.campaignName" />
        </div>

        <div class="p-field p-col-12 p-md-2">
            <label for="segmentName">NOC Template ID</label>
            <input type="text" pInputText [(ngModel)]="data.nocTemplateId">
        </div>
        <div class="p-field p-col-12 p-md-2">
            <label for="segmentName">Template Karakter Sayısı</label>
            <input type="number" pInputText [(ngModel)]="data.templateCharacterCount">
        </div>

        <div class="p-field p-col-12 p-md-8">
            <label for="supplier">Kampanya SMS Metni</label>
            <textarea rows="19" cols="30" pInputTextarea [(ngModel)]="data.campaignSmsText" (input)="onTextChange()"></textarea>
        </div>
        <div class="p-field p-col-12 p-md-4">
            <div class="p-mb-5"></div>
            <p-table [value]="variables" styleClass="p-datatable-gridlines">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Değişken Bilgisi</th>
                        <th>Değişken Adı</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-variable>
                    <tr>
                        <td>{{variable.name}}</td>
                        <td>{{variable.code}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <div class="p-field p-col-12 p-md-8">
            <div style="background-color: red; padding: 15px; border-radius: 10px; color:white;">

                <div class="p-grid">
                    <div class="p-col-6">
                       Maksimum Karakter Sayısı:
                    </div>
                    <div class="p-col-6">
                        {{this.maxKampanyaSMSKarakteri}}
                    </div>
                </div>
                <div *ngIf="data.templateCharacterCount" class="p-grid p-mt-2">
                    <div class="p-col-6">
                      Kullanılabilir Karakter Sayısı:
                    </div>
                    <div class="p-col-6">
                        {{this.maxKampanyaSMSKarakteri - this.data.templateCharacterCount -  this.campaignSmsTextCount}}
                    </div>
                </div>


            </div>
          </div>
    </div>


    <div class="footer" style="float: right; display: flex">
        <p-button icon="pi pi-save" [label]="data._id ? 'Güncelle' : 'Kaydet'" (onClick)="saveOrUpdate()"
            styleClass="p-button"></p-button>
    </div>

</p-dialog>