<div class="dialog" *ngIf="isBirthday || isFestiveDay">
  <div class="p-dialog-content">
    <div class="container">
      <div class="subject">
        <ng-container *ngIf="isBirthday">
          <h2>Sn. {{celebrationData.name + ' ' + celebrationData.surName}}</h2>
          <p>Doğum Gününüz Kutlu Olsun 🎉</p>
          <img class="dialog-image" src="assets/layout/images/bday.png" alt="Birthday Celebration">
        </ng-container>
        <ng-container *ngIf="isFestiveDay">
          <h2 style="max-width: 540px;">{{celebrationData.festiveName}} Kutlu Olsun 🎉</h2>
          <img class="dialog-image" src="assets/layout/images/festive_celebration.png" alt="Festive Day Celebration">
        </ng-container>
      </div>
    </div>
  </div>
</div>
