import { Directive, ElementRef, HostListener, Input, OnInit, EventEmitter, Output, OnDestroy, Self, Optional } from '@angular/core';
import { NgForm, ControlContainer, FormGroupDirective } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ButtonDirective } from 'primeng/button';
import { AppMessageService } from '../services/common/app-message.service';
import { AppMessageType } from '../services/common/app-message';

@Directive({
  selector: '[appSubmit]',
  providers: [NgForm]
})
export class SubmitButtonDirective extends ButtonDirective implements OnInit, OnDestroy {
  @Output() appSubmit = new EventEmitter<void>();
  @Input() loadingText: string;

  private prevLabel: string;
  private appMessage$: Subscription;

  constructor(public el: ElementRef<any>,
              private appMessageService: AppMessageService,
              private fg: ControlContainer) {
    super(el, document);

    this.icon = 'pi pi-check';
  }

  @HostListener('click') onClick() {
    if (this.form !== undefined && this.form !== null) {
      if (this.form.invalid) {
        this.appSubmit.emit();
        return;
      }
    }
    if (this.form === undefined || this.form === null) {
      this.appSubmit.emit();
      return;
    }
    this.setSpinner();

    this.appSubmit.emit();
  }

  get form() { return this.fg.formDirective ? (this.fg.formDirective as FormGroupDirective).form : null; }

  ngOnInit() {
    this.appMessage$ = this.appMessageService.eventSource.subscribe(event => {
      if (event.messageType === AppMessageType.success || event.messageType === AppMessageType.error ||
          event.messageType === AppMessageType.successToast || event.messageType === AppMessageType.errorToast ||
          event.messageType === AppMessageType.hideWorkingPanel ||
          event.messageType === AppMessageType.resetSubmitButton) {
        this.reset();
      }
    });
  }

  ngOnDestroy() {
    if (this.appMessage$ !== undefined) {
      this.appMessage$.unsubscribe();
    }
  }

  setSpinner() {
    this.prevLabel = this.label;
    this.icon = 'pi pi-spin pi-spinner';
    this.el.nativeElement.disabled = true;
    this.label = this.loadingText === undefined ? 'Kaydediliyor...' : this.loadingText;
  }

  reset() {
    if (!this.prevLabel) {
      return;
    }
    this.icon = 'pi pi-check';
    this.label = this.prevLabel;
    this.el.nativeElement.disabled = false;
  }
}
