import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";


@Injectable({
    providedIn: 'root'
})

export class AboutService {
    constructor(private http: HttpClient) {}

    getVersion() {
      return  this.http.get<any[]>(`${environment.url}versions`)
    }
}



