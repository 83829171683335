import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IListEnvelope } from 'src/app/domain/list.interface';
import {ISupplierStaff, SupplierStaffDto } from 'src/app/domain/supplier-staff';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SupplierStaffService {
    getSupplierItems() {
      throw new Error('Method not implemented.');
    }
    constructor(private http: HttpClient) { }

    getSupplierStaffs(page?: number, limit?: number) {
        return this.http.get<IListEnvelope<ISupplierStaff>>(`${environment.url}supplierStaffs?limit=${limit ?? ''}&page=${page ?? ''}`);
    }

    getSupplierStaffsBySupplierId(supplierId: string, vehicleType?: string) {
        return this.http.get<ISupplierStaff[]>(`${environment.url}supplierStaffs/bySupplierId/${supplierId}?vehicleType=${vehicleType ? vehicleType : ''}`);
    }

    addSupplierStaff(supplierStaff: SupplierStaffDto) {
        return this.http.post<ISupplierStaff>(`${environment.url}supplierStaffs/create`, supplierStaff);
    }

    editSupplierStaff(id: string, supplierStaff: SupplierStaffDto) {
        return this.http.put<ISupplierStaff>(`${environment.url}supplierStaffs/${id}`, supplierStaff);
    }

    detailSupplierStaff(id: string) {
        return this.http.get<ISupplierStaff>(`${environment.url}supplierStaffs/${id}`);
    }

    deleteSupplierStaff(id: string) {
        return this.http.delete(`${environment.url}supplierStaffs/${id}`);
    }
}
