import { Component, Input, OnInit } from '@angular/core';


@Component({
  selector: 'app-sms-log',
  templateUrl: './sms-log.component.html',
  styleUrls: ['./sms-log.component.scss']
})
export class SmsLogComponent implements OnInit {
  display: boolean = false;
  paramsDialog: boolean = false;
  paramsData: string[];
  response: string;
  @Input('smsLogList') smsLogList :any[];


  constructor() { }
  ngOnInit(): void {
  }

  showDialog(value:string) {
    console.log(value)
    this.display = true;
    this.response=value;
  }
  paramsShowDialog(value:string[]) {
    console.log(value)
    this.paramsData = value;
    this.paramsDialog = true;
  }

  parseSoapResponse(response: string): string {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(response, "text/xml");
    const messageElements = xmlDoc.getElementsByTagName("message");
    if (messageElements.length > 0) {
      const message = messageElements[0].textContent;
      return message === 'Successful' ? 'Başarılı' : 'Başarısız';
    }
    return 'Başarısız'; // Or return a default/fallback message if <message> tag is not found
  }

  getResponseStatus(response: string): string {
    return this.parseSoapResponse(response);
  }

  // getParamValues(params: any[]): string {
  //   return params.map(p => p.param).join(', ');
  // }

}
