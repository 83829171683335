import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IVersionSetting } from '../../domain/version-setting';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { AuthService } from '../auth/auth.service';


@Injectable({
    providedIn: 'root'

})
export class VersionSettingService {
    constructor(private http: HttpClient,
      private authService : AuthService,
      ) {}


    getAllVersionSettings(){
      return this.http.get<IVersionSetting[]>(`${environment.url}versionSettings`);
    }

    updateVersionSetting(versionSetting: IVersionSetting) {
      const updateUrl = `${environment.url}versionSettings/${versionSetting._id}`;
      const userId = this.authService.getUserId();
      const userIdName = this.authService.getUserName();
      let value= versionSetting.activeVersion?.name

      const updatedVersionSetting: IVersionSetting = {

        ...versionSetting,
        activeVersion: versionSetting.activeVersion,
        logs: [
          ...(versionSetting.logs || []),
          {
            userName: userId,
            user: userIdName,
            oldValue:value,
            //versionSetting.selectedVersion?.name || '',
            newValue: versionSetting.activeVersion?.name || '',
            actionType: 'Versiyon Değişikliği',
            date: new Date()
          }
        ]
      };

      updatedVersionSetting.logs.sort((a, b) => {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      });

      return this.http.put(updateUrl, updatedVersionSetting);
    }

    // getVersion(module: string) {
      // TODO: @merve Burası tüm versiyonları çekip içinden 1 elemanı ayıklayıp onun da aktifversiyonlarından sadece 1 string alıyor, çok verimsiz. Düzeltilmeli.
      //   İstediğimiz işi yapan api yazılmış(servera) ancak burası ile bağlanmamış. @Get(:module) -> şeklinde olduğu için çalışmıyor olabilir.
      //   Örnek olarak => 'Fiyat Modülü' texti gönderiliyor, ondan algılayamıyor olabilir. Ya gönderilen string parse edilir yada querye bağlanabilir.


    //   return this.http.get<IVersionSetting[]>(`${environment.url}versionSettings`).pipe(
    //     map(versionSettings => {
    //       console.log(versionSettings)
    //       const matchedSetting = versionSettings.find(setting => setting.name === module);
    //       if (matchedSetting) {
    //         return matchedSetting.activeVersion.name;
    //       } else {
    //         return null;
    //       }
    //     }),
    //     catchError(error => {
    //       console.error('Versiyon alınırken bir hata oluştu:', error);
    //       return of(null);
    //     })
    //   );
    // }
    // client/version-setting.service.ts
    getVersion(module: string) {
      return this.http.post<{version: string}>(`${environment.url}versionSettings/version`, {module: module});
    }



}
