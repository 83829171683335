<p-dialog [header]="cars._id? 'Araç Güncelleme Formu' : 'Yeni Araç Kayıt Formu'" [(visible)]="carDialog" [modal]="true"
    showEffect="fade" (onHide)="onHide()" [style]="{ width: '800px' }

">

    <form [formGroup]="form">
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-6">
                <label for="supplier">Tedarikçi</label>
                <p-dropdown [disabled]="isSupplier || special" inputId="supplier" [options]="suppliersLists" (onChange)="onSupplierChange($event)" optionLabel="label" optionValue="value"
                    formControlName="selectedSupplier" placeholder="Seçiniz" appendTo="body" filter="true">
                </p-dropdown>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="branch">Tedarikçi Şube</label>
                <p-dropdown inputId="branch" [options]="branches" optionLabel="label" optionValue="value"
                    formControlName="supplierBranch" placeholder="Seçiniz" appendTo="body" filter="true"
                    [disabled]="isSupplier || special">
                </p-dropdown>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <label for="supplier">Plaka İl Kodu</label>
                <p-dropdown placeholder="İl Kodu" [options]="cities" label="code" formControlName="plateCityCode"
                    [showClear]="true" [style]="{'width':'100%'}">
                </p-dropdown>
            </div>
            <div class="p-field p-col-12 p-md-4">
                <label for="supplier">Plaka Numarası</label>
                <input formControlName="plateNumber" type="text" pInputText />
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="supplier">Şasi Numarası
                    <i class="pi pi-info-circle"
                        pTooltip="Şasi numarası, genellikle 17 karakterden oluşur ve I, O, Q harflerini içeremez. Her bir karakter veya grup, aracın üreticisini, markasını, modelini ve üretim yılını belirtir."
                        tooltipPosition="right"></i>
                </label>
                <input formControlName="chassisNumber" type="text" pInputText maxLength="17" />
                <div *ngIf="form.controls.chassisNumber.invalid && form.controls.chassisNumber.touched">
                    <small class="form-text text-muted">Şasi numarasını uygun formatta giriniz.</small>
                </div>
            </div>

            <div class="p-field p-col-12 p-md-6">
                <label for="name">Marka</label>
                <p-dropdown formControlName="brand" [disabled]="!form.controls.selectedSupplier.value"
                    [options]="brandOptions" optionLabel="name" optionValue="_id" [showClear]="true"
                    (onChange)="brandChange($event)"
                    [placeholder]="form.controls.selectedSupplier.value ? 'Otomobil markası seçiniz' : 'Önce tedarikçi seçin'"
                    appFilterDropdown>
                </p-dropdown>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="surname">Model</label>
                <p-dropdown formControlName="vehicleModel" [options]="vehicleModels" optionLabel="name"
                    optionValue="_id" emptyMessage="Önce içinde Model olan bir Marka seçimi yapılmalıdır"
                    [showClear]="true" [disabled]="true" (onChange)="vehicleModelChange($event)"
                    placeholder="Otomobil modeli seçiniz" appFilterDropdown>
                </p-dropdown>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="phone">Yıl</label>
                <p-dropdown formControlName="year" [options]="vehicleYears" optionLabel="year" 
                    emptyMessage="Önce içinde Yıl olan Model seçimi yapılmalıdır" [showClear]="true" [disabled]="true"
                    (onChange)="vehicleYearChange($event);" placeholder="Model yılı seçiniz">
                </p-dropdown>

            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="segmentName">Segment</label>
                <input formControlName="segmentName" type="text" pInputText disabled>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="phone">Vites Türü</label>
                <p-dropdown formControlName="vehicleGear" [options]="vehiclegears" placeholder="Vites türü seçiniz"
                    appendTo="body" appFilterDropdown>
                </p-dropdown>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="phone">Yakıt Türü</label>
                <p-dropdown formControlName="fuelType" [options]="fueltypes" placeholder="Yakıt türü seçiniz"
                    appendTo="body" appFilterDropdown>
                </p-dropdown>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="phone">Renk</label>
                <p-dropdown formControlName="vehicleColor" [options]="vehiclecolors" placeholder="Renk seçiniz"
                    appendTo="body" appFilterDropdown>
                </p-dropdown>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="plaka">Güncel KM</label>
                <input formControlName="vehicleKm" id="vehicleKm" type="text" pInputText />
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="plaka">Motor Hacmi</label>
                <input formControlName="engineCapacity" id="engineCapacity" type="text" pInputText />
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="supplier">Beygir Gücü</label>
                <input formControlName="enginePower" id="enginePower" type="text" pInputText />
            </div>
            <div class="p-field p-col-12 p-md-12">
                <label for="phone">Araç Özellikleri</label>
                <p-multiSelect formControlName="vehicleFeatures" appendTo="body" [options]="vehiclefeatures" optionValue="value" optionLabel="label"
                maxSelectedLabels="50"
                appFilterDropdown>
                </p-multiSelect>
            </div>
            

        </div>
    </form>
    <div class="p-fluid p-formgrid p-grid ">
        <div class="p-field p-col-12 p-md-8">
            <label for="isActive">Aktiflik Durumu</label>
            <div><p-inputSwitch [(ngModel)]="isActive"></p-inputSwitch></div>
        </div>
        <div class="p-field p-col-12 p-md-2 ">
            <button type="button" pButton [icon]="cars._id ? 'pi pi-refresh' : 'pi pi-check'"
                [label]="cars._id ? 'Güncelle' : 'Kaydet'" (click)="updateOrSave()" class="p-button-text">
            </button>
        </div>
        <div class="p-field p-col-12 p-md-2 ">
            <button type="button" pButton icon="pi pi-times" (click)="closeDialog()" label="İptal"
                class="p-button-text">
            </button>
        </div>
    </div>
    

</p-dialog>