import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { NotesService } from './services/notes.service';
import { INote } from './utils/note.interface';
import { OverlayPanel } from 'primeng/overlaypanel';
import { AppMessageService } from '../../services/common/app-message.service';
import { NotesSocketService } from './services/notes.socket.service';
import { UserService } from '../../pages/user-management/services/user.service';
import { SelectItem } from 'primeng/api';
import { AuthPermissionService } from '../../services/auth/auth-permission.service';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'app-self-notes',
  templateUrl: './self-notes.component.html',
  styleUrls: ['./self-notes.component.scss']
})
export class SelfNotesComponent implements OnInit {

  notes: INote[] = [];
  @Output() onTotalNotesChange = new EventEmitter<number>();
  totalNotes: number = 0;

  @ViewChild('op') panel: OverlayPanel;
  @ViewChild('sendPanel') sendPanel: OverlayPanel;
  submiting: boolean = false;
  searchText: string = '';

  openNotes: boolean = false;

  title: string = '';
  note: string = '';
  pinned: boolean= false;
  tags: string[] = [];
  color: string = '#FDFDBC';
  private defaultColor = '#FDFDBC';
  saveAsSticky: boolean = false;

  permissions: { sendNote: boolean } = {sendNote: false};
  users: SelectItem[] = [];
  selectedUsers: string[] = [];
  selectedTitle: string = '';
  selectedNote: string = '';

  settings: {
    smallMode: boolean,
    showNoteCount: boolean
  } = {
    smallMode: false,
    showNoteCount: true
  }

  constructor(private service: NotesService, 
      private socketService: NotesSocketService, 
      private messageService: AppMessageService, 
      private userService: UserService, 
      private authService: AuthService,
      private permissionService: AuthPermissionService) {
    this.loadLocalSettings();
    this.permissions.sendNote = this.permissionService.permissionCheck('Notes.sendNote', 'isList');
  }

  ngOnInit(): void {
    this.reloadNotes();

    this.socketService.getNoteRecieved().subscribe( note => {
      this.reloadNotes();
    });
  }

  addNote() {
    if(!this.title) {
      this.submiting = true;
      return;
    }

    this.service.create({
      title: this.title,
      note: this.note,
      pinned: this.pinned,
      tags: this.tags,
      color: this.color
    }).subscribe( res => {
      if(this.saveAsSticky) {
        this.openAsSticky(res.title, res.note);
      }
      this.cencelNewNote();
      this.reloadNotes();
    });
  }

  pinNote(note: INote) {
    note.pinned = !note.pinned;
    this.service.update(note).subscribe( res => {
      note = res;
      this.sort();
    });
  }

  deleteNote(id: string) {
    this.service.delete(id).subscribe( res => {
      this.notes = this.notes.filter( note => note._id !== id);
      this.totalNotes -= 1;
      this.updateNoteCount();
    });
  }

  cencelNewNote() {
    this.submiting = false;
    this.pinned = false;
    this.title = '';
    this.note = '';
    this.tags = [];
    this.color = this.defaultColor;
    this.saveAsSticky = false;
    this.panel.hide();
  }

  openAsSticky(title: string, note: string) {
    this.messageService.stickyNote(title, note);
  }

  search() {
    this.service.getMyNotes(this.searchText).subscribe(res => {
      this.notes = res;
    });
  }

  changeSmallMode() {
    this.settings.smallMode = !this.settings.smallMode;
    localStorage.setItem('note-settings', JSON.stringify(this.settings));
  }

  changeCountDisplay() {
    this.settings.showNoteCount = !this.settings.showNoteCount;
    localStorage.setItem('note-settings', JSON.stringify(this.settings));
    this.updateNoteCount();
  }

  private async initializeSendNote() {
    const users = await this.userService.getUserItems().toPromise();
    const selfUserId = this.authService.getUserId();
    this.users = users.filter(user => user.value !== selfUserId);
  }

  async openSendPanel(event, note: INote) {
    if(this.users.length === 0) {
      await this.initializeSendNote();
    }
    this.sendPanel.show(event);
    this.selectedTitle = note.title;
    this.selectedNote = note._id;
  }

  sendNote() {
    this.socketService.addWatcher(this.selectedNote, this.selectedUsers);
    this.sendPanel.hide();
    this.messageService.successToast('Not Gönderildi', `[${this.selectedTitle}] notu toplamda ${this.selectedUsers.length} kişiye başarıyla gönderildi.`);
  }

  getTagsText(tags: string[]): string {
    let result = '';
    tags.forEach(tag => {
      result += '- '+tag+'\n';
    });
    return result;
  }

  private reloadNotes(text?: string) {
    this.searchText = '';
    this.service.getMyNotes(text).subscribe(res => {
      this.notes = res;
      this.totalNotes = res.length;
      this.updateNoteCount();
    });
  }

  private sort() {
    this.notes = this.notes.sort( (a, b) => {
      if(a.pinned) {
        return b.pinned ? (new Date(b.updateDate).getTime() - new Date(a.updateDate).getTime()) : -1;
      }
      return b.pinned ? 1 : new Date(b.updateDate).getTime() - new Date(a.updateDate).getTime();     
    });
  }

  private loadLocalSettings() {

    const storedSettings = localStorage.getItem('note-settings');
    if(storedSettings) {
      this.settings = JSON.parse(storedSettings);
    } else {
      localStorage.setItem('note-settings', JSON.stringify(this.settings));
    }
  }

  private updateNoteCount() {
    if(this.settings.showNoteCount) {
      this.onTotalNotesChange.emit(this.totalNotes);
      return;
    }
    this.onTotalNotesChange.emit(0);
  }

}
