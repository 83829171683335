<div class="noc-top"></div>
<div class="noc-bottom"></div>

    <div class="script-tags" *ngIf="scriptData.tags">   
        <div class="tag" *ngFor="let tag of scriptData.tags.slice(0, 5)"><i class="pi pi-tag"></i> {{ tag }} </div>
    </div>    
<div class="container">
    <div class="script-title">
        {{ scriptData.title }}
    </div>
<p-divider></p-divider>
    <div class="ql-editor" [innerHTML]="scriptData.safeContent"></div>
</div>
