import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IListEnvelope } from 'src/app/domain/list.interface';
import { ServiceDto, IService, IServiceQuestion, ServiceQuestionDto } from 'src/app/domain/service';
import { environment } from 'src/environments/environment';
import { AppCacheService } from './app-cache.service';

@Injectable({
    providedIn: 'root'
})
export class ServiceService {

    constructor(private http: HttpClient,
                private cache: AppCacheService) {}

    getAllServices(): Observable<IListEnvelope<IService>> {

        const cachedItem = this.cache.get('SERVICES_ALL');
        if (cachedItem) {
            return of(cachedItem);
        }

        return this.http.get<IListEnvelope<IService>>(`${environment.url}services`)
                                .pipe(
                                    tap(t => this.cache.set('SERVICES_ALL', t, 1000 * 60 * 10))
                                );
    }

    getServices(page?: number, limit?: number) {
        return this.http.get<IListEnvelope<IService>>(`${environment.url}services?limit=${limit ?? ''}&page=${page ?? ''}`);
    }

    async getServiceItems(): Promise<SelectItem[]> {
        return new Promise((resolve, reject) => {
            this.getAllServices()
                    .subscribe((response) => {
                        const services = response.rows.map<SelectItem>(m => ({ value: m._id, label: m.name }));
                        resolve(services);
                    },
                    (error) => reject(error)
                    );
        });
    }

    async getServiceItemsWithCode(): Promise<SelectItem[]> {
        return new Promise((resolve, reject) => {
            this.getAllServices()
                    .subscribe((response) => {
                        const services = response.rows.map<SelectItem>(m => ({ value: m._id, label: m.name, code:m.code }));
                        resolve(services);
                    },
                    (error) => reject(error)
                    );
        });
    }


    addService(service: ServiceDto) {
        return this.http.post<IService>(`${environment.url}services/create`, service);
    }

    editService(id: string, service: ServiceDto) {
        return this.http.put<IService>(`${environment.url}services/${id}`, service);
    }

    detailService(id: string) {
        return this.http.get<IService>(`${environment.url}services/${id}`);
    }

    deleteService(id: string) {
        return this.http.delete(`${environment.url}services/${id}`,);
    }
 
    
    toggleServiceActivation(id: string, isActive: boolean){
        console.log("service.", isActive)
        return this.http.patch<IService>(`${environment.url}services/${id}`, { isActive: isActive });
    }

    /**
     * Hizmetler için sorulması gereken soruların listesini alır
     */
    getAllQuestions(): Observable<IServiceQuestion[]> {
        return this.http.get<IServiceQuestion[]>(`${environment.url}services/questions/all`);
    }

    addQuestion(question: ServiceQuestionDto){
        return this.http.post<IServiceQuestion>(`${environment.url}questions/add`, question);
    }

    updateActivate(id: string, isActive: boolean){
        return this.http.put<IServiceQuestion>(`${environment.url}questions/setActive/${id}`, {isActive: isActive});
    }

    updateQuestion(id: string, question: ServiceQuestionDto) {
        return this.http.put<IServiceQuestion>(`${environment.url}questions/${id}`, question);
    }
}
