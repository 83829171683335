import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RolesEnum } from '../../domain/decorators/roles.decorator';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class HomeGuard  {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authService.isLoggedIn()) {

        const role = this.authService.getUserRole();

        if (role === RolesEnum.supplier) {
            this.router.navigate(['/supplier-operations']);
            return false;
        }

        if (role === RolesEnum.user) {
            this.router.navigate(['/operator/request']);
            return false;
        }

        if (role === RolesEnum.callCenter) {
            this.router.navigate(['/operator/add-call']);
            return false;
        }

        return true;
    }

    // not logged in so redirect to login page
    this.router.navigate(['/login'],{state:{shownMessage:true}});
    return false;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }
}
