<div class="p-grid">
    <div class="p-xs-12 p-sm-12 p-md-6 p-lg-4 p-lg-offset-4 p-xl-offset-4 p-xl-4">
        <form [formGroup]="form" autocomplete="off">
            <p-panel header="Şifrenizi yenileyin">    
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-12">
                        <label for="oldPassword">Eski şifreniz</label>
                        <input id="oldPassword" formControlName="oldPassword" type="password" pInputText />
        
                        <div *ngIf="submitted && form.controls.oldPassword.errors">
                            <div *ngIf="form.controls.oldPassword.errors.required" class="validation-error">
                              Eski şifrenizi giriniz.
                            </div>
                        </div>
                    </div>
        
                    <div class="p-field p-col-12 p-md-12">
                        <label for="newPassword">Yeni şifreniz</label>
                        <input id="newPassword" formControlName="newPassword" type="password" pInputText />
        
                        <div *ngIf="submitted && form.controls.newPassword.errors">
                            <div *ngIf="form.controls.newPassword.errors.required" class="validation-error">
                              Yeni şifrenizi giriniz.
                            </div>
        
                            <div *ngIf="form.controls.newPassword.errors.minlength" class="validation-error">
                                Yeni şifreniz en az 8 karakter olmalıdır.
                            </div>
        
                            <div *ngIf="form.controls.newPassword.errors.maxlength" class="validation-error">
                                Yeni şifreniz en az 8 karakter olmalıdır.
                            </div>
                        </div>
        
                    </div>
                    <div class="p-field p-col-12 p-md-12">
                        <label for="newPassword2">Yeni şifreniz tekrar</label>
                        <input id="newPassword2" formControlName="newPassword2" type="password" pInputText />
        
                        <div *ngIf="submitted && form.controls.newPassword2.errors">
                            <div *ngIf="form.controls.newPassword2.errors.required" class="validation-error">
                              Yeni şifrenizi giriniz.
                            </div>
        
                            <div *ngIf="form.controls.newPassword2.errors.minlength" class="validation-error">
                                Yeni şifreniz en az 8 karakter olmalıdır.
                            </div>
        
                            <div *ngIf="form.controls.newPassword2.errors.maxlength" class="validation-error">
                                Yeni şifreniz en az 8 karakter olmalıdır.
                            </div>
                        </div>
                    </div>

                    <div class="p-field p-col-12 p-md-12 p-text-center" *ngIf="generalErrorMessage">
                        <div class="validation-error">
                            {{ generalErrorMessage  }}
                        </div>
                    </div>
                </div>
            
                <p-footer>
                    <button type="button"                            
                            (appSubmit)="onResetPassword()"
                            icon="pi pi-refresh"
                            label="Değiştir"
                            loadingText="Lütfen bekleyiniz."></button>
                </p-footer>
            </p-panel>
        </form>
    </div>
</div>

<form [formGroup]="otpForm" autocomplete="off">
    <p-dialog [(visible)]="showTokenDialog" header="PIN" modal="true" [style]="{ 'min-width': '300px' }">
        <div class="p-grid">
            <div class="p-col-12 p-text-center p-mb-3">
                <p>Mailinize gelen pin kodunu giriniz.</p>
            </div>
        </div>

        <div class="p-grid">
            <div class="p-offset-3 p-col-6 p-text-center">
                <input  id="otpToken"
                        formControlName="otpToken"
                        pInputText type="text"
                        maxlength="6"
                        class="p-text-center"
                        [style]="{'width': '100%', 'font-weight': 'bold', 'font-size': '1.5em'}"/>

                <div *ngIf="otpSubmitted && otpForm.controls.otpToken.errors">
                    <div *ngIf="otpForm.controls.otpToken.errors.required" class="validation-error">
                      PIN kodunu giriniz
                    </div>

                    <div *ngIf="otpForm.controls.otpToken.errors.minlength" class="validation-error">
                        PIN kodu en az 6 karakter olmalıdır.
                    </div>

                    <div *ngIf="otpForm.controls.otpToken.errors.maxlength" class="validation-error">
                        PIN kodu en fazla 6 karakter olmalıdır.
                    </div>
                </div>
            </div>
        </div>
        
        <div class="p-grid p-mb-2">
            <div class="p-offset-4 p-col-4 p-text-center">
                <div [appCountdown]="tokenCountdown"></div>
            </div>
        </div>


        <div class="p-grid">
            <div class="p-col-12 p-md-12 p-text-center" *ngIf="generalErrorMessage">
                <div class="validation-error">
                    {{ generalErrorMessage }}
                </div>
            </div>
        </div>

        <div class="p-grid p-mt-2">
            <div class="p-offset-3 p-col-6 p-text-center">
                <button type="button"                            
                        (appSubmit)="onSendOTPToken()"
                        icon="pi pi-check-circle"
                        label="Onayla"
                        class="p-button-success"
                        loadingText="Lütfen bekleyiniz."
                        [style]="{ 'width': '100%' }"
                        ></button>
            </div>
        </div>
    </p-dialog>
</form>