import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppMessageService } from '../../../services/common/app-message.service';
import { OccurenceParameterService } from '../../../services/occurence-parameter.service';
import { IOccurrenceParameter, OccurrenceParameterDto } from '../occurrence-parameter';
import { getParameterTypeItem, OccurrenceParameterType } from '../occurrence-parameter-type';

@Component({
  selector: 'app-occurrence-parameter-add',
  templateUrl: './occurrence-parameter-add.component.html',
  styleUrls: ['./occurrence-parameter-add.component.scss']
})
export class OccurrenceParameterAddComponent implements OnInit {
  form: FormGroup
  addDialog: boolean = false;
  submitted: boolean = false;
  parameterTypes: SelectItem[];
  parameterId: string = '';
  initialFormState: any;

  @Output() addedParameter = new EventEmitter<IOccurrenceParameter>();
  @Output() editedParameter = new EventEmitter<IOccurrenceParameter>();

  constructor(private fb: FormBuilder,
              private parameterService: OccurenceParameterService,
              private appMsgService: AppMessageService) {
    this.form = this.fb.group({
      name: new FormControl({value:'', disabled: false}, Validators.required ),
      exCode: new FormControl({value:'', disabled: false}, Validators.required ),
      type: new FormControl({value:OccurrenceParameterType.string, disabled: false}, Validators.required),
      value: new FormControl({value:'', disabled: false}, [Validators.required]),
      description: new FormControl({value:'', disabled: false}, Validators.required),
      group: new FormControl({value:'', disabled: false}, Validators.required)
    });

    this.initialFormState = this.form.value;
   }

  ngOnInit(): void {
    this.parameterTypes = getParameterTypeItem();
  }

  showDialog() {
    this.parameterId = ''
    this.form.reset(this.initialFormState);
    this.form.enable();
    this.addDialog = true;
  }

  addParameter() {
    this.submitted = true;
    this.parameterId = '';

    if(this.form.invalid) {
      return;
    }

    this.parameterService.addParameter(this.form.value)
    .pipe(
      catchError((err) => {
        this.appMsgService.reportError(err);
        return throwError(err);
      }))
    .subscribe(res => {
      this.appMsgService.successToast('Parametre başarıyla eklendi.', '');
      this.addedParameter.emit(res);
        }, (error) => {
          return throwError(error);
        });
        this.addDialog = false;
        this.submitted = false;
  }
  
  editParameterForm(parameter: IOccurrenceParameter) {
    this.parameterId = parameter._id;

    if(parameter.type === 'array') {
      this.form.controls.value.setValue([]);
    }

    this.form.patchValue({
      name: parameter.name,
      exCode: parameter.exCode,
      type: parameter.type,
      value: parameter.value,
      description: parameter.description,
      group: parameter.group
    });
    if(parameter.type === 'date') {
      this.form.controls.value.setValue(new Date(parameter.value));
    }

    //this.form.controls.group.disable();
    //this.form.controls.name.disable();
    //this.form.controls.type.disable();
    //this.form.controls.description.disable();

    this.addDialog = true;
  }

  editParameter() {
    this.submitted = true;
    if(this.form.invalid) {
      return;
    }

    this.parameterService.editParameterValue(this.parameterId, this.form.value)
    .pipe(
      catchError((err) => {
        this.appMsgService.reportError(err);
        return throwError(err);
      }))
    .subscribe(res => {
      this.appMsgService.successToast('Parametre değeri başarıyla güncellendi.', '');
      this.editedParameter.emit(res);
        }, (error) => {
          return throwError(error);
        });
        this.addDialog = false;
        this.submitted = false;
  }
}
