import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { INote } from '../utils/note.interface';

@Injectable({
  providedIn: 'root'
})
export class NotesService {

  constructor(private http: HttpClient) {}

  getMyNotes(text?: string) {
    const textPart = text ? '?text='+text : '';
    return this.http.get<INote[]>(`${environment.url}notes${textPart}`);
  }

  create(note: INote) {
    return this.http.put<INote>(`${environment.url}notes/create`, note);
  }

  update(note: INote) {
    return this.http.post<INote>(`${environment.url}notes/update`, note);
  }

  delete(id: string) {
    return this.http.post(`${environment.url}notes/delete`, { id });
  }

  
}
