import { NgModule } from '@angular/core';
import { OccurrenceParameterComponent } from './occurrence-parameter.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared.module';
import { OccurenceParameterService } from '../../services/occurence-parameter.service';
import { OccurrenceParameterAddComponent } from './occurrence-parameter-add/occurrence-parameter-add.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';




@NgModule({
  declarations: [
    OccurrenceParameterComponent,
    OccurrenceParameterAddComponent
  ],
  imports: [
    SharedModule,
    RouterModule.forChild([
      {path: '', component: OccurrenceParameterComponent}
    ]),  
    ReactiveFormsModule,
    RouterModule,
    CommonModule,
    FormsModule
  ],
  providers: [
    OccurenceParameterService
  ]
})
export class OccurrenceParameterModule { }
