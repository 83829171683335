<div class="map-holder p-shadow-2 p-grid">
    <div class="p-col-2 map-information"><b style="font-size: 1.6rem;">{{ mapTitle }}</b>
        <div style="padding-left: 1rem; padding-top: 2rem;">
            <div>Toplam Dosya Sayısı: {{ allFileCount }}</div>
            <br>
            <div>Toplam Hizmet Sayısı: <b>{{ allServiceCount }}</b></div>
            <br>
            <div *ngFor="let item of serviceData" >&nbsp;&nbsp;&nbsp;{{ item.name }}: {{ item.count }}</div>
            <p></p>
            <!-- <div>Tedarikçiye Atanmamış Hizmet Sayısı:</div>
            <div>{{ data.service?.danger }}</div> -->

        </div>
    </div>
    <div class="p-col-10">
        <div id="map" style="height: 50vh;"></div>
    </div>
</div>