import { SelectItem } from "primeng/api";

export enum OccurrenceParameterType {
    string = 'string',
    date = 'date',
    array = 'array', 
}

export const getParameterTypeItem = () => {
    return [
              { label: 'Metin', value:'string' },
              { label: 'Tarih', value:'date' },
              { label: 'Dizi', value:'array' },             
            ] as SelectItem[];
}

export const getParameterTypeName = (parameterType: OccurrenceParameterType) => {
    switch(parameterType) {
        case 'string': return 'Metin';
        case 'array': return 'Dizi';
        case 'date': return 'Tarih';
    }
}

  