import { Directive, OnInit } from "@angular/core";
import { Dropdown } from "primeng/dropdown";

@Directive({
    selector: "p-dropdown[appFilterDropdown]",
})
export class DropdownDirective implements OnInit {
    constructor(public dropdown: Dropdown) {}

    ngOnInit(): void {
        this.dropdown.resetFilterOnHide = true;
        this.dropdown.filter = true;
        this.dropdown.emptyFilterMessage = "sonuç yok";
        this.dropdown.filterLocale = "tr-TR";
        this.dropdown.optionValue = this.dropdown.optionValue ? this.dropdown.optionValue : 'value';
        this.dropdown.optionLabel = this.dropdown.optionLabel ? this.dropdown.optionLabel : 'label';
        
    }
}
