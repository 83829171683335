import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-email-log',
  templateUrl: './email-log.component.html',
  styleUrls: ['./email-log.component.scss']
})
export class EmailLogComponent implements OnInit {
  @Input('eMaillogList') eMaillogList: any[];
  request: string;
  display: boolean = false;
  paramsDialog: boolean = false;
  paramsData: string[];


  constructor() { }
  ngOnInit(): void {
    }

    showDialog(value:string) {
      this.display = true;
      this.request=value;
    }

    paramsShowDialog(value:string[]) {
      this.paramsData = value;
      this.paramsDialog = true;
    }
  

}
