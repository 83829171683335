<p-dialog header="Notlarım" [(visible)]="openNotes" [style]="{ width: '600px', 'min-width': '480px' }"
    [maximizable]="true" [draggable]="true" [resizable]="true" [modal]="false">

    <div class="noc-top"></div>
    <div class="noc-bottom"></div>

    <div class="main-frame">

        <div class="header" (mousedown)="$event.stopPropagation()">
            <p-badge [value]="totalNotes" styleClass="mr-2" pTooltip="Toplam Not Sayısı" tooltipPosition="bottom"></p-badge>
            <span class="p-input-icon-left">
                <i class="pi pi-search" (click)="search()"></i>
                <input type="text" pInputText [(ngModel)]="searchText" class="search-area" [class.stay-long]="searchText" (keyup.enter)="search()"/>
            </span>
            <button pButton pRipple type="button" icon="pi pi-plus" tabindex="-1" label="Yeni" class="p-button-text"
                (click)="op.toggle($event)"></button>
            
            <div>
                <button pButton pRipple type="button" icon="pi pi-{{settings.smallMode ? 'table' : 'th-large'}}" class="p-button-rounded p-button-text"
                    (click)="changeSmallMode()" pTooltip="Özet/Detay Görünümü" tooltipPosition="bottom"></button>
                <button pButton pRipple type="button" icon="pi pi-{{settings.showNoteCount ? 'eye' : 'eye-slash'}}" class="p-button-rounded p-button-text"
                    (click)="changeCountDisplay()" pTooltip="Toplam Not Sayısını Göster/Gizle" tooltipPosition="bottom"></button>
            </div>

        </div>

        <div *ngFor="let note of notes" class="note">

            <div class="left-part" [style]="'background-color: '+note.color">
                <i class="pi pi-{{note.pinned ? 'star-fill' : 'star'}} star" style="cursor: pointer;" (click)="pinNote(note)"></i>
                <i class="pi pi-trash settings" (click)="deleteNote(note._id)" [class.small-mode]="settings.smallMode"></i>
            </div>
            <div class="main-part">
                <div class="title">{{note.title}}</div>
                <div class="note-container" [class.small-mode]="settings.smallMode">{{note.note}}</div>
                <div class="note-bottom" [class.small-mode]="settings.smallMode" *ngIf="!settings.smallMode">
                    <div class="tags">
                        <i *ngIf="note.tags && note.tags.length > 0" class="pi pi-tags settings" [pTooltip]="getTagsText(note.tags)" tooltipPosition="right" [class.small-mode]="settings.smallMode"></i>
                        <button *ngIf="permissions.sendNote" pButton pRipple type="button" icon="pi pi-send" class="p-button-rounded p-button-text" tabindex="-1" (click)="openSendPanel($event, note)" [disabled]="smallMode"></button>
                    </div>
                    <div class="date">
                        {{note.updateDate | date:'dd.MM.yyyy HH:mm'}}&nbsp;&nbsp;
                        <button pButton pRipple type="button" icon="pi pi-paperclip" class="p-button-rounded p-button-text" tabindex="-1" (click)="openAsSticky(note.title, note.note)" [disabled]="smallMode"></button>
                    </div>
                </div>
            </div>

        </div>


        <div *ngIf="notes && notes.length === 0" class="zero-note">
            <img src="assets/images/octopus/note-graphic.svg" height="140px" />
            {{!searchText ? 'Hiç not kalmadı..' : 'Eşleşen Not Yok..'}}
        </div>

        <div class="spacer"></div>

    </div>

    <!--[style]="{ 'background-color': '#ebeff3' }"-->

    <p-overlayPanel (onHide)="cencelNewNote()" #op>
        <span class="p-float-label note-input-field tape">
            <input id="float-input" type="text" pInputText [(ngModel)]="title" style="width: 100%;">
            <label for="float-input">Başlık</label>
        </span>
        <div *ngIf="submiting" class="form-warning">Başlık boş bırakılamaz!</div>

        <span class="p-float-label note-input-field">
            <textarea id="float-input" autoResize="autoResize" [(ngModel)]="note" pInputTextarea
                class="text-area"></textarea>
            <label for="float-input">Not</label>
        </span>

        <p-chips [(ngModel)]="tags" placeholder="Etiketler" [allowDuplicate]="false" [style]="{'margin-top': '1rem', 'display': 'block', 'max-width': '300px'}">
            <ng-template let-item pTemplate="item">
                <div class="tag-item">
                    <i class="pi pi-tag pi-fw"></i>
                    {{ item }}
                </div>
            </ng-template>
        </p-chips>

        <div class="note-form-bottom">
            <i class="pi pi-{{pinned ? 'star-fill' : 'star'}}" style="font-size: 1.5rem; padding: 6px;"
                (click)="pinned = !pinned" pTooltip="Favorilere Ekle" tooltipPosition="left"></i>
            <div class="color-picker">
                <div class="color-display-container">
                    <input type="color" class="color-display" [(ngModel)]="color">
                </div>
            </div>
            <i class="pi pi-paperclip" style="font-size: 1.5rem; padding: 6px;" [class.highlited-icon]="saveAsSticky" 
                (click)="saveAsSticky = !saveAsSticky" pTooltip="Ekrana Sabitle" tooltipPosition="right"></i>
        </div>
        
        

        <p-divider></p-divider>

        <div class="footer">
            <p-button label="İptal" styleClass="p-button-text" (click)="cencelNewNote()"></p-button>
            <p-button icon="pi pi-save" label="Notu Kaydet" styleClass="p-button-success" (click)="addNote()"></p-button>
        </div>

        <div class="noc-bottom"></div>
    </p-overlayPanel>

    <p-overlayPanel *ngIf="permissions.sendNote" #sendPanel>

        <div style="margin-bottom: 12px;"><b>{{selectedTitle}}</b></div>

        <p-multiSelect [options]="users" [(ngModel)]="selectedUsers" [style]="{'width': '200px'}" defaultLabel="Kime Gönderilsin?" selectedItemsLabel="{0} Kullanıcı Seçildi" optionValue="value" optionLabel="label"  appFilterDropdown ></p-multiSelect>

        <p-divider></p-divider>

        <div class="footer">
            <p-button label="İptal" [text]="true" (click)="selectedUsers = []; sendPanel.hide()"></p-button>
            <p-button icon="pi pi-send" label="Notu Gönder" styleClass="p-button-success" (click)="sendNote()"></p-button>
        </div>

        <div class="noc-bottom"></div>

    </p-overlayPanel>

</p-dialog>