import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { AuthService } from '../../../services/auth/auth.service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TaskSocketService extends Socket {
  constructor(userId:string) {


    super({ url: `${environment.socketUrl ?? '/'}`, options: { autoConnect: true, transports: ['websocket'] }});
    this.connect();
    this.emit('/tasks', userId);
  }

  getCalculatedTasks() {
    return this.fromEvent<any>('calculatedTask');
 }

}
