export enum AppMessageType {
    empty,
    workingPanel,
    hideWorkingPanel,
    success,
    confirm,
    warning,
    error,
    successToast,
    infoToast,
    noteToast,
    errorToast,
    resetSubmitButton,
    custom
}

export class AppMessage {
    public static empty = new AppMessage('', AppMessageType.empty);
    public header: string;
    public message: string;
    public messageType: AppMessageType;
    public sticky: boolean;
    public icon: string;
    public accept?: () => void;
    public reject?: () => void;

    constructor(message: string, messageType: AppMessageType) {
        this.message = message;
        this.messageType = messageType;
    }
}
