<div *ngIf="smsLogList && smsLogList.length> 0" class="card">
    <p-table [value]="smsLogList" dataKey="_id" [rowsPerPageOptions]="[10, 25, 50]" [paginator]="true" [rows]="10" pageLinks="7"
        [filterDelay]="500"  responsiveLayout="stack"  styleClass="oc-border p-datatable-sm"
        [customSort]="true" #dt>

        <ng-template pTemplate="header">
            <tr>
                <th>Gönderen Kullanıcı</th>
                <th>Dosya Numarası</th>
                <th>Hizmet Numarası</th>
                <th>Tarih</th>
                <th>Saat</th>
                <th>Parametreler</th>
                <th>Giden Telefon</th>
                <th>Cevap</th>
                <th>Durum</th>                

            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
            <tr>
                <td>{{(item?.user?.name ? item?.user?.name : '')+' '+(item?.user?.surname ? item?.user?.surname:
                    '')}}</td>
                <td>{{item?.fileNo}}</td>
                <td>{{item?.serviceNo}}</td>
                <td>{{item?.date | date:'dd.MM.yyyy'}}</td>
                <td>{{item?.date | date:'HH:mm'}}</td>
                <td>
                    <p-button type="button" (click)="paramsShowDialog(item?.params)">
                        {{item?.params.length}}
                    </p-button>
                </td>

                <td>{{item?.recieverPhone}}</td>
                <td>
                    <p-button type="button" (click)="showDialog(item?.response)" icon="pi pi-info-circle">
                    </p-button>
                </td>
                <td>
                    <i class="pi {{ getResponseStatus(item?.response) === 'Başarılı' ? 'pi-check mark-green' : 'pi-times mark-red' }}" >
                    </i>
                </td>

            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="8">Belirlenen filtrelere uygun operasyon bulunamadı...</td>
            </tr>
        </ng-template>
    </p-table>
</div>
<p-dialog header="Sistem Cevabı" [(visible)]="display" [modal]="true" [style]="{width: '50vw'}">
    {{response}}
</p-dialog>

<p-dialog header="Parametreler" [(visible)]="paramsDialog" [modal]="true" [style]="{width: '50vw'}">
    <p-table [value]="paramsData" dataKey="_id" [customSort]="true" #dt>

    <ng-template pTemplate="header">
        <tr>
            <th>Parametre</th>
            <th>Değer</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
        <tr>
            <td>{{item?.param}}</td>
            <td>{{item?.value}}</td>


        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="8">Belirlenen uygun parametreler bulunamadı...</td>
        </tr>
    </ng-template>
</p-table>
</p-dialog>