import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { environment } from '../../../environments/environment';
import { ISupplierStaff, SupplierStaffDto } from '../../domain/supplier-staff';
import { AppMessageService } from '../../services/common/app-message.service';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SupplierStaffDialogComponent } from './supplier-staff-dialog/supplier-staff-dialog.component';
import { AuthService } from '../../services/auth/auth.service';
import { SupplierService } from '../../services/settings/supplier.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-supplier-staff-list',
  templateUrl: './supplier-staff-list.component.html',
  styleUrls: ['./supplier-staff-list.component.scss']
})
export class SupplierStaffListComponent implements OnInit {
  @ViewChild('dialog') dialog : SupplierStaffDialogComponent;
  supplierType: any = null;
  supplierStaffList: SupplierStaffDto[] = [];
  inputText: string;
  searchElSub$: Subscription;
  private searchSub$ = new Subject<string>();
  isSupplier : string;
  suppliersLists: SelectItem[];
  @Input() selectedSupplier: string;
  supplierStaffs : ISupplierStaff[] = [];
  @Input() special: boolean = false;





  constructor(
    private http: HttpClient,
    private message: AppMessageService,
    private confirmationService: ConfirmationService,
    private auth: AuthService,
    private supplierService: SupplierService,
    private router: Router
  ) {
      this.isSupplier = this.auth.getUserSupplierId()
   }


  getSupplierStaffList() {
    let param = { 'text': this.inputText,'isActive': this.supplierType, 'supplier':this.selectedSupplier };
    this.http.post<SupplierStaffDto[]>(`${environment.url}supplierStaffs/getAll`, param).subscribe(response => {
      this.supplierStaffList = response;
    });
  }

  searchChange(event: any) {
    this.searchSub$.next(event.target.value);
  }

  openStaffDialog(staff?:SupplierStaffDto){
    this.dialog.openStaffDialog(staff);
  }

  async ngOnInit(): Promise<void> {
    this.suppliersLists = [{value: '', label: 'HEPSİ'}, ...(await this.supplierService.getSupplierItems())];
    this.getSupplierStaffList();
    this.searchElSub$ = this.searchSub$.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe((value) => {
      this.inputText = value;
      this.getSupplierStaffList()
      this.supplierStaffList = [];
    });
  }

  onNewStaffAdded(supplierStaff: ISupplierStaff){
    this.supplierStaffList.push(supplierStaff);
  }

  onStaffEdit(supplierStaff: ISupplierStaff){
    const index = this.supplierStaffList.findIndex(i=> i._id === supplierStaff._id);
    this.supplierStaffList[index]=supplierStaff;
  }

}
