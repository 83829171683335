import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { LazyLoadEvent, SelectItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { Subject, Subscription, throwError } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { IListEnvelope } from '../../domain/list.interface';
import { AppMessageService } from '../../services/common/app-message.service';
import { OccurenceParameterService } from '../../services/occurence-parameter.service';
import { IOccurrenceParameter } from './occurrence-parameter';
import { OccurrenceParameterAddComponent } from './occurrence-parameter-add/occurrence-parameter-add.component';
import { getParameterTypeItem, getParameterTypeName, OccurrenceParameterType } from './occurrence-parameter-type';
import { UserService } from '../user-management/services/user.service';

@Component({
  selector: 'app-occurrence-parameter',
  templateUrl: './occurrence-parameter.component.html',
  styleUrls: ['./occurrence-parameter.component.scss']
})
export class OccurrenceParameterComponent implements OnInit {
  @ViewChild('dt') table: Table;
  @ViewChild('parameterOperations') addComponent: OccurrenceParameterAddComponent;
  rows: IOccurrenceParameter[] = [];
  loading: boolean = false;
  totalRecords = 0;

  typeFilter: SelectItem[] = [];
  selectedType: string;

  searchText = '';
  searchElSub$: Subscription;
  private searchSub$ = new Subject<string>();

  data: any[] = []; // Verilerinizin olduğu dizi
  dropdownOptions: SelectItem[] = []; // p-dropdown için seçenekler
  selectedGroup: string; // Seçili grubun değerini tutacak

  constructor(private occurrenceParameterService: OccurenceParameterService,
    private appMsgService: AppMessageService,
    private userService:UserService) { }

  ngOnInit(): void {
    this.getAllGroups();

    this.typeFilter = [{ label: 'Hepsi', value: '' }, ...getParameterTypeItem()];

    this.searchElSub$ = this.searchSub$.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe((value) => {
      this.searchText = value;
      this.table.reset();
    });
  }

  ngOnDestroy() {
    if (this.searchElSub$) {
      this.searchElSub$.unsubscribe();
    }
  }

  getAllGroups() {
    this.occurrenceParameterService.getAllGroups().subscribe((response) => {
      this.data = response.groups;
      this.dropdownOptions = [
        { label: 'Hepsi', value: '' }, 
        ...this.data.map((groupName) => ({
          label: groupName,
          value: groupName
        }))
      ];
    });
  }
  


  loadData(event: LazyLoadEvent) {
    this.loading = true;
    this.occurrenceParameterService.getParamaters
      (event.first / event.rows + 1, event.rows, this.selectedType as string, this.searchText,  this.selectedGroup)
      .pipe(
        catchError((err) => { 
          this.appMsgService.reportError(err);
          return throwError(err);
        }),
        finalize(() => (this.loading = false))
      )
      .subscribe((response) => { //IOccurrenceParameter
        this.rows = response.rows;
        this.totalRecords = response.totalRecords;
      // this.loadUserNames()
      });
    // .subscribe((response: IListEnvelope<any>) => { //IOccurrenceParameter
    //   this.rows = response.rows;
    //   this.totalRecords = response.totalRecords;
    // });
  }


  addParameter(parameter: IOccurrenceParameter) {
    this.rows.push(parameter);
  }

  editParameter(parameter: IOccurrenceParameter) {
    const index = this.rows.findIndex(i => i._id === parameter._id);
    this.rows[index] = parameter;
  }

  parameterTypeName(type: OccurrenceParameterType) {
    return getParameterTypeName(type);
  }

  editParameterForm(parameter: IOccurrenceParameter) {
    this.addComponent.editParameterForm(parameter);
  }

  searchChange(event: any) {
    this.searchSub$.next(event.target.value);
  }

  resetFilter() {
    this.table.reset();
  }
}
