import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

export interface OctopusSearchFilters { 
  limit: number,
  openInNewPage: boolean,
  hideBackground: boolean,
  openFavorites: boolean,
  enabled: {
    services: boolean,
    suppliers: boolean,
    files: boolean,
    policies: boolean
  }
}

export interface OctopusSearchData {
  type: string,
  _id: string,
  service?: string,
  fileNo?: string,
  insuranceFileNo?: string,
  supplier?: string,
  policyNo?: string,
  mipNo?: string,
  idNumber?: string,
  idType?: string,
  experFile?: string,
  damageFile?: string,
  fileId?: string,
  owner?: string,
  externalContactNumber?: string,
  mobilePhone?: string,
}

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private http: HttpClient) {}

  search(text: string, filters: OctopusSearchFilters ) {
    return this.http.post<OctopusSearchData[]>(`${environment.url}search/${encodeURIComponent(text)}`, filters);
  }

}
