<div class="card" *ngIf="statusGroupData && statusGroupData.length > 0">


    <div class="content"> <!--[style]="{ 'grid-template-columns': 'repeat(' + statusGroupData.length + ', minmax(0, 1fr))' }"-->

        <div class="sub-container" [style]="{background: item.color}" *ngFor="let item of statusGroupData; trackBy: trackByFn">
            <button pButton pRipple type="button" icon="pi pi-external-link"
                    class="btn p-button-rounded p-button-text p-button-sm"
                    (click)="goToListByGroup(item)"></button>
            <div class="number" [style]="{'color':textColor(item.color)}"> {{ item.count }}</div>
            <div class="text" [style]="{'color':textColor(item.color)}">{{ item.name }}</div>
        </div>

    </div>
</div>


<div class="card" *ngIf="dashboardData && dashboardData.length > 0">


    <div class="content" [style]="{ 'grid-template-columns': 'repeat(' + 6 + ', minmax(0, 1fr))' }">

        <div class="sub-container" [style]="{background: item.color}" *ngFor="let item of dashboardData; trackBy: trackByFn">
            <button pButton pRipple type="button" icon="pi pi-external-link"
                    class="btn p-button-rounded p-button-text p-button-sm"
                    (click)="goToList(item)"></button>
            <div class="number" [style]="{'color':textColor(item.color)}"> {{ item.count }}</div>
            <div class="text" [style]="{'color':textColor(item.color)}">{{ item.name }}</div>
        </div>

    </div>
</div>
