import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppMessageService } from '../../../services/common/app-message.service';
import { DashboardService } from '../../../services/dashboard/dashboard.service';

@Component({
  selector: 'app-file-card',
  templateUrl: './file-card.component.html',
  styleUrls: ['./file-card.component.scss']
})
export class FileCardComponent implements OnInit, OnDestroy {
  open = 0;
  closed = 0;
  canceled=0;

  dashboard$: Subscription;

  constructor(private dashboardService: DashboardService,
    private messageService: AppMessageService) { }

  ngOnInit(): void {

    this.dashboard$ = this.dashboardService.events$.subscribe((e) => {
      if (e.event === 'response') {
        this.open = e.state.response.files.find(f => f.status === 'open')?.count ?? 0;
        this.closed = e.state.response.files.find(f => f.status === 'closed')?.count ?? 0;
        this.canceled = e.state.response.files.find(f => f.status === 'canceled')?.count ?? 0;
      }
    });

  }

  ngOnDestroy(): void {
    if (this.dashboard$) {
      this.dashboard$.unsubscribe();
    }
  }

  goToList(status: string) {
    if((status === 'open' && this.open === 0) || (status === 'closed' && this.closed === 0) || (status === 'canceled' && this.canceled === 0)) {
      this.messageService.errorToast('Kayıt Yok', 'Seçilen filtrelerle eşleşen kayıt yok');
      return;
    }
    this.dashboardService.goToFileStatusList(status);
  }
}
