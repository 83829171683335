
<div class="p-grid p-m-0">
    <div class="p-offset-1 p-col-10 p-sm-offset-3 p-sm-6 p-md-offset-4 p-md-4 p-lg-offset-4 p-lg-4">
        <div class="logo">
            <img src="assets/images/anadolu/anadolu-logo.png" alt="Octopus">
        </div>


        <p-message *ngIf="shownMessage" severity="info" text="Oturum zaman aşımı süresi doldu.Lütfen tekrar giriş yapınız."></p-message>

        <div class="card">
            <div class="title">Anadolu Asistans'a hoşgeldiniz</div>
            <div class="app-login-container">
                <form (submit)="submit()">
                    <div *ngIf="!showCodePanel; else elseBlock" class="p-grid p-fluid">
                        <div class="p-col-12">
                            {{ userNameCaption }}
                        </div>
                        <div class="p-col-12">
                            <input [(ngModel)]="email" name="email" pInputText type="text"
                                [placeholder]="userNameCaption">
                        </div>
                        <div class="p-col-6">
                            Şifre
                        </div>
                        <!-- <div class="p-col-6 password-container" *ngIf="this.appSettings.loginStrategy === 'local'">
                            <a routerLink="/forgot-password">Şifremi unuttum!</a>
                        </div> -->
                        <div class="p-col-12">
                            <input [(ngModel)]="password" name="password" pInputText type="password"
                                placeholder="Şifre" />
                        </div>
                        <div class="p-col-12 text-center validation-error" [hidden]="!errorMessage">
                            {{ "Kullanıcı Adı ya da Şifre Hatalı" }}
                        </div>

                        <div class="p-col-6">
                            <button pButton label="Giriş" icon="pi pi-check" type="submit" [disabled]="controlButtonDisable()"></button>

                        </div>

                     <div class="p-col-12">
                               <app-recaptcha  *ngIf="appSettings.captchaGuard"  (onResponse)="showCaptchaResponse($event)" #recapthca></app-recaptcha>
                        </div>
                    </div>

                    <ng-template #elseBlock>
                        <div style="margin-bottom:1rem">
                            <h4>SMS ile gönderilen kodu giriniz</h4>
                            <ng-otp-input  class="otp-input-style" #ngOtpInput  (onInputChange)="onOtpChange($event)"  [config]="{length:6,allowNumbersOnly:true}"></ng-otp-input>

                        </div>
                        <div style="display: flex; justify-content: center;margin-bottom:1rem;">
                            <octo-countdown  [counter]="59" (onCounterFinish)="timeFinish()" #countdown></octo-countdown>
                        </div>

                        <div class="p-col-12 text-center message" [style.color]="otpCodeMessage.success ? 'green':'red'" [hidden]="otpCodeMessage.success===undefined">
                            {{ otpCodeMessage.message }}
                        </div>
                        <div style="display: flex; justify-content: center">
                            <button pButton label="Giriş Yap" type="button" icon="pi pi-check" (click)="signIn()"></button>
                        </div>
                        <div style="display: flex; justify-content: flex-end">

                            <button pButton  [label]="'Yeni Kod Gönder'"
                            type="button"

                        class="p-button-text"
                        (click)="getOtpCode()"></button>
                        </div>
                    </ng-template>
                </form>

            </div>
        </div>
    </div>
</div>

