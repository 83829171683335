<p-dialog
    [(visible)]="scriptSearchDialog"
    [style]="{ width: '35vw' }"
    [maximizable]="true"
    [resizable]="true"
    [modal]="false"
    (onHide)="closeSearchDialog()"
    ><div class="noc-top"></div>
    <ng-template pTemplate="header">
        <div class="top">
            <div class="header">Scriptler</div>

            <div class="search">
                <div class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input
                        pInputText
                        type="text"
                        [(ngModel)]="filter.searchText"
                        [style]="{ 'border-radius': '18px', width: '100%' }"
                        (input)="dt2.filterGlobal($event.target.value, 'contains')"
                        placeholder="Başlıklarda Ara"
                    />
                </div>
                <div>
                    <button pButton *ngIf="isFilterSelected()"  icon="pi pi-filter-slash" class="p-button  p-button-rounded"  (click)="clearFilters()">
                    </button>
                </div>
            </div>
           
            <div class="mode">
                <button
                    pButton
                    pRipple
                    type="button"
                    icon="pi pi-{{ listMode ? 'table' : 'th-large' }}"
                    class="p-button-rounded p-button-text"
                    (click)="changeListMode()"
                    pTooltip="Özet/Detay Görünüm"
                    tooltipPosition="bottom"
                ></button>
            </div>
        </div>
    </ng-template>
    
    <p-table
        [value]="scripts"
        [lazy]="true"
        (onLazyLoad)="loadScripts($event)"
        [loading]="loading"
        pageLinks="3"
        [paginator]="true"
        [rows]="10"
        [totalRecords]="totalRecords"
        paginatorDropdownAppendTo="body"
        [rowsPerPageOptions]="[5, 10, 20]"
        [globalFilterFields]="['title', 'tags']"
        dataKey="_id"
        #dt2
    >
        <ng-template pTemplate="caption">
            <div *ngIf="tags && tags.length > 0" class="tags-container">
                <button
                    *ngFor="let tag of selectedTags"
                    (click)="toggleTag(tag)"
                    class="tag-button active"
                    
                >
                    {{ tag }}
                </button>
                <button
                    *ngFor="let tag of unselectedTags"
                    (click)="toggleTag(tag)"
                    class="tag-button"
                   
                >
                    {{ tag }}
                </button>
            </div>
            
        </ng-template>
        <ng-template pTemplate="body" let-item>
            <tr>
                <div class="script">
                    <div class="main-part">
                        <div class="top">
                            <div class="title" (click)="showScript(item)">
                                {{ item.title }}
                            </div>
                        </div>
                        <div class="bottom" [class.list-mode]="listMode">
                            <div class="tags" *ngIf="!listMode">
                                <i class="pi pi-tags"></i>
                                <button
                                    *ngFor="let tag of item.tags"
                                    pButton
                                    [label]="tag"
                                    class="p-button-link t-button"
                                    (click)="searchTag(tag)"
                                    pTooltip="Başlıklarda ara"
                                    #tagButton
                                ></button>
                            </div>

                            <div class="date">
                                {{ item?.creationDate | date : "dd.MM.yyyy" }}
                            </div>
                        </div>
                    </div>
                </div>
            </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="99">Script bulunamadı</td>
            </tr>
        </ng-template>
    </p-table>
    <div class="noc-bottom"></div>
</p-dialog>
