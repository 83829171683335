import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-download-report',
  templateUrl: './download-report.component.html',
  styleUrls: ['./download-report.component.scss']
})
export class DownloadReportComponent implements OnInit {
  reportId = '';

  constructor(private http: HttpClient,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.reportId = this.activatedRoute.snapshot.params.id;
  }

  download() {
    const url = `${environment.url}reports/${this.reportId}`;

    this.http
        .get(url, { observe: 'response', responseType: 'blob' })
        .subscribe((value) => {
            let fileName = 'report.xlsx';
            const cd = value.headers.get('Content-Disposition');

            if (cd) {
              fileName = cd.replace('attachment;filename=', '');
            }

            this.downloadFile(value.body as Blob, fileName);
        });
  }

  private downloadFile(data: Blob, filename: string) {
      const blob = data;
      const url = window.URL.createObjectURL(blob);
      const fileName = filename;
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style.display = 'none';
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
  }
}
