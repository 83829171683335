import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { OctopusSearchData, OctopusSearchFilters, SearchService } from '../../services/search.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { OverlayPanel } from 'primeng/overlaypanel';
import { AppMessageService } from '../../services/common/app-message.service';
import { AuthService } from '../../services/auth/auth.service';


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  @ViewChild('op') configPanel: OverlayPanel;
  @ViewChild('searchBar', {read: ElementRef}) searchBar: ElementRef;
  
  dialogOpen: boolean = false;
  pinnedOpen: boolean = false;
  openConfig: boolean = false;
  searchText: string = '';

  filters: OctopusSearchFilters = {
    limit: 20,
    openInNewPage: true,
    hideBackground: false,
    openFavorites: true,
    enabled: {
      files: true,
      policies: false,
      services: true,
      suppliers: false
    }
  };
  fixLimit: number = 18;
  outoFocus: boolean = true;

  isProcessing = false;
  datas: OctopusSearchData[] = [];

  pinnedItems: OctopusSearchData[] = [];
  private search$ = new Subject<string>();

  role: string = '';
  componentLoaded: boolean = false;

  constructor(private readonly searchService: SearchService, 
    private readonly mesage: AppMessageService,
    private readonly auth: AuthService) {}

  ngOnInit(): void {
    this.addCustomSearchKeybind();
    this.loadSquance();
  }

  private loadSquance() {
    if(this.componentLoaded) {
      return;
    }

    this.role = this.auth.getUserRole();
    if( this.role === 'supplier' ) {
      return;
    }

    this.loadFilters();
    this.loadPinnedItems();

    this.search$.pipe(
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe( (text) => {
      this.search(text);
    });

    this.componentLoaded = true;
  }

  ngOnDestroy() {
    if(!this.search$.closed) {
      this.search$.unsubscribe();
    }
  }

  textChanged(text: string) {
    if(this.dialogOpen) {
      this.search$.next(text);
    }
  }

  private search(text: string) {
    if(this.isProcessing) {
      return; 
    }
    if(!text) {
      this.datas = [];
      return;
    }
    this.isProcessing = true;

    this.searchService.search(text, this.filters).subscribe( res => {
      this.datas = res;
    }, (err) =>  {}, () => { this.isProcessing = false; });
  }

  private loadFilters() {
    const storage = localStorage.getItem('search_settings');
    if(!storage) {
      localStorage.setItem('search_settings', JSON.stringify(this.filters));
    } else {
      this.filters = JSON.parse(storage) as OctopusSearchFilters;
    }
  }

  private loadPinnedItems() {
    const storage = localStorage.getItem('search_spinned_items');
    if(!storage) {
      localStorage.setItem('search_spinned_items', JSON.stringify(this.pinnedItems));
    } else {
      this.pinnedItems = JSON.parse(storage) as OctopusSearchData[];
    }
  }

  private addCustomSearchKeybind() {
    window.onkeyup = (e) => {
      if(e.key === 'F4') {
        this.loadSquance();
        if(this.dialogOpen) {
          this.closeDialog();
        } else {
          this.openDialog();
        }
      }
    }
  }

  openDialog() {
    if(this.role === 'supllier') {
      return;
    }

    if(this.searchBar && this.outoFocus) {
      this.searchBar.nativeElement.focus();
    }

    this.configPanel.hide();
    this.dialogOpen = true;
    if(this.filters.openFavorites) {
      this.pinnedOpen = true;
    }
  }

  closeDialog() {
    this.pinnedOpen = false;
    this.searchText = '';
    this.datas = [];
    this.openConfig = false;
    this.configPanel.hide();
    this.dialogOpen = false;
  }

  configChanged() {
    if(this.filters.limit > 99 || this.filters.limit < 5) {
      this.mesage.errorToast('Geçersiz Limit', 'Öge Arama Limiti 5 ila 99 arasında olabilir!');
      return;
    }
    localStorage.setItem('search_settings', JSON.stringify(this.filters));
  }

  //  Pin Operations
  pinItem(item: OctopusSearchData, index: number) {
    if(this.pinnedItems.length >= this.fixLimit) {
      this.mesage.errorToast('Sabitleme Sınırına Ulaşıldı', `En fazla ${this.fixLimit} adet ögenin sabitlenmesine izin veriliyor. Bu limitin üstünde öge sabitlenemez.`);
      return;
    }
    this.pinnedItems.push(item);
    this.datas.splice(index, 1);
    localStorage.setItem('search_spinned_items', JSON.stringify(this.pinnedItems));
  }

  unpinItem(index: number) {
    console.log(index)
    this.pinnedItems.splice(index, 1);
    localStorage.setItem('search_spinned_items', JSON.stringify(this.pinnedItems));
  }

  togglePinnedPanel() {
    this.pinnedOpen = !this.pinnedOpen;
  }
  //  ----------------
}
