<div class="card">
    <div class="header">
        <div class="title">
            DOSYA
        </div>
    </div>
    <div class="content">
        <div class="sub-container indigo">
            <button pButton pRipple type="button" icon="pi pi-external-link"
                    class="btn p-button-rounded p-button-text p-button-sm"
                    (click)="goToList('open')"></button>
            <div class="number">{{ open }}</div>
            <div class="text">Devam Ediyor</div>
        </div>

        <div class="sub-container green">
            <button pButton pRipple type="button" icon="pi pi-external-link"
                    class="btn p-button-rounded p-button-text p-button-sm"
                    (click)="goToList('closed')"></button>
            <div class="number">{{ closed }}</div>
            <div class="text">Tamamlandı</div>
        </div>

        <div class="sub-container red">
            <button pButton pRipple type="button" icon="pi pi-external-link"
                    class="btn p-button-rounded p-button-text p-button-sm"
                    (click)="goToList('canceled')"></button>
            <div class="number">{{ canceled }}</div>
            <div class="text">İptal</div>
        </div>
    </div>
</div>