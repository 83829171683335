import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { AppMessageService } from '../../../services/common/app-message.service';
import { DashboardService } from '../../../services/dashboard/dashboard.service';

interface IServiceCardData {
  service: string;
  name: string;
  count: number;
  selected: boolean;
}

@Component({
  selector: 'app-service-card',
  templateUrl: './service-card.component.html',
  styleUrls: ['./service-card.component.scss']
})
export class ServiceCardComponent implements OnInit, OnDestroy {
  @Input() filterServiceTypes: string[];
  
  dashboard$: Subscription;
  services: SelectItem[] = [];
  dashboardData: IServiceCardData[] = [];
  
  constructor(private dashboardService: DashboardService,
      private messageService: AppMessageService) { }

  ngOnInit(): void {

    this.dashboard$ = this.dashboardService.events$.subscribe((e) => {
      if (e.event === 'response') {
        this.services = e.state.services;

        const selectedServiceId = this.dashboardData.find(f => f.selected)?.service;

        this.dashboardData = [];

        this.services.forEach((s, index) => {
          const count = e.state.response.services.filter(f => f.service === s.value)
                                                 .reduce((p, c) => p + c.count, 0);

          this.dashboardData.push({
            service: s.value,
            name: s.label,
            count,
            selected: selectedServiceId ? selectedServiceId === s.value : index === 0
          });          
        });

        const selected = this.dashboardData.find(f => f.selected);
        this.dashboardService.setSelectedService(selected.service);
      }
      
    });

  }

  ngOnDestroy(): void {
    if (this.dashboard$) {
      this.dashboard$.unsubscribe();
    }
  }

  trackByFn(index: number, item: IServiceCardData) {
    return item.service;
  }

  onClick(service: IServiceCardData) {
    if (!service.selected) {
      this.dashboardData.forEach(f => f.selected = false);
      service.selected = true;

      this.dashboardService.setSelectedService(service.service);
    }
  }

  goToList(service: IServiceCardData) {
    if(service.count === 0) {
      this.messageService.errorToast('Kayıt Yok', 'Seçilen filtrelerle eşleşen kayıt yok');
      return;
    }
    this.dashboardService.goToServiceList(service.service);
  }

  checkServiceType(service: string): boolean {
    if(this.filterServiceTypes && this.filterServiceTypes.length > 0) {
      const data = this.filterServiceTypes.find(f => f === service);
      return data ? true : false;
    }
    return true;
  }
}
