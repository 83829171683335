
<div class="p-grid p-m-0">
    <div class="p-offset-1 p-col-10 p-sm-offset-3 p-sm-6 p-md-offset-4 p-md-4 p-lg-offset-4 p-lg-4">
        <div class="logo">
            <img src="assets/images/anadolu/anadolu-logo.png" alt="Octopus">
        </div>
        <!-- <div class="card" *ngIf="appSettings.loginStrategy === 'local'">
            <div class="title">Şifremi Unuttum</div>
            <form [formGroup]="formEmail" autocomplete="off">
                <div class="p-grid p-fluid">
                    <div class="p-col-6">
                        <a routerLink="/login">Giriş sayfasına dön</a>
                    </div>
                    <div class="p-col-12">
                        Lütfen e-posta adresinizi giriniz.
                    </div>
                    <div class="p-col-12">
                        <input formControlName="email"
                                name="email"
                                pInputText
                                type="email"
                                placeholder="E-posta adresi">

                        <div *ngIf="emailSubmitted && formEmail.controls.email.errors">
                            <div *ngIf="formEmail.controls.email.errors.required" class="validation-error">
                                E-mail adresinizi giriniz
                            </div>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-12 p-text-center" *ngIf="emailErrorMessage">
                        <div class="validation-error">
                            {{ emailErrorMessage }}
                        </div>
                    </div>

                    <div class="p-col-12">
                        <app-recaptcha  (onResponse)="showCaptchaResponse($event)" #recapthca></app-recaptcha>
                    </div>

                    <div class="p-col-12" *ngIf="showEmailSubmitBtn">
                        <button type="button"
                                (appSubmit)="onSendEmail()"
                                icon="pi pi-check-circle"
                                label="Şifre Yenile"
                                class="p-button-success"
                                loadingText="Lütfen bekleyiniz."
                                [style]="{ 'width': '100%' }"></button>
                    </div>
                </div>
            </form>
        </div> -->
    </div>
</div>

<form [formGroup]="otpForm" autocomplete="off">
    <p-dialog [(visible)]="showTokenDialog" header="PIN" modal="true" [style]="{ 'min-width': '300px' }">
        <div class="p-grid">
            <div class="p-col-12 p-text-center p-mb-3">
                <p>Mailinize gelen pin kodunu giriniz.</p>
            </div>
        </div>

        <div class="p-grid">
            <div class="p-offset-3 p-col-6 p-text-center">
                <input  id="otpToken"
                        formControlName="otpToken"
                        pInputText type="text"
                        maxlength="6"
                        class="p-text-center"
                        [style]="{'width': '100%', 'font-weight': 'bold', 'font-size': '1.5em'}"/>

                <div *ngIf="otpSubmitted && otpForm.controls.otpToken.errors">
                    <div *ngIf="otpForm.controls.otpToken.errors.required" class="validation-error">
                      PIN kodunu giriniz
                    </div>

                    <div *ngIf="otpForm.controls.otpToken.errors.minlength" class="validation-error">
                        PIN kodu en az 6 karakter olmalıdır.
                    </div>

                    <div *ngIf="otpForm.controls.otpToken.errors.maxlength" class="validation-error">
                        PIN kodu en fazla 6 karakter olmalıdır.
                    </div>
                </div>
            </div>
        </div>

        <div class="p-grid p-mb-2">
            <div class="p-offset-4 p-col-4 p-text-center">
                <div [appCountdown]="tokenCountdown" (countdownCompleted)="onCountdownCompleted()"></div>
            </div>
        </div>


        <div class="p-grid">
            <div class="p-col-12 p-md-12 p-text-center" *ngIf="tokenErrorMessage">
                <div class="validation-error">
                    {{ tokenErrorMessage }}
                </div>
            </div>
        </div>

        <div class="p-grid p-mt-2">
            <div class="p-offset-3 p-col-6 p-text-center">
                <button type="button"
                        (appSubmit)="onSendOTPToken()"
                        icon="pi pi-check-circle"
                        label="Onayla"
                        class="p-button-success"
                        loadingText="Lütfen bekleyiniz."
                        [style]="{ 'width': '100%' }"
                        ></button>
            </div>
        </div>
    </p-dialog>
</form>

<p-confirmDialog icon="pi pi-information-triangle"></p-confirmDialog>
