import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IListEnvelope } from 'src/app/domain/list.interface';
import { environment } from 'src/environments/environment';
import { CityDto, ICity } from '../../domain/location';
import { AppCacheService } from './app-cache.service';

@Injectable({
    providedIn: 'root'
})
export class CityService {

    constructor(private http: HttpClient,
                private cache: AppCacheService) {}

    getAllCities(): Observable<IListEnvelope<ICity>> {

        const cachedItem = this.cache.get('CITIES_ALL');
        if (cachedItem) {
            return of(cachedItem);
        }

        return this.http.get<IListEnvelope<ICity>>(`${environment.url}cities?all=true`)
                        .pipe(
                            tap(t => this.cache.set('CITIES_ALL', t, 1000 * 60 * 10))
                        );
    }

    getCities(page?: number, limit?: number): Observable<IListEnvelope<ICity>> {
        return this.http.get<IListEnvelope<ICity>>(`${environment.url}cities?limit=${limit ?? ''}&page=${page ?? ''}`);
    }

    async getCityItems(): Promise<SelectItem[]> {
        return new Promise((resolve, reject) => {
            this.getAllCities()
                    .subscribe((response) => {
                        const cities = response.rows.map<SelectItem>(m => ({ value: m._id, label: m.name }));

                        resolve(cities);
                    },
                    (error) => reject(error)
                    );
        });
    }

    addCity(city: CityDto): Observable<any> {
        return this.http.post<ICity>(`${environment.url}cities/create`, city);
    }

    editCity(id: string, city: CityDto): Observable<any> {
        return this.http.put<ICity>(`${environment.url}cities/${id}`, city);
    }

    detailCity(id: string): Observable<any> {
        return this.http.get<ICity>(`${environment.url}cities/${id}`);
    }

    deleteCity(id: string): Observable<any> {
        return this.http.delete(`${environment.url}cities/${id}`);
    }

    async getCityCodeItems(): Promise<SelectItem[]> {
        return new Promise((resolve, reject) => {
            this.getAllCities()
            .subscribe(res => {
                const cities = res.rows.map<SelectItem>(m => ({ value: m.code, label: m.code }));
                resolve(cities);
            }, err => reject(err));
        });
    }

}
