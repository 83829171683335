import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard  {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authService.isLoggedIn()) {
      if (route.data.expectedRoles !== undefined && route.data.expectedRoles.length > 0) {
        const userRole = this.authService.getUserRole();
        const roles = route.data.expectedRoles as string[];

        if (roles.findIndex(f => f === userRole) === -1) {
          // user does not have necessary role, redirect to access denied page
          this.router.navigate(['/accessdenied']);
          return false;
        }
      }

      return true;
    }

    // not logged in so redirect to login page
    this.router.navigate(['/login'],{state:{shownMessage:true}});
    return false;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }
}
