import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { AppMessageType } from '../services/common/app-message';
import { AppMessageService } from '../services/common/app-message.service';
import { OctoNotificationsService } from './octo-notifications.service';
import { NotesSocketService } from '../components/self-notes/services/notes.socket.service';

@Component({
  selector: 'app-octo-notifications',
  templateUrl: './octo-notifications.component.html',
  styleUrls: ['./octo-notifications.component.scss']
})
export class OctoNotificationsComponent implements OnInit {

  boop: HTMLAudioElement;
  value: number = 0;

  constructor(private service: OctoNotificationsService, private messageService: AppMessageService, private ms: MessageService, private noteSocketService: NotesSocketService) {}
  
  ngOnInit(): void {

    this.boop = new Audio('assets/sounds/Boop.mp3');
    this.boop.preload = 'auto';

    this.service.getNotifications().subscribe(msg => {
      this.messageService.notification(msg);
      this.value++;
    });

    this.messageService.eventSource.subscribe(event => {
      if(event.messageType === AppMessageType.custom) {

        this.playSound();

        this.ms.clear('customToast');
        this.ms.add({key: 'customToast', icon: event.icon, severity: 'custom', summary: event.header, detail: event.message });
      }
    });

    this.noteSocketService.getNoteRecieved().subscribe( msg => {
      const detail = (msg.owner.name ? msg.owner.name+' ' : '')+(msg.owner.surname ? msg.owner.surname : '')+' tarafından not gönderildi.';
      this.playSound();
      this.ms.add({key: 'noteWatcherToast', icon: 'pi-paperclip', severity: 'custom', summary: msg.title, detail: detail, life: 10000, data: { color: msg.color}});
    });
  }

  private playSound() {
    if(this.boop) {
      this.boop.pause();
      this.boop.currentTime = 0;
      this.boop.play();
    } else {
        console.error('Ses yüklenemedi!');
    }
  }

}
