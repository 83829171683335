
<p-toolbar>
    <div class="p-toolbar-group-left">
        <span class="p-buttonset">
            <button pButton pRipple label="Günlük"
                class="p-button-outlined"
                [ngClass]="{ 'p-button-raised': this.filter.dateType === 'daily' }"
                (click)="dateFilterChanged('daily')"></button>
            <button pButton pRipple label="Haftalık"
                class="p-button-outlined"
                [ngClass]="{ 'p-button-raised': this.filter.dateType === 'weekly' }"
                (click)="dateFilterChanged('weekly')"></button>
            <button pButton pRipple label="Aylık"
                class="p-button-outlined"
                [ngClass]="{ 'p-button-raised': this.filter.dateType === 'monthly' }"
                (click)="dateFilterChanged('monthly')"></button>

            <button pButton pRipple label="Tarih Aralığı"
                    class="p-button-outlined"
                    [ngClass]="{ 'p-button-raised': this.filter.dateType === 'selection' }"
                    (click)="dateFilterChanged('selection')"
                    ></button>
        </span>

        <span style="margin-left: 6px" *ngIf="datesText"> Tarih aralığı: {{ datesText }}</span>

        <p-calendar #calendar
                [(ngModel)]="dates"
                selectionMode="range"
                [readonlyInput]="true"
                dateFormat="dd.MM.yyyy"
                [numberOfMonths]="2"
                (onSelect)="onDatesSelected($event)">
            <p-footer>
                <div class="p-grid" style="border-top: solid 1px #e0e0e0; padding-top:6px">
                    <div class="p-col-4">
                        <button pButton pRipple type="button" label="Tamam"
                                (click)="onDatesConfirm()"
                                class="p-button-outlined"></button>
                        <button pButton pRipple type="button" label="İptal"
                                (click)="onCancelCalendar()"
                                class="p-button-text p-button-danger" style="margin-left: 6px;"></button>
                    </div>
                    <div class="p-col-8 calendarDateText" *ngIf="datesSelectionText"> Tarih aralığı: {{ datesSelectionText }}</div>
                </div>
            </p-footer>
        </p-calendar>
    </div>
    <div class="p-toolbar-group-right">
        <label for="">{{assigned ===true ?  'Tedarikçi Atanmış Hizmetler' : (assigned === false) ? 'Tedarikçi Atanmamış Hizmetler' : 'Tüm Hizmetler'}}</label>
        <p-triStateCheckbox class="p-ml-2 p-mr-2" [(ngModel)]="assigned"
        (onChange)="assignedChange()"></p-triStateCheckbox>
        <!-- [disabled]="this.filter.supplierList.length >= 1" -->

        <p-multiSelect [options]="supplierList" [(ngModel)]="this.filter.supplierList" optionValue="value" optionLabel="label" appFilterDropdown
            (onChange)="resetTable()" placeholder="[Tüm Tedarikçiler]" [style]="{ width: '170px' }"   [disabled]="assigned=== false" ></p-multiSelect>

        <p-multiSelect [options]="serviceTypeList" [(ngModel)]="this.filter.serviceTypes" optionValue="value" optionLabel="label" appFilterDropdown
            (onChange)="resetTable()" placeholder="[Tüm Hizmetler]"></p-multiSelect>

        <p-dropdown [options]="customerList" [(ngModel)]="this.filter.customer" (onChange)="resetTable()"
            [style]="{ width: '250px' }" [showClear]="true" appFilterDropdown placeholder="[Tüm Müşteriler]"></p-dropdown>
        <p-dropdown [options]="cityList" [style]="{ width: '250px' }" (onChange)="cityChange()" [(ngModel)]="city" placeholder="[Tüm İller]"
            [showClear]="true" appFilterDropdown></p-dropdown>
    </div>
</p-toolbar>
<div class="card" style="background-color: #F1F5F9;">
    <div class="card-container" style="margin-bottom: 16px;">
        <app-file-card style="grid-column: 1;"></app-file-card>

        <app-service-card [filterServiceTypes]="this.filter.serviceTypes" style="grid-column: span 2;"></app-service-card>

        <app-durumlar-card style="grid-column: span 3;"></app-durumlar-card>
    </div>

    <app-map *ngIf="allowMap"></app-map>
</div>
