import { Injectable } from '@angular/core';
import { Observable, EMPTY, Subject } from 'rxjs';
import { AppMessage, AppMessageType } from './app-message';
import { UtilsService } from './utils.service';

export interface IConfirm {
  header?: string;
  message: string;
  accept?: () => void;
  reject?: () => void;
}

@Injectable({
  providedIn: 'root'
})
export class AppMessageService {
  private _eventSource = new Subject<AppMessage>();
  public eventSource = this._eventSource.asObservable();

  constructor(private utilsService: UtilsService) {
  }

  workingPanel(message: string) {
    this._eventSource.next(new AppMessage(message, AppMessageType.workingPanel));
  }

  success(message: string) {
    this._eventSource.next(new AppMessage(message, AppMessageType.success));
  }

  warning(message: string) {
    this._eventSource.next(new AppMessage(message, AppMessageType.warning));
  }

  confirm(confirmParams: IConfirm) {
    const wmessage = new AppMessage(confirmParams.message, AppMessageType.confirm);
    wmessage.header = confirmParams.header;
    wmessage.accept = confirmParams.accept;
    wmessage.reject = confirmParams.reject;

    this._eventSource.next(wmessage);
  }

  hideWorkingPanel() {
    const message = new AppMessage('', AppMessageType.hideWorkingPanel);
    this._eventSource.next(message);
  }

  successToast(header: string, message: string) {
    const event = new AppMessage(message, AppMessageType.successToast);
    event.header = header;
    this._eventSource.next(event);
  }

  infoToast(header: string, message: string) {
    const event = new AppMessage(message, AppMessageType.infoToast);
    event.header = header;
    this._eventSource.next(event);
  }
  errorToast(header: string, message: string) {
    const event = new AppMessage(message, AppMessageType.errorToast);
    event.header = header;
    this._eventSource.next(event);
  }

  resetSubmitButton() {
    const event = new AppMessage('', AppMessageType.resetSubmitButton);
    this._eventSource.next(event);
  }

  reportError(error: any, toast: boolean = false): Observable<any> {

    let message = this.utilsService.getErrorMessage(error);

    if (toast) {
      if (message && message.length > 100) {
        message = message.substring(0, 100);
      }
      const event = new AppMessage(message, AppMessageType.errorToast);
      event.header = 'Hata oluştu';
      this._eventSource.next(event);
    } else {
      this._eventSource.next(new AppMessage(message, AppMessageType.error));
    }
    return EMPTY;
  }

  notification(message?: string) {
    const event = new AppMessage('', AppMessageType.custom);
    event.icon = 'pi-bell';
    event.message = message ? message : '';
    this._eventSource.next(event);
  }

  stickyNote(title: string, message: string) {
    const event = new AppMessage(message, AppMessageType.noteToast);
    event.header = title;
    event.sticky = true;
    this._eventSource.next(event);
  }

}
