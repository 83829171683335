import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { IUserGuide } from 'src/app/pages/operator/user-guides/interface/userguide.interface';
import { UserGuidesService } from 'src/app/services/operator/user-guides.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { UserGuidesSharedService } from 'src/app/services/operator/user-guides-shared.service';

@Component({
  selector: 'app-user-guide',
  templateUrl: './app-user-guide.component.html',
  styleUrls: ['./app-user-guide.component.scss']
})
export class AppUserGuideComponent implements OnInit {

  private userGuides: IUserGuide[] = [];
  private guidesLoaded: boolean = false;
  currentGuide: IUserGuide | null = null;
  sanitizedContent: SafeHtml | null = null;
  displayDialog: boolean = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private userGuidesSharedService: UserGuidesSharedService,
    private userGuidesService: UserGuidesService,
  ) {}

  ngOnInit(): void {
    // Fetching user guides on init for the first time
    this.loadUserGuides();

    // Updates userGuides when userGuides table updates
    this.getGuidesWhenUpdated();

    // Updates currentGuide on route change
    this.onRouteChange();
  }

  private onRouteChange() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      const currentPath = this.getCurrentPath();
      this.assignCurrentGuide(currentPath);
    });
  }

  private getGuidesWhenUpdated() {
    this.userGuidesSharedService.userGuides$.subscribe(guides => {
      const currentPath = this.getCurrentPath();
      this.userGuides = guides;
      this.assignCurrentGuide(currentPath);
    });
  }

  private loadUserGuides() {
    if (!this.guidesLoaded) {
      this.userGuidesService.getAllUserGuides().subscribe(
        res => {
          const currentPath = this.getCurrentPath();
          this.userGuides = res;
          this.guidesLoaded = true;
          this.assignCurrentGuide(currentPath);
        }
      );
    }
  }

  private getCurrentPath(): string {
    let path = '';
    let route = this.activatedRoute.root;
  
    while (route.firstChild) {
      route = route.firstChild;
      const routePath = route.snapshot.url.map(segment => segment.path).join('/');
      if (routePath) {
        path += `/${routePath}`;
      }
    }
  
    // If the constructed path is empty, it's the root path "/"
    return path || '/';
  }
  

  private assignCurrentGuide(path: string): void {
    this.currentGuide = this.userGuides.find(guide => {
      // Match root path explicitly with "/"
      if ((guide.pageName === '/' && (path === '/' || path === '/operator')) || (guide.pageName === '/operator/file/' && path.includes('/operator/file/'))) {
        return guide.isActive !== false;
      }
      // Match other paths exactly
      return path === guide.pageName && guide.isActive !== false;
    }) || null;
  
    if (this.currentGuide) {
      this.sanitizedContent = this.sanitizer.bypassSecurityTrustHtml(this.currentGuide.content);
    } else {
      this.sanitizedContent = null;
    }
  }

  showDialog(): void {
    this.displayDialog = true;
  }
}
