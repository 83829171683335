<div class="search-holder" *ngIf="role && role !== 'supplier'">
    <dialog class="dialog p-shadow-6" [class.slide]="dialogOpen" open>
        <div class="search-bar-container">
            <i class="pi pi-spin pi-spinner waiting-icon" [class.hide]="!isProcessing"></i>
            <input type="text" id="inputElement" class="search-bar" [(ngModel)]="searchText" [tabindex]="dialogOpen ? 1 : -1" (input)="textChanged($event.target.value)" placeholder="Ara" #searchBar>
        </div>
        
        <div class="brand-text">powered by İdeabilisim</div>
        
        <div class="list-container" [class.slow-growth]="datas.length > 0">
            
            <div *ngFor="let item of datas; let index = index" class="item">
                <a [routerLink]="item.type === 'supplier' ? ['/settings', 'suppliers', item._id] : ['/operator', 'file', item.fileId]" [target]="filters.openInNewPage ? '_blank' : undefined">
                    <div class="item-link"  pTooltip="Sayfaya Git" (click)="filters.openInNewPage ? (undefined) : closeDialog()">
    
                        <i *ngIf="item.type === 'file'" class="pi pi-folder item-icon"></i>
                        <i *ngIf="item.type === 'service'" class="pi pi-file item-icon"></i>
                        <i *ngIf="item.type === 'supplier'" class="pi pi-car item-icon"></i>
                        <i *ngIf="item.type === 'policy'" class="pi pi-inbox item-icon"></i>
        
                    </div>
                </a>
                
    
                <div><b>{{item.service}}{{item.fileCode}}{{item.supplier}}{{item.policyNo}}</b></div>
                <div *ngIf="item.insuranceFileNo" pTooltip="Sigorta Firması Dosya No">{{item.insuranceFileNo}}</div>
                <div *ngIf="item.mipNo" pTooltip="Mip Numarası">{{item.mipNo}}</div>
                <div *ngIf="item.idNumber" pTooltip="Kimlik Numarası">{{item.idNumber}}</div>
                <div *ngIf="item.idType" pTooltip="Kimlik Tipi">{{item.idType}}</div>
                <div *ngIf="item.experFile" pTooltip="Oto Analiz Dosya Numarası">{{item.experFile}}</div>
                <div *ngIf="item.damageFile" pTooltip="RH Hasar Dosya Numarası">{{item.damageFile}}</div>
                <div *ngIf="item.owner" pTooltip="Sigortalı İsmi">{{item.owner}}</div>
                <div *ngIf="item.externalContactNumber" pTooltip="Müşteri Numarası">{{item.externalContactNumber}}</div>
                <div *ngIf="item.mobilePhone" pTooltip="Telefon Numarası">{{item.mobilePhone}}</div>
    
                <div class="pin">
                    <p-button icon="pi pi-star" pTooltip="Favorilere Ekle" [text]="true" size="small" (onClick)="pinItem(item, index)"></p-button>
                </div>
    
            </div>
        
        </div>
    
        <div class="side-section">
            
            <p-button icon="pi pi-cog" pTooltip="Ayarlar Menüsü" tabindex="-1" [severity]="openConfig ? 'info' : 'primary'" styleClass="p-button-sm" tooltipPosition="top" (onClick)="openConfig = !openConfig; op.toggle($event)"></p-button>
            <p-button icon="pi pi-times" pTooltip="Kapat" tabindex="-1" styleClass="p-button-sm p-button-danger" tooltipPosition="top" (onClick)="closeDialog()"></p-button>
            
            <p-overlayPanel [dismissable]="false" #op>
                <div class="congif-panel">
                    <div class="config-field">
                        <div><b>
                            <i class="pi pi-file"></i>
                            &nbsp;Hizmet Araması</b>
                            <div style="color: gray">
                                Hizmetlerde arama yapar (Hizmet No - Mip No - Hasar Dosya No - Exper Dosya No)
                            </div>
                        </div>
                        <p-inputSwitch [(ngModel)]="filters.enabled.services" [style]="{display: 'flex'}" (onChange)="configChanged()"></p-inputSwitch>
                    </div>
                    <div class="config-field">
                        <div><b>
                            <i class="pi pi-folder"></i>
                            &nbsp;Dosya Araması</b>
                            <div style="color: gray">
                                Dosyalarda arama yapar (Dosya No - Sigorta Firması Dosya No)
                            </div>
                        </div>
                        <p-inputSwitch [(ngModel)]="filters.enabled.files" [style]="{display: 'flex'}" (onChange)="configChanged()"></p-inputSwitch>
                    </div>
                    <div class="config-field">
                        <div><b>
                            <i class="pi pi-inbox"></i>
                            &nbsp;Poliçe Araması</b>
                            <div style="color: gray">
                                Sisteme kayıtlı poliçelerde arama yapar (Poliçe No - Kimlik No, Sigortalı Adı - Sigortalı Soyadı, Sigortalı Telefon, Müşteri No)
                            </div>
                        </div>
                        <p-inputSwitch [(ngModel)]="filters.enabled.policies" [style]="{display: 'flex'}" (onChange)="configChanged()"></p-inputSwitch>
                    </div>
                    <div class="config-field">
                        <div><b>
                            <i class="pi pi-car"></i>
                            &nbsp;Tedarikçi Araması</b>
                            <div style="color: gray">
                                Tedarikçilerde arama yapar (Tedarikçi Adı)
                            </div>
                        </div>
                        <p-inputSwitch [(ngModel)]="filters.enabled.suppliers" [style]="{display: 'flex'}" (onChange)="configChanged()"></p-inputSwitch>
                    </div>
                    <div class="config-field">
                        <div><b>Öge Arama Limiti</b>
                            <div style="color: gray">
                                Aramaya açık olan her bir ögeden en fazla kaç adet getirileceğini belirler.
                            </div>
                        </div>
                        <input pInputText type="number" [style]="{width: '50px'}" [(ngModel)]="filters.limit" min="5" max="99" (ngModelChange)="configChanged()"/>
                    </div>
                    <div class="config-field">
                        <div><b>Hızlı Arama Erişimi</b>
                            <div style="color: gray">
                                Basıldığında arama çubuğuna hızlı erişimi sağlar.
                            </div>
                        </div>
                        <input pInputText type="text" [style]="{width: '50px'}" value="F4" disabled />
                    </div>
                    <div class="config-field">
                        <div><b>Öge Sabitleme Limiti</b>
                            <div style="color: gray">
                                Kaç adet ögenin sabitlenebileceğini belirler.
                            </div>
                        </div>
                        <input pInputText type="number" [style]="{width: '50px'}" [(ngModel)]="fixLimit" disabled />
                    </div>
                    <div class="config-field">
                        <div><b>
                            &nbsp;Yeni Sayfada Açma</b>
                            <div style="color: gray">
                                "Sayfaya Git" aksiyonunun, yeni sayfaya yönlendirmesini sağlar.
                            </div>
                        </div>
                        <p-inputSwitch [(ngModel)]="filters.openInNewPage" [style]="{display: 'flex'}" (onChange)="configChanged()"></p-inputSwitch>
                    </div>
                    <div class="config-field">
                        <div><b>
                            <i class="pi pi-pencil"></i>
                            &nbsp;Otomatik Fokuslanma</b>
                            <div style="color: gray">
                                Arama modülüne erişildiğinde, arama barının otomatik olarak fokusu üzerine çekmesini sağlar.
                            </div>
                        </div>
                        <i class="pi pi-check-circle exists-icon"></i>
                    </div>
                    <div class="config-field">
                        <div><b>
                            &nbsp;Arkaplan Karartması</b>
                            <div style="color: gray">

                            </div>
                        </div>
                        <p-inputSwitch [(ngModel)]="filters.hideBackground" [style]="{display: 'flex'}" (onChange)="configChanged()"></p-inputSwitch>
                    </div>
                    <div class="config-field">
                        <div><b>
                            &nbsp;Favoriler Otomatik Açık Gelsin</b>
                            <div style="color: gray">
                                
                            </div>
                        </div>
                        <p-inputSwitch [(ngModel)]="filters.openFavorites" [style]="{display: 'flex'}" (onChange)="configChanged()"></p-inputSwitch>
                    </div>
                </div>
            </p-overlayPanel>
    
        </div>
    
        <div class="pinned-panel p-shadow-6">
            <div class="pinned-panel-control" [class.open]="pinnedOpen">
                <b>Favoriler</b>
                <p-button [icon]="'pi pi-'+(pinnedOpen ? 'angle-up' : 'angle-down')" tabindex="-1" [badge]="pinnedItems.length" [text]="true" size="small" label=" " (onClick)="togglePinnedPanel()"></p-button>
            </div>
    
            <div class="list-container" [class.slow-growth]="pinnedOpen">
                <div *ngFor="let item of pinnedItems; let index = index" class="item">
                    <a [routerLink]="item.type === 'supplier' ? ['/settings', 'suppliers', item._id] : ['/operator', 'file', item.fileId]" [target]="filters.openInNewPage ? '_blank' : undefined">
                        <div class="item-link"  pTooltip="Sayfaya Git" (click)="filters.openInNewPage ? (undefined) : closeDialog()">
        
                            <i *ngIf="item.type === 'file'" class="pi pi-folder item-icon"></i>
                            <i *ngIf="item.type === 'service'" class="pi pi-file item-icon"></i>
                            <i *ngIf="item.type === 'supplier'" class="pi pi-car item-icon"></i>
                            <i *ngIf="item.type === 'policy'" class="pi pi-inbox item-icon"></i>
            
                        </div>
                    </a>
                    
        
                    <div><b>{{item.service}}{{item.fileCode}}{{item.supplier}}{{item.policyNo}}</b></div>
        
                    <div class="pinned">
                        <p-button icon="pi pi-star-fill" pTooltip="Favorilerden Kaldır" [text]="true" size="small" (onClick)="unpinItem(index)"></p-button>
                    </div>
        
                </div>
            </div>
        </div>
    
    </dialog>
</div>

<div class="background-hider" [class.hide-active]="filters.hideBackground && dialogOpen"></div>