import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DashboardService } from '../../../services/dashboard/dashboard.service';

declare var jsVectorMap: any;


@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, OnDestroy {
  map: any;
  cityData: { city: string, count: number,  name: string } [] = [];
  serviceData: { service: string, count: number,  name: string } [] = [];
  mapData: any = {};

  dashboard$: Subscription;

  mapTitle = 'TÜRKİYE';

  allFileCount = 0;
  allServiceCount = 0;

  constructor(private dashboardService: DashboardService) { }

  ngOnInit(): void {

    this.dashboard$ = this.dashboardService.events$.subscribe(e => {

      if (e.event !== 'response') {
        return;
      }


      // Veri içinde gelen iller
      const cities = e.state.response.services.map(m => m.city)
                                              .filter((c, index, arr) => arr.indexOf(c) === index);

      // her ilin toplamları hazırlanıyor
      this.cityData = [];
      cities.forEach((city) => {
        const count = e.state.response.services.filter(f => f.city === city)
                                               .reduce((p, curr) => p + curr.count, 0)

        const cityItem = e.state.cities.find(f => f._id === city);

        this.cityData.push({ city: cityItem.code, name: cityItem.name, count });
      });

      // seçilen il varsa yada yoksa harita başlığı ayarlanıyor
      if (e.state.filter.cities?.length > 0) {
        const selectedCity = e.state.filter.cities[0];

        const name = e.state.cities.find(f => f.code === selectedCity)?.name;

        if (name) {
          this.mapTitle = name;
        }
      } else {
        this.mapTitle = 'TÜRKİYE';
      }

      this.allFileCount = e.state.response.files.reduce((p, c) => p + c.count, 0);
      this.allServiceCount = e.state.response.services.reduce((p, c) => p + c.count, 0);
      
      // servis adları alınıyor
      this.serviceData = [];
      e.state.services.forEach((service) => {
        const count = e.state.response.services.filter(f => f.service === service.value)
                                                .reduce((p, c) => p + c.count, 0);
                     
        this.serviceData.push({ service: service.value, count, name: service.label });
      });

      // harita verisi
      this.mapData = {};
      this.cityData.forEach(city => this.mapData[city.city] = city.count);

      setTimeout(() => this.updateMap(), 200);      
    });
  }

  updateMap() {
    try {

      if (this.map) {
        this.map.series.regions[0].clear();
        if (Object.keys(this.mapData).length > 0) {
          this.map.series.regions[0].setValues(this.mapData);
        }
        return;
      }


      this.map = new jsVectorMap({
        selector: '#map',
        map: 'turkey_1_mill_en',
        backgroundColor: '#E4F1E900',
        regionsSelectable: true,
        regionsSelectableOne: true,
        regionStyle: {
          selected: {
            stroke: 'black',
            "stroke-width": 2,
            fill: '#005D78'
          },
          hover: {
            stroke: '#535453',
            "stroke-width": 2,
            fill:'#7FB3C8'
          }
        },
        onRegionSelected: (code) => {
          this.dashboardService.setSelectedCity(code); 

          if (this.dashboardService.state.selectedCityCode === '') {
            this.map.clearSelectedRegions();
          }
        },
        zoomButtons: false,
        zoomOnScroll: false,
        onRegionTooltipShow: (tooltip, index) => {
          //tooltip.css({ backgroundColor: '#FFFFFFCC', color: '#000000', border: '1px solid #000000CC' }).text(tooltip.text() );//+ ' (Toplam Dosya: '+this.dataOfMap[index-1]['count']+')');
        },
        series: {
          regions: [{
            values: this.mapData,
            scale: ['#ffffff', '#ff0000'],
            normalizeFunction: 'polynomial'
          }]
        },      
        });
    } catch (e) {
      console.log(e);
    }
  }

  ngOnDestroy(): void {
      if (this.dashboard$) {
        this.dashboard$.unsubscribe();
      }
  }
}
