import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { AuthService } from '../../../services/auth/auth.service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotesSocketService extends Socket {
  constructor(private auth: AuthService) {
    super({ url: `${environment.socketUrl ?? '/'}`, options: { autoConnect: true, transports: ['websocket'] }});
    this.connect();
    this.emit('subscribe-notes', this.auth.getUserId());
  }

  getNoteRecieved() {
    return this.fromEvent<any>('note-recieved');
 }

  addWatcher(note: string, users: string[]) {
    this.emit('note-send', {note, users});
  }

}
