import { FormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';
import { OccurenceParameterService } from '../occurence-parameter.service';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
    constructor(private parameters: OccurenceParameterService) {
    }

    public logInvalidControls(form: FormGroup) {
        Object.keys(form.controls).forEach(key => {
            if (form.controls[key].invalid) {
                const errors = Object.keys(form.controls[key].errors).reduce((p, c) => {
                    return p + ' ' + c;
                }, '');
            }
        });
    }

    calendarLocale(locale: 'tr') {
        switch (locale) {
            case 'tr': return {
                firstDayOfWeek: 1,
                dayNames: ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'],
                dayNamesShort: ['Paz', 'Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt'],
                dayNamesMin: ['Pa', 'Pt', 'Sa', 'Ça', 'Pe', 'Cu', 'Ct'],
                monthNames: [ 'Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran',
                                'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık' ],
                monthNamesShort: [ 'Oca', 'Şub', 'Mar', 'Nis', 'May', 'Haz', 'Tem', 'Ağu', 'Eyl', 'Eki', 'Kas', 'Ara' ],
                today: 'Bugün',
                clear: 'Temizle',
                dateFormat: 'dd.mm.yyyy',
                weekHeader: 'Ha'
            };

            default: return this.calendarLocale('tr');
        }
    }

    getErrorMessage(error: any) {
        let message = 'Bilinmeyen hata';
        if (error instanceof HttpErrorResponse) {
          if (error.error && error.error.message && Array.isArray(error.error.message)) {
            return error.error.message.join('. ');
          }
          if(error.error && error.error.message && error.error.message.message){
            return error.error.message.message;
          }
          if (error.error && error.error.message) {
            return error.error.message;
          }
          if (error.error && error.error.error && error.error.error.message) {
            return error.error.error.message;
          }
        }

        if (typeof(error) === 'string') {
          message = error;
        } else if (error.error !== undefined && error.error.StatusCode !== undefined) {
          if (error.error.status === 401 || error.error.StatusCode !== 401) {
            message = 'İşlem için gereken yetki bulunamadı';
          }

          if (error.error.StatusCode === 500 || error.error.StatusCode === 400) {
            message = error.error.Message;
          } else {
            message = error.error;
          }
        } else if (error.error) {
          let err = error.error;

          while (err.InnerException) {
              err = err.InnerException;
          }

          if (err.ExceptionMessage) {
            message = err.ExceptionMessage;
          } else if (error.error.ExceptionMessage) {
            message = error.error.ExceptionMessage;
          }

        } else if (error.Message !== undefined && error.ExceptionMessage !== null) {
          message = error.Message + ' : ' + error.ExceptionMessage;
        } else if (error.Message !== undefined && error.StatusCode && error.StatusCode === 400) {
          message = error.Message + ' : ' + error.ExceptionMessage;
        }

        return message;
    }

    getListFromEnum(enumToConvert: any) {
        return Object.keys(enumToConvert)
                      .filter((item) => enumToConvert[item] !== '')
                      .map<SelectItem>((item) => ({
                              label: enumToConvert[item],
                              value: item
                            }));
    }
    getServiceSupplierTypeText(type){
      switch(type) {
        case '0': return 'Anlaşmalı';
        case '1': return 'Manuel Anlaşmalı';
        case '2':return 'Anlaşmasız';
        default: return '';
      }
    }
    /**
     * Date değişkeni dd.MM.yyyy formatında texte dönüştürür
     * @param date tarih bilgisi
     */
    formatDate(date: Date, separator = '.') {

      if (!date) {
        return '';
      }

      if (typeof date === 'string') {
        date = new Date(date);
      }

      let month = (date.getMonth() + 1).toString();
      let day = date.getDate().toString();
      const year = date.getFullYear().toString();
      if (month.length < 2) {
        month = '0' + month;
      }
      if (day.length < 2) {
        day = '0' + day;
      }

      return [day, month, year].join(separator);
    }


     /**
     * Date değişkeni dd.MM.yyyy HH:mm formatında texte dönüştürür
     * @param date tarih bilgisi
     */
     formatDateTime(date: Date) {

          if (!date) {
            return '';
          }

          if (typeof date === 'string') {
            date = new Date(date);
          }

          let month = (date.getMonth() + 1).toString();
          let day = date.getDate().toString();
          const year = date.getFullYear().toString();
          if (month.length < 2) {
            month = '0' + month;
          }
          if (day.length < 2) {
            day = '0' + day;
          }

          let hour = date.getHours().toString();
          let minute = date.getMinutes().toString();

          if (hour.length < 2) {
            hour = '0' + hour;
          }
          if (minute.length < 2) {
            minute = '0' + minute;
          }

          const datePart = [day, month, year].join('.');

          return `${datePart} ${hour}:${minute}`;
        }

    /**
     * İçinde bulunulan ayın ilk gününü getirir yada
     * month parametresinde belirtilen ayın ilk gününü getirir
     * ex: 01.03.2020
     */
    getBeginingOfMonth(month?: number) {
        const today = new Date();
        if (!month) {
            month = today.getMonth();
        }
        return new Date(Date.UTC(today.getFullYear(), month, 1));
    }

 /**
  sistemin belirtilen ay önceki tarihini döner
  */
   getMonthAgo(month:number){
    let date = new Date();
    date.setMonth(new Date().getMonth() - month);
    return date;
   }

    /**
     * İçinde bulunulan yılık ilk gününü getirir yada
     * month parametresinde belirtilen ayın ilk gününü getirir
     * ex: 01.01.2020
     */
    getBeginingOfYear(year?: number) {
        const today = new Date();
        if (!year) {
            year = today.getFullYear();
        }
        return new Date(Date.UTC(year, 0, 1));
    }

    /**
     * verilen tarihe kadar kalan süreyi 00:00:00 formatında döndürür
     * @param date: bu tarih / saate kadar kalan süre
     */
    getTimeTo(date: Date) {
        const now = new Date();

        if (!date || date < now) {
            return '00:00:00';
        }

        // ms
        const diff = Math.abs(date.valueOf() - now.valueOf());
        let seconds = Math.floor(diff / 1000);
        let minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);

        minutes = minutes % 60;
        seconds = seconds % 60;

        return `${hours < 10 ? '0' + hours :  + hours}:
                ${minutes < 10 ? '0' + minutes :  + minutes}:
                ${seconds < 10 ? '0' + seconds :  + seconds}`;
    }

    /**
     * Verilen tarih-saat içinden saat tarih kısmını almak için
     * @param date tarih
     */
    getDateOnly(date: Date) {
      return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    }

  private getFirst(source: string, numberOfChars: number) {
    if (numberOfChars >= source.length) {
        return source;
    }

    return source.substring(0, numberOfChars);
  }

  private getLast(source: string, numberOfChars: number) {
      if (numberOfChars >= source.length) {
          return source;
      }

      return source.substring(source.length - numberOfChars);
  }

  /**
   * Mobil telefon numarasını formatlayıp alan kodu ve telefon no olarak iki parça halinde
   * döndürür
   * @param source telefon numarası "(507) 235-8100" formatında
   * @returns alan kodu + telefon no arrray ["507", "2358100"]
   */
  toMobilePhone(source: string) {
      const parsedPhoneNumber: [string, string] = ['', ''];
      let mobilePhone = (source ?? '').replace('(', '').replace(')', '').replace('-', '')
          .replace(' ', '').replace('+', ''); // "(507) 235-8100"
      if (mobilePhone.length < 10) {
          return parsedPhoneNumber;
      }

      mobilePhone = this.getLast(mobilePhone, 10);
      const areaCode = this.getFirst(mobilePhone, 3);
      mobilePhone = this.getLast(mobilePhone, 7);

      return [areaCode, mobilePhone];
  }

  /**
   *  verilen string değişkenin başından belirtilen karakterleri çıkartır.
   */
  trimStart(str: string, c: string): string {
      if (!str) {
        return '';
      }

      if (str.length === 0) {
        return '';
      }

      let start = 0;
      while (str[start] === c) {
          start++;
      }
      return str.substr(start);
  }


  /**
   *  Verilen string değerindeki harf, rakam ve boşluk karakteri haricindeki tüm karakterleri (newline, tab da dahil) temizler.
   * @param str temizlenecek string
   */
  public clearText(str: string): string {
    return str.replace(/[^a-zA-Z0-9' 'wığüşöçĞÜŞÖÇİ@\._\-]+/g, "");
  }


  public async getFilterDates(date: Date): Promise<[Date, Date]> {

    const calculateDates = (monthsApart: number): [Date, Date] => {
        const currentDate = new Date(date);
        const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
        const startDate = new Date(endDate.getFullYear(), endDate.getMonth() - monthsApart, endDate.getDate());
        return [startDate, endDate];
    };

    try {
        const res: number | string | null = await this.parameters.getValues("filterDateApartValue").toPromise();

        const monthsApart = (typeof res === 'number') ? res : 2;

        return calculateDates(monthsApart);

    } catch (error) {
        console.error('Parametreye girilen değer number türünde olmalı.', error);

        return calculateDates(2);
    }
}


  public getFilterDatesToday(date: Date) {
    /** Hizmet Listesi ve Operasyon Kontrol Ekranları için Başlangıç Tarihleri Bugüne Çevrildi */
    const startDate = new Date(date);
    startDate.setHours(0, 0, 0, 0);

    const endMonth = date.getMonth() < 11 ? date.getMonth() + 2 : 1;
    const endYear = date.getMonth() < 11 ? date.getFullYear() : date.getFullYear() + 1;
    const endDate = new Date(endYear, endMonth - 1, 1);
    endDate.setHours(0, 0, 0, 0);

    return [startDate, endDate];
}
  public compareDatesMonthWise(date1: Date, date2: Date, maxDiffrance: number) {
    const yearDiffrance: number = date1.getFullYear() - date2.getFullYear();
    const monthDiffrance: number = date1.getMonth() - date2.getMonth();
    const totalDiffrance: number = monthDiffrance + (yearDiffrance*12);
    const diffrance = totalDiffrance < 0 ? totalDiffrance*(-1) : totalDiffrance;

    return diffrance-maxDiffrance >= 1 ? true : false;
  }
}
